

import React from 'react';

import update from 'immutability-helper';
import _ from 'lodash'

// MuiTheme
import muiThemeable from 'material-ui/styles/muiThemeable';

// COMPONENTS
import { SortableList, SortableItem, DragHandle } from './helper';
import Blacklist from './blacklist';
import IconMenu from 'material-ui/IconMenu';
import MenuItem  from 'material-ui/MenuItem';
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'
import IconButton from 'material-ui/IconButton';

import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';

import SectionIcon from 'material-ui/svg-icons/editor/drag-handle';
import ArrowDropRight from 'material-ui/svg-icons/navigation-arrow-drop-right';
import RejectedIcon from 'material-ui/svg-icons/alert/error';
import { MoveIcon } from '../assets/icons'
import CircularProgress from 'material-ui/CircularProgress';

class DashboardItem extends React.Component {

    constructor(props) {
        super(props);
    }
    shouldComponentUpdate(nextProps, nextState) {
        return (
            !(_.isEqual(this.props.item, nextProps.item)) ||
            !(_.isEqual(this.props.project, nextProps.project)) ||
            !(_.isEqual(this.props.updatesVisible, nextProps.updatesVisible)) ||
            !(_.isEqual(this.props.blacklistDisabled, nextProps.blacklistDisabled))
        )
    }
    handleChangeSize(size){
        this.props.onChangeSize( this.props.item.id, size )
    }
    handleCloseBlacklist(blacklist) {
        this.props.onChangeBlacklist( this.props.item.id, blacklist)      
    }
    handleDelete(){
        this.props.onDelete( this.props.item.id )
    }
    handleMouseLeave(){
        // this.setState( update(this.state, {
        //     filterOpen: {$set: false},
        // }));
    }
    handleSaveImage() {
        // const node = findDOMNode(this.refs.imageSrc)
        // domtoimage.toBlob(node)
        //     .then( blob => {
        //         saveAs(blob, 'chart.png');
        //     })
        //     .catch( error => console.error('oops, something went wrong!', error) );
    }
    render() {
        const { 
            project, plan,
            index, className, style, 
            item, chartConfig,
            updatesVisible, blacklistDisabled,
            muiTheme
        } = this.props
        const { palette } = muiTheme
        let size; switch (item.size) {
            case '1x1': { size={width:374, height:210}; break }
            case '2x1': { size={width:770, height:210}; break }
            case '3x1': { size={width:1166, height:210}; break }
            default: { size={width:374, height:210}; break }
        }
        const styles = {
            item:{ 
                display:'inline-block', verticalAlign:'top', margin:10, backgroundColor:'white',                 
            },
            box:{ position:'relative', backgroundColor:'white', border:'#e5e5e5 solid 1px', boxShadow:'0px 0px 10px 0px rgba(0,0,0,.15)' },
            chartHeader:{ backgroundColor:'rgba(0,0,0,0)', height:50, lineHeight:'50px', padding:0 },
            moveHandle:{
                padding:12, lineHeight:'normal',
                color:palette.midgrey,
                cursor: 'move',
            },
            chartTitle:{ paddingLeft:24, color:palette.textColor, fontWeight:'bold', fontSize:16 },
            menuItem:{ fontSize:14 },
            panelContent:{ position:'relative', ...size },
            progress:{ 
                position:'absolute', top:0, bottom:0, right:0, left:0,
                display:'flex', justifyContent:'center', alignItems:'center',
                backgroundColor:'rgba(0,0,0,0)'
            },
            rejected:{
                display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', 
                color:palette.nioColor, height:'100%', lineHeight:'20px', textAlign:'center', fontWeight:'bold'
            },
            rejectedIcon:{ height:48, width:48, marginBottom:20 },
            contextWrapper: {
                position:'absolute',bottom:0,right:0, height:48,
                display:'flex', alignItems:'center', padding:'0px 20px'
            },
            blacklistBtn:{ marginRight:-12},
            updateFlag: { 
                backgroundColor: palette.updateColor, borderRadius:8, padding:'4px 7px',
                color:'white', fontWeight:500, fontSize:10,
            }
        }
        const getChart = (item) => {
            return ( 
                <div>
                    { React.cloneElement(chartConfig.chart, {
                        ...size,
                        data: item.data,
                        project,
                        palette
                    }) } 
                </div> 
            )
        }
        const getSizeOptions = () => chartConfig.sizes

        return (
            <SortableItem index={index} style={ styles.item } className={ className }>
                <div style={ styles.box } ref={ 'imageSrc' }>
                <Toolbar style={ styles.chartHeader }>
                    <ToolbarGroup>
                        <div className={'dontprint'}>
                            <DragHandle style={ styles.moveHandle }>
                                <MoveIcon color={ styles.moveHandle.color }/>
                            </DragHandle>
                        </div>
                        <ToolbarTitle text={ chartConfig.name } style={ styles.chartTitle }/>
                    </ToolbarGroup>
                    <ToolbarGroup>                                           
                        <IconMenu
                            style={ styles.menu }
                            iconButtonElement={<IconButton className={'dontprint'}><MoreVertIcon color={ palette.midgrey }/></IconButton>}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                            targetOrigin={{horizontal: 'right', vertical: 'top'}}
                        >
                            <MenuItem
                                primaryText={ 'Größe' }
                                // leftIcon={ getSizeIcon(item.size) }
                                rightIcon={<ArrowDropRight />}
                                style={ styles.menuItem }
                                menuItems={[
                                    getSizeOptions(item).map( s => 
                                        <MenuItem 
                                            key={s} 
                                            // leftIcon={getSizeIcon(s)} 
                                            value={s} 
                                            primaryText={s} 
                                            checked={s==item.size}
                                            insetChildren={s!=item.size}
                                            onClick={ () => this.handleChangeSize(s) }
                                            style={ styles.menuItem }
                                        /> 
                                    )
                                ]}
                            />
                            <MenuItem 
                                // leftIcon={<DeleteIcon/>} 
                                primaryText="als Bild speichern" 
                                onClick={ this.handleSaveImage.bind(this) } 
                                style={ styles.menuItem }
                            />
                            <MenuItem 
                                // leftIcon={<DeleteIcon/>} 
                                primaryText="Löschen" 
                                onClick={ this.handleDelete.bind(this) } 
                                style={ styles.menuItem }
                            />
                        
                        </IconMenu>
                    </ToolbarGroup>
                </Toolbar>
                               
                
                <div style={ styles.panelContent }>
                {   item.fulfilled &&
                    getChart(item)
                }
                {   item.pending && 
                    <div style={ styles.progress }>
                        <CircularProgress size={50} thickness={4}/> 
                    </div>
                }
                {   item.rejected &&
                    <div style={ styles.rejected }>
                        <RejectedIcon style={ styles.rejectedIcon } color={ styles.rejected.color }/>
                        { `Es ist ein Fehler beim Laden des Diagrams aufgetreten` }
                    </div>
                }
                </div>
                
                <div style={styles.contextWrapper}>
                    {   updatesVisible && item.updates>0 &&
                        <span style={styles.updateFlag}>{item.updates}</span>
                    }
                    {   !blacklistDisabled &&
                        <Blacklist 
                            buttonStyle={styles.blacklistBtn}
                            blacklist={item.blacklist} 
                            plan={plan} 
                            onClose={ this.handleCloseBlacklist.bind(this) }
                        />
                    }
                    
                </div>
            </div></SortableItem>
        )
    }
}

export default muiThemeable()(DashboardItem)