
import React from 'react';

import { connect } from 'react-redux';
import update from 'immutability-helper';

// import PHE from "print-html-element";
// import domtoimage from 'dom-to-image';
// import { saveAs } from 'file-saver'


// MuiTheme
import muiThemeable from 'material-ui/styles/muiThemeable';

// ACTIONS
import { 
    act_LoadDashboard,
    act_DeleteDashboard, act_UpdateDashboardData, act_ChangeDashboardBlacklist, act_DuplicateDashboard,
    act_AddChart, act_DeleteChart, act_ChangeChartBlacklist, act_ChangeChartSize, act_ChangeChartPosition
} from '../actions/dashboardActions'

// COMPONENTS
import View from './view'
import DashboardItem from './dashboardItem';
import Blacklist from './blacklist';
import { SortableList, SortableItem, DragHandle } from './helper';
import ConfirmDialog from './confirmDialog';
import { 
    ChartSpAp, ChartMB, ChartWorker,
    ChartWorkload, ChartTimeOverage,
    ChartHeight,
    ChartStatusGesamtaussage, ChartStatusMaterialbereitstellung, ChartStatusWorkload, ChartStatusParts, ChartStatusScrewcases, ChartStatusBemis
} from './charts'
import IconMenu from 'material-ui/IconMenu';
import MenuItem  from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import FlatButton from 'material-ui/FlatButton';
import Toggle from 'material-ui/Toggle';
import FloatingActionButton from 'material-ui/FloatingActionButton';

import MenuIcon from 'material-ui/svg-icons/navigation/more-vert';
import AddIcon from 'material-ui/svg-icons/content/add';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import PrintIcon from 'material-ui/svg-icons/action/print';
import RadialChartIcon from 'material-ui/svg-icons/action/donut-large';
import LineChartIcon from 'material-ui/svg-icons/action/timeline';
import SingeValueChartIcon from 'material-ui/svg-icons/image/looks-one';
import DuplicateIcon from 'material-ui/svg-icons/image/control-point-duplicate';


const additions = [
    { id:1, chart:<ChartSpAp/>, icon:<SingeValueChartIcon/>, sizes:['1x1'] },
    { id:2, chart:<ChartMB/>, icon:<SingeValueChartIcon/>, sizes:['1x1'] },
    { id:3, chart:<ChartWorker/>, icon:<SingeValueChartIcon/>, sizes:['1x1'] },
    { id:4, chart:<ChartWorkload/>, icon:<RadialChartIcon/>, sizes:['1x1'] },
    { id:5, chart:<ChartTimeOverage/>, icon:<SingeValueChartIcon/>, sizes:['1x1'] },
    { id:6, chart:<ChartHeight/>, icon:<LineChartIcon/>, sizes:['2x1','3x1'] },
    { id:7, chart:<ChartStatusGesamtaussage/>, icon:<RadialChartIcon/>, sizes:['1x1'] },
    { id:8, chart:<ChartStatusMaterialbereitstellung/>, icon:<RadialChartIcon/>, sizes:['1x1'] },
    { id:9, chart:<ChartStatusWorkload/>, icon:<RadialChartIcon/>, sizes:['1x1'] },
    { id:10, chart:<ChartStatusParts/>, icon:<RadialChartIcon/>, sizes:['1x1'] },
    { id:11, chart:<ChartStatusScrewcases/>, icon:<RadialChartIcon/>, sizes:['1x1'] },
    { id:12, chart:<ChartStatusBemis/>, icon:<RadialChartIcon/>, sizes:['1x1'] },
]

// COMPONENT
class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            deleteDialog:false,
            menuOpen:false,
            charts:this.props.charts.map(c=> Object.assign({},c,additions.find(a=>a.id==c.id)) )
        }
    }
    componentWillMount() {
        const dashboardId = this.props.match.params.id
        if(dashboardId) this.init(dashboardId)
    }
    componentWillReceiveProps(nextProps) {
        const currDashboardId = this.props.match.params.id, nextDashboardId = nextProps.match.params.id
        if(nextDashboardId != currDashboardId) this.init(nextDashboardId )
    }
    init(dashboardId) {
        const projectId = this.props.project.id
        this.props.dispatch( act_LoadDashboard({projectId,dashboardId}) )
    }
    handleDataChange(key, value) {
        const projectId = this.props.project.id, dashboardId = this.props.data.id
        const data = {...this.props.data, [key]:value}
        this.props.dispatch( act_UpdateDashboardData({projectId, dashboardId, data}) )
    }
    handleOpenMenu() {
        this.setState( update(this.state, { 
            menuOpen: {$set: true}
        }))
    }
    handleCloseMenu(open,reason) {
        if(open===false && reason==='clickAway') {
            this.setState( update(this.state, { 
                menuOpen: {$set: false}
            }))            
        }
    }
    handleRequestDeleteDashboard() {
        this.setState( update(this.state, { 
            deleteDialog: {$set: true},
            menuOpen: {$set: false}
        }))
    }
    handleDeleteDashboard() {
        const projectId = this.props.project.id, dashboardId = this.props.data.id
        this.props.dispatch( act_DeleteDashboard({projectId, dashboardId}) )
        this.props.history.push(`/projects/${projectId}/dashboards`)
        this.handleCancelDeleteDashboard()
    }
    handleCancelDeleteDashboard() {
        this.setState( update(this.state, { 
            deleteDialog: {$set: false}
        }))
    }
    handleChangeDashboardBlacklist(blacklist) {
        const projectId = this.props.project.id, dashboardId = this.props.data.id
        this.props.dispatch( act_ChangeDashboardBlacklist({projectId, dashboardId, blacklist}))
    }
    handleAddChart(chartId, size) {
        const projectId = this.props.project.id, dashboardId = this.props.data.id
        this.props.dispatch( act_AddChart({projectId, dashboardId, chartId, size}))
    }
    handleChangeChartBlacklist(dashboardItemId,blacklist) {
        const projectId = this.props.project.id, dashboardId = this.props.data.id
        this.props.dispatch( act_ChangeChartBlacklist({projectId, dashboardId, dashboardItemId, blacklist}))
    }
    handleChangeChartSize(dashboardItemId,size) {
        const projectId = this.props.project.id, dashboardId = this.props.data.id
        this.props.dispatch( act_ChangeChartSize({projectId, dashboardId, dashboardItemId, size}))
    }
    handleChangeChartPosition({oldIndex, newIndex}) {
        const projectId = this.props.project.id, dashboardId = this.props.data.id, dashboardItemId = this.props.items[oldIndex].id
        this.props.dispatch( act_ChangeChartPosition({projectId, dashboardId, dashboardItemId, position:newIndex}))
    }
    handleDeleteChart(dashboardItemId) {
        const projectId = this.props.project.id, dashboardId = this.props.data.id
        this.props.dispatch( act_DeleteChart({projectId, dashboardId, dashboardItemId }))
    }
    handlePrintDashboard() {
        this.setState( update(this.state, { 
            menuOpen: {$set: false}
        }))  
        // const { dashboard, project, muiTheme } = this.props
        // const header = ReactDOMServer.renderToStaticMarkup(
        //     <MuiThemeProvider muiTheme={lightTheme}>
        //         <Toolbar style={ {marginBottom:25, width:1200, padding:'none', background:'none' } } className={'print-header'}>
        //             <ToolbarGroup>
        //                 <ToolbarTitle text={ project.name + ' - ' + dashboard.title } style={ {fontSize:30, fontWeight:'bold', color:'#4A4A4A'} }/>
        //             </ToolbarGroup>
        //             <ToolbarGroup>
        //                 <div style={ {fontFamily:'Roboto', fontSize:14, color:muiTheme.palette.midgrey }}>{ moment().format('DD.MM.YYYY') }</div>
        //             </ToolbarGroup>
        //         </Toolbar>
        //     </MuiThemeProvider>
        // )
        // const content = [...findDOMNode(this.refs.list).getElementsByClassName('print-item')].map(p => p.outerHTML).join('\n') 
        // PHE.printHtml( content, { 
        //     templateString: header + '{{printBody}}'
        // });
    }
    handleDuplicateDashboard() {
        const projectId = this.props.project.id, dashboardId = this.props.data.id
        this.props.dispatch( act_DuplicateDashboard({projectId, dashboardId}))
    }

    render() {
        const { charts, deleteDialog, menuOpen } = this.state
        const { pending, fulfilled, data, blacklist, items, project, plan, muiTheme, me } = this.props
        const palette = muiTheme.palette
        const styles = {
            addBtn:{ position:'absolute', bottom:0, right:0, margin:40 },
            tooltip:{ backgroundColor:'white', color:palette.textColor },
            toggle:{ marginLeft:-5 },
            toggleTrack:{backgroundColor:palette.midgrey},
            toggleLabel:{color:palette.secondaryTextColor}
        }
  
        return (
            <View
                pending={pending}
                fulfilled={fulfilled}
                title={ data.name} 
                onTitleChange={title=>this.handleDataChange('name',title)}
                leftActions={[

                ]}
                rightActions={<React.Fragment>
                    {   data.blacklistActive &&
                        <Blacklist 
                            key='blacklist'
                            blacklist={blacklist} 
                            plan={plan} 
                            onClose={ this.handleChangeDashboardBlacklist.bind(this) }
                        />
                    }
                    <IconMenu
                        key={'menu'}
                        open={menuOpen}
                        iconButtonElement={<IconButton><MenuIcon/></IconButton>}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        targetOrigin={{horizontal: 'right', vertical: 'top'}}
                        onRequestChange={this.handleCloseMenu.bind(this)}
                        onClick={ this.handleOpenMenu.bind(this) }
                    >
                        {   me.isAdmin && 
                            <MenuItem 
                                key={'isPublic'}
                                leftIcon={
                                    <Toggle
                                        style={ styles.toggle }
                                        thumbStyle={styles.toggleThumb}
                                        trackStyle={styles.toggleTrack}
                                        toggled={data.public ? true : false}
                                    />
                                }
                                primaryText={'Öffentlich'} 
                                onClick={()=>this.handleDataChange('public',data.public?false:true)}
                            />
                        } 
                        {   data.creator_user_id === me.data.id &&
                            <React.Fragment>
                                <MenuItem 
                                    key={'showUpdates'}
                                    leftIcon={
                                        <Toggle
                                            style={ styles.toggle }
                                            thumbStyle={styles.toggleThumb}
                                            trackStyle={styles.toggleTrack}
                                            toggled={data.updatesVisible ? true : false}
                                        />
                                    }
                                    primaryText={'Updates anzeigen'} 
                                    onClick={()=>this.handleDataChange('updatesVisible',data.updatesVisible?false:true)}
                                />
                                <MenuItem 
                                    key={'blacklist'}
                                    leftIcon={
                                        <Toggle
                                            style={ styles.toggle }
                                            thumbStyle={styles.toggleThumb}
                                            trackStyle={styles.toggleTrack}
                                            toggled={data.blacklistActive ? true : false}
                                        />
                                    }
                                    primaryText={'zentraler Kontextfilter'} 
                                    onClick={()=>this.handleDataChange('blacklistActive',data.blacklistActive?false:true)}
                                />
                            </React.Fragment>
                        }
                        <MenuItem key={'duplicate'} leftIcon={<DuplicateIcon/>} primaryText={'Dashboard duplizieren'} onClick={this.handleDuplicateDashboard.bind(this)}/>
                        <MenuItem key={'print'} leftIcon={<PrintIcon/>} primaryText={'Dashboard drucken'} disabled onClick={this.handlePrintDashboard.bind(this)}/>
                        {   data.creator_user_id === me.data.id &&
                            <MenuItem key={'delete'} leftIcon={<DeleteIcon/>} primaryText={'Dashboard löschen'} onClick={this.handleRequestDeleteDashboard.bind(this)}/>
                        }
                    </IconMenu>
                </React.Fragment>}
            >
                <div ref='list'>
                    <SortableList 
                        axis="xy" 
                        onSortEnd={ this.handleChangeChartPosition.bind(this) }
                        useDragHandle={true}
                    >
                    {
                        items.map( (item,i) =>
                            <DashboardItem
                                key={ item.id }
                                index={ i }
                                className={'print-item'}
                                item={ item }
                                chartConfig={ charts.find(c=>c.id==item.chart_id) }
                                plan={plan}
                                project={ project }
                                updatesVisible={ data.updatesVisible }
                                blacklistDisabled={ data.blacklistActive ? true : false }
                                onChangeBlacklist={ this.handleChangeChartBlacklist.bind(this) }
                                onChangeSize= { this.handleChangeChartSize.bind(this) }
                                onDelete={ this.handleDeleteChart.bind(this) }
                            />
                        )
                    }
                    </SortableList>
                    <IconMenu
                         key={'addChart'}
                         style={ styles.addBtn }
                         iconButtonElement={
                             <FloatingActionButton>
                                <AddIcon/>
                            </FloatingActionButton>
                         }
                         anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                         targetOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                    {   charts.map( (c,i) =>
                        <MenuItem key={i} leftIcon={c.icon} primaryText={c.name} onClick={ () => this.handleAddChart(c.id, c.default_size) } />,
                    )}
                    </IconMenu>
                </div>
    
                <ConfirmDialog 
                    open={ deleteDialog }
                    title={ 'Löschen Bestätigen'}
                    text={ 'Das Dashboard wird nach Bestätigung unwiederruflich gelöscht.' }
                    onCancel={ this.handleCancelDeleteDashboard.bind(this) }
                    onConfirm={ this.handleDeleteDashboard.bind(this) }
                />
            </View>
        )
    }
};


// EXPORT
export default connect(store => ({
    fulfilled: store.dashboard.current.fulfilled,
    pending: store.dashboard.current.pending,
    data: store.dashboard.current.data,
    blacklist: store.dashboard.current.blacklist,
    items: store.dashboard.current.items,//.sort( (a,b) => a.position - b.position ),
    charts: store.dashboard.charts,
    
    project: store.project.current.data,
    plan: store.plan.current,

    me: store.user.me
}))(
    muiThemeable()(
        Dashboard
    )
);