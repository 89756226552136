export const getpartsuperstatus = (s) => {
    const st_materialbereitstellung = getaggrstatus([s.st_behaelter, s.st_regalbelegung, s.st_layout_app ])
    const st_gesamtaussage = getaggrstatus([s.st_behaelter, s.st_regalbelegung, s.st_layout_app, s.st_visualization, s.st_absicherung, s.st_umlaufteil ])
    return { st_materialbereitstellung, st_gesamtaussage }
}

export const getaggrstatus = (arr) => {
    const len = arr.length
    let status = -1;
    let ns = 0, io = 0, nio = 0, nr = 0;
    arr.forEach(el => {
        switch (el) {
            case -2: { nio+=1; break; } 
            case -1: { ns+=1; break; } 
            case 0: { nio+=1; break; }
            case 1: { io+=1; break; }
            case 2: { nr+=1; break; }
        }
    });
    if(nio>0) status = 0
    else if(io+nr==len) status = 1
    else status = -1
    
    return status;
}