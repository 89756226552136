// DEPENDENCIES
import { Observable } from "rxjs";
import store from "./store";
import { saveAs } from 'file-saver';
import JSZip from 'jszip'

import userManager from "./utils/userManager";
import { getpartsuperstatus, getaggrstatus } from "./logic";

const apiUrl = process.env.REACT_APP_TMR_API_URL

// MAIN REQUESTS
const excelrequest$ = (route ) => {
  const user = store.getState().oidc.user;
  const token = user ? user.id_token : "";
  let url = new URL(apiUrl + route);
  let params = {
    // xhrFields: { responseType: "blob" },
    responseType: "blob",
    headers: {
      // 'Content-type': 'application/json',
      token
    },
    credentials: "include",
    method: "GET",
    mode: "cors"
  };
  return Observable.from(fetch(url, params))
    .catch(err =>
      Observable.throw({ code: 0, msg: "Connection to Server Refused", err })
    )
    .mergeMap(r => {
      if (r.status === 401) {
        userManager.removeUser();
      }
      const fileName = r.headers.get('Content-Disposition')
        .split(';')
        .find(n => n.includes('filename='))
        .replace('filename=', '')
        .trim()
      ;
      r.blob().then(function(myBlob) {
        saveAs(myBlob, fileName)
      });
      return [r];
    });
};
const request$ = (route, method, body, query) => {
  const user = store.getState().oidc.user;
  const token = user ? user.id_token : "";
  let url = new URL(apiUrl + route);
  let params = {
    headers: {
      "content-type": "application/json",
      token
    },
    credentials: "include",
    method,
    mode: "cors"
  };
  if (body) params.body = JSON.stringify(body);
  if (query) url.search = new URLSearchParams(query);
  return Observable.from(fetch(url, params))
    .catch(err =>
      Observable.throw({ code: 0, msg: "Connection to Server Refused", err })
    )
    .mergeMap(r => {
      if (r.status === 401) {
        userManager.removeUser();
      }
      return r.json();
    });
};
const imageRequest$ = (route, method, data) => {
  const user = store.getState().oidc.user;
  const token = user ? user.id_token : "";
  const url = apiUrl + route;
  const params = {
    headers: {
      token
    },
    credentials: "include",
    method,
    mode: "cors",
    body: data
  };
  return Observable.from(fetch(url, params))
    .catch(err =>
      Observable.throw({ code: 0, msg: "Connection to Server Refused", err })
    )
    .mergeMap(r => {
      if (r.status === 401) {
        userManager.removeUser();
      }
      return r.json();
    });
};

// ENDPOINTS
export default {
  // CONFIGURATION
  get_configuration: r => request$('/configuration', 'GET'),
  
  // AUTHENTICATION
  register: r => request$(`/user/register`, "POST", r.data),
  login: r => request$(`/user/login`, "PUT"),
  logout: r => request$(`/user/logout`, "PUT"),

  // USER-MANAGEMENT
  get_me: r => request$(`/current-user`, "GET").map(map_me),
  get_users: r => request$(`/users`, "GET"),
  get_user: r => request$(`/users/${r.params.userId}`, "GET"),
  update_user_data: r => request$(`/users/${r.params.userId}`, "PUT", r.data),
  update_user_settings: r => request$(`/users/${r.params.userId}/settings`, "PUT", r.data),
  add_role_to_user: r => request$(`/users/${r.params.userId}/roles`, "POST", r.data),
  remove_role_from_user: r => request$(`/users/${r.params.userId}/roles`, "DELETE", r.data),

  // ROLE MANAGEMENT
  get_roles: r => request$(`/roles`, "GET"),
  create_role: r => request$(`/roles`, "POST", r.data),
  get_role: r => request$(`/roles/${r.params.roleId}`, "GET"),
  update_role: r => request$(`/roles/${r.params.roleId}`, "PUT", r.data),
  delete_role: r => request$(`/roles/${r.params.roleId}`, "DELETE"),
  add_right_to_role: r => request$(`/roles/${r.params.roleId}/rights`, "POST", r.data),
  remove_right_from_role: r => request$(`/roles/${r.params.roleId}/rights`, "DELETE", r.data),
  get_rights: r => request$(`/rights`, "GET"),

  // PROJECT MANAGEMENT
  get_projects: r => request$(`/projects`, "GET").mergeMap(projects => [projects.map(map_project)] ),
  create_project: r => request$(`/projects`, "POST", r.data).map(map_project),
  get_project: r => request$(`/projects/${r.params.projectId}`, "GET").map(map_project),
  update_project: r => request$(`/projects/${r.params.projectId}`, "PUT", r.data).map(map_project),
  delete_project: r => request$(`/projects/${r.params.projectId}`, "DELETE"),
  update_project_position: r => request$(`/projects/${r.params.projectId}/position`, "PUT", r.data),
  get_project_rights: r => request$(`/projects/${r.params.projectId}/rights`, "GET", r.data),
  get_programs: r => request$(`/programs`, "GET", null, r.data).mergeMap(programs => [programs.map(map_program)] ),
  get_filter: r => request$(`/filter`, "GET",null,r.data),
  get_current_program: r => request$(`/projects/${r.params.projectId}/program`, "GET").mergeMap(programs => [programs.map(map_program)] ),
  sync_project: r => request$(`/projects/${r.params.projectId}/sync`, "PUT"),
  get_sync_jobs: r => request$(`/syncjobs`, "GET"),
  reorder_sync_job: r => request$(`/syncjobs/${r.params.jobId}/position`, "PUT", r.data),
  cancel_sync_job: r => request$(`/syncjobs/${r.params.jobId}/cancel`, "POST"),

  // DASHBOARD-MANAGEMENT
  get_charts: r => request$("/charts", "GET"),
  get_chart_data: r => request$(`/projects/${r.params.projectId}/charts/${r.params.chartId}`, "GET", null, r.data),
  get_dashboards: r => request$(`/projects/${r.params.projectId}/dashboards`, "GET"),
  create_dashboard: r => request$(`/projects/${r.params.projectId}/dashboards`, "POST", r.data),
  duplicate_dashboard: r => request$(`/projects/${r.params.projectId}/dashboards/${r.params.dashboardId}/duplicate`, "POST"),
  delete_dashboard: r => request$(`/projects/${r.params.projectId}/dashboards/${r.params.dashboardId}`, "DELETE"),
  update_dashboard_data: r => request$(`/projects/${r.params.projectId}/dashboards/${r.params.dashboardId}`, "PUT", r.data),
  update_dashboard_blacklist: r => request$(`/projects/${r.params.projectId}/dashboards/${r.params.dashboardId}/context`, "PUT", r.data),
  update_dashboard_position: r => request$(`/projects/${r.params.projectId}/dashboards/${r.params.dashboardId}/position`, "PUT", r.data),
  get_dashboard: r => request$(`/projects/${r.params.projectId}/dashboards/${r.params.dashboardId}`, "GET").map(map_dashboard),
  create_dashboard_item: r => request$(`/projects/${r.params.projectId}/dashboards/${r.params.dashboardId}/items`, "POST", r.data).map(map_dashboard_item),
  delete_dashboard_item: r => request$(`/projects/${r.params.projectId}/dashboards/${r.params.dashboardId}/items/${r.params.dashboardItemId}`, "DELETE"),
  update_dashboard_item_context: r => request$(`/projects/${r.params.projectId}/dashboards/${r.params.dashboardId}/items/${r.params.dashboardItemId}/context`, "PUT", r.data).map(map_dashboard_item),
  update_dashboard_item_position: r => request$(`/projects/${r.params.projectId}/dashboards/${r.params.dashboardId}/items/${r.params.dashboardItemId}/position`, "PUT", r.data),
  update_dashboard_item_size: r => request$(`/projects/${r.params.projectId}/dashboards/${r.params.dashboardId}/items/${r.params.dashboardItemId}/size`, "PUT", r.data).map(map_dashboard_item),

  // PLAN-MANAGEMENT
  search: r => request$(`/project/${r.params.projectId}/search`, "GET", null, r.data),
  get_plans: r => request$("/plans", "GET"),
  get_raw_plan: r => request$(`/plan`, "GET", null, r.data).map(map_plan),
  get_plan: r => request$(`/projects/${r.params.projectId}/plan`, "GET").map(map_plan),
  get_blacklist: r => request$(`/blacklist`, "GET", null, r.data),
  subscribe_to_section: r => request$(`/projects/${r.params.projectId}/plan/sections/${encodeURIComponent(r.params.sectionId)}/subscription`, "POST"),
  unsubscribe_from_section: r => request$(`/projects/${r.params.projectId}/plan/sections/${encodeURIComponent(r.params.sectionId)}/subscription`, "DELETE"),
  get_cycle: r => request$(`/projects/${r.params.projectId}/plan/cycles/${encodeURIComponent(r.params.cycleId)}`, "GET").map(map_cycle),
  create_height: r => request$(`/projects/${r.params.projectId}/plan/cycles/${encodeURIComponent(r.params.cycleId)}/heights`, "POST", r.data).map(map_height),
  update_height: r => request$(`/projects/${r.params.projectId}/plan/cycles/${encodeURIComponent(r.params.cycleId)}/heights/${r.params.heightId}`, "PUT", r.data).map(map_height),
  delete_height: r => request$(`/projects/${r.params.projectId}/plan/cycles/${encodeURIComponent(r.params.cycleId)}/heights/${r.params.heightId}`, "DELETE"),
  set_height_connect_transfer: r => request$(`/projects/${r.params.projectId}/plan/cycles/${encodeURIComponent(r.params.cycleId)}/heights/${r.params.heightId}/connect_transfer`, "PUT", r.data ).map(map_height),
  get_workstation: r => request$(`/projects/${r.params.projectId}/plan/workstations/${encodeURIComponent(r.params.workstationId)}`, "GET").map(map_workstation),
  update_position: r => request$(`/projects/${r.params.projectId}/plan/workstations/${encodeURIComponent(r.params.workstationId)}/position`, "PUT", r.data),
  update_workload_status: r => request$(`/projects/${r.params.projectId}/plan/workstations/${encodeURIComponent(r.params.workstationId)}/workload/status`, "PUT", r.data).map(map_workstation),
  update_part_status: r => request$(`/projects/${r.params.projectId}/plan/workstations/${encodeURIComponent(r.params.workstationId)}/parts/${encodeURIComponent(r.params.partId)}/status`, "PUT", r.data).map(map_workstation),
  update_screwcase_status: r => request$(`/projects/${r.params.projectId}/plan/workstations/${encodeURIComponent(r.params.workstationId)}/screwcases/${encodeURIComponent(r.params.screwcaseId)}/status`, "PUT", r.data).map(map_workstation),
  update_resource_status: r => request$(`/projects/${r.params.projectId}/plan/workstations/${encodeURIComponent(r.params.workstationId)}/resources/${encodeURIComponent(r.params.resourceId)}/status`, "PUT", r.data).map(map_workstation),
  upload_image: r => imageRequest$(`/projects/${r.params.projectId}/plan/workstations/${encodeURIComponent(r.params.workstationId)}/images`, "POST", r.data),
  delete_image: r => request$(`/projects/${r.params.projectId}/plan/workstations/${encodeURIComponent(r.params.workstationId)}/images/${r.params.imageId}`, "DELETE"),

  // COMMENTS
  create_comment: r => request$(`/projects/${r.params.projectId}/plan/workstations/${encodeURIComponent(r.params.workstationId)}/comments`, "POST", r.data),
  update_comment: r => request$(`/projects/${r.params.projectId}/plan/workstations/${encodeURIComponent(r.params.workstationId)}/comments/${r.params.commentId}`, "PUT", r.data),
  delete_comment: r => request$(`/projects/${r.params.projectId}/plan/workstations/${encodeURIComponent(r.params.workstationId)}/comments/${r.params.commentId}`, "DELETE"),

  // EXPORTS
  get_export_preview: r => request$(`/projects/${r.params.project_id}/exports/${r.params.export_id}`,'GET'),
  export: r => excelrequest$(`/projects/${r.params.project_id}/exports/${r.params.export_id}/download?base_sync_id=${r.params.base_sync_id}&ref_sync_id=${r.params.ref_sync_id}`),
  get_export_blacklist: r => request$(`/projects/${r.params.project_id}/exports/${r.params.export_id}/blacklist`,'GET'),
  set_export_blacklist: r => request$(`/projects/${r.params.project_id}/exports/${r.params.export_id}/blacklist`,'PUT',r.data),

  // DEFAULTS
  get_default_project: () => map_project(),
  get_default_workstation_status: () => map_ws_status()
};

// MODEL MAPPER
const map_me = u => ({
  data: map_user(u),
  subscriptions: u.subscriptions.map(s => ({
    projectId: s.project_id,
    sectionId: s.section_id
  })),
  roles: u.roles,
  settings: u.settings[0] || {}
});

const map_user = u => ({
  id: u.id,
  email: u.email,
  firstname: u.firstname,
  lastname: u.lastname,
  department: u.department
});

const map_project = (p = {}) => ({
  id: p.id || "new",
  position: p.position,
  prefix: p.prefix || "",
  plant: p.plant || "",
  name: p.name || "",
  program_id: p.program_id || '',
  filter_keys: p.filter_keys || '',
  pid: p.pid || "",
  pr: p.pr || "",
  blacklist_id: p.blacklist_id || null,
  blacklist: p.blacklist || [],
  min_workload: p.min_workload || 0.85,
  max_workload: p.max_workload || 0.95,
  workload_premises: p.workload_premises,
  valueAdded_premises: p.valueAdded_premises,
  stable_premises: p.stable_premises,
  stable_condition: p.stable_condition,
  partlyStable_condition: p.partlyStable_condition,
  plan_key: p.plan_key || null,
  line_key: p.line_key || null,
  syncs: p.syncs || [],
  shouldSync: p.shouldSync,
  waitToSync: p.waitToSync, 
  inSync: p.inSync
});

const map_program = (p = {}) => ({
  id: p.id || "new",
  name: p.name || ''
});

const map_plan = p => {
  return {
    data: {
      line_key: p.line_key,
      line_name: p.line_name,
      plan_key: p.key,
      name: p.name,
      date: p.date
    },
    program: map_program(p.program),
    sections: p.sections,
    cycles: p.cycles,
    workstations: p.workstations.map(w => {
      const position = w.position ? w.position.position : null;
      const status = map_ws_status(w.status);
      return { ...w, position, status };
    }),
    stats: {
      date: p.date,
      sectionCount: p.sections.length,
      cycleCount: p.cycles.length,
      workstationCount: p.workstations.length,
    }
  };
};
const map_cycle = c => {
  return {
    data: {
      id: c.id,
      name: c.name,
      number: c.number
    },
    heights: c.heights.map(map_height)
  };
};
const map_height = h => {
  return {
    id: h.id,
    pid: h.pid,
    values: [h.x1, h.x2, h.x3, h.x4, h.x5, h.x6, h.x7, h.x8, h.x9, h.x10],
    connect_transfer: h.connect_transfer,
    connect_transfer_date: h.connect_transfer_date,
    warning: h.warning
  };
};
const map_workstation = w => {
  return {
    data: {
      id: w.id,
      name: w.name,
      number: w.number,
      position: w.position ? w.position.position : null
    },
    status: map_ws_status(w.status),
    workload: map_workload(w.workload),
    operations: w.operations.map(map_operation),
    parts: w.parts.map(map_part),
    screwcases: w.screwcases.map(map_screwcase),
    resources: w.resources.map(map_resource),
    images: w.images.map(map_image),
    comments: w.comments.map(map_comment),
    warning: w.status ? w.status.warning : null
  };
};
const map_ws_status = s => {
  return {
    st_gesamtaussage: s ? s.st_gesamtaussage : -1,
    st_materialbereitstellung: s ? s.st_materialbereitstellung : -1,
    st_workload: s ? s.st_workload : -1,
    st_parts: s ? s.st_parts : -1,
    st_screwcases: s ? s.st_screwcases : -1,
    st_resources: s ? s.st_resources : -1,
  };
};
const map_workload = w => {
  return {
    ...w,
    status: w.status ? w.status.status : -1,
    warning: w.status ? w.status.warning : null
  };
};
const map_operation = o => {
  return {
    data: {
      id: o.id,
      name: o.name,
      long_name: o.long_name || "",
      number: o.number,
      av_time: o.av_time,
      g_time: o.g_time,
      pid: o.pid,
      operations: o.operations,
      classification: o.classification
    }
  };
};
const map_part = p => {
  const {
    st_absicherung = -1,
    st_behaelter = -1,
    st_layout_app = -1,
    st_mitlaufender_wagen = -1,
    st_regalbelegung = -1,
    st_umlaufteil = -1,
    st_visualization = -1,
    warning = null
  } = p.status || {};
  const status = {
    st_absicherung,
    st_behaelter,
    st_layout_app,
    st_mitlaufender_wagen,
    st_regalbelegung,
    st_umlaufteil,
    st_visualization,
  };
  return {
    ...p,
    status,
    superStatus: getpartsuperstatus(status),
    warning
  };
};
const map_screwcase = s => {
  return {
    ...s,
    data:{
        ...s.data,
    },
    status: s.status ? s.status.status : -1,
    warning: s.status ? s.status.warning : null
  };
};
const map_resource = r => {
  return {
    ...r,
    status: r.status ? r.status.status : -1,
    warning: r.status ? r.status.warning : null
  };
};
const map_image = i => {
  return i;
  // return {
  //     data: {},
  //     status: {}
  // }
};
const map_comment = c => {
  return c;
  // return {
  //     data: {},
  //     status: {}
  // }
};
const map_dashboard = ({dashboard,blacklist,items}) => {
  return {
        dashboard,
        blacklist,
        items: items.map(map_dashboard_item)
    }
};
const map_dashboard_item = d => {
  return {
    id: d.id,
    chart_id: d.chart_id,
    size: d.size,
    position: d.position,
    blacklist: d.blacklist,
    blacklist_id: d.blacklist_id,
    data: null,
    updates: 0,
    pending: false,
    fulfilled: false,
    rejected: false
  };
};
