"use strict";

// DEPENDENCIES
import update from 'immutability-helper';
import merge from 'lodash.merge';

// DEFAULT
const defaultState = { 
    pending:false,
    fulfilled:false,
    data:{},
    heights:[],
    editHeight: null,
}

// REDUCER
const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'LOAD_PENDING': {
            state = update(state, {
                pending: {$set: true},
                fulfilled: {$set: false},
                editHeight: {$set: null},
            });
            break;
        }
        case 'LOAD_CYCLE_FULFILLED': {
            const { data, heights } = action.payload
            state = update(state, {
                pending: {$set: false},
                fulfilled: {$set: true},
                data:{$set: data},
                heights:{$set: heights}
            });
            break;
        }
        case 'LOAD_CYCLE_REJECTED': {
            state = update(state, {
                pending: {$set: false},
                fulfilled: {$set: false},
                data:{$set: {}},
                heights:{$set: []}
            });
            break;
        }
        case 'CREATE_HEIGHT_FULFILLED': {
            const { height } = action.payload
            state = update(state, {
                heights: {$push: [height]},
                editHeight: {$set: height.id},
            });
            break;
        }
        case 'EDIT_HEIGHT': {
            const { heightId } = action.payload
            state = update(state, {
                editHeight: {$set: heightId},
            });
            break;
        }
        case 'CANCEL_EDIT_HEIGHT': {
            state = update(state, {
                editHeight: {$set: null},
            });
            break;
        }
        case 'UPDATE_HEIGHT': {
            const { heightId, height } = action.payload
            const index = state.heights.findIndex(h=>h.id==heightId)
            state = update(state, {
                heights: {$splice: [[index,1,height]]},
                editHeight: {$set: null}
            });
            break;
        }
        case 'DELETE_HEIGHT': {
            const { heightId } = action.payload
            const index = state.heights.findIndex(h=>h.id==heightId)
            state = update(state, {
                heights: {$splice: [[index,1]]},
                editHeight: {$set: null},
            });
            break;
        }
    }
    return state
}

// EXPORT
export default reducer;