
import update from 'immutability-helper';
import api from '../api';

// DEFAULT
const defaultState = {
    list: {
        pending:false,
        fulfilled:false,
        items:[]
    },
    current: {
        pending:false,
        fulfilled:false,
        data: {},
        shouldSync: false,
        waitToSync: false,
        inSync: false,
    },
    export:{
        pending:false,
        fulfilled:false,
        export_id:null,
        data:{},
        columns:[],
        rows:[],
        syncs:[],
        blacklist: []
    },
    sync:{
        pending:false,
        fulfilled:false,
        jobs: [],
    },
    programs:[],
    filter:[],
};

// REDUCER
const reducer = (state=defaultState, action) => {
    switch (action.type) {
        // LOAD PROJECTS
        case 'LOAD_PROJECTS_PENDING': {
            state = update(state, {
                list: {
                    pending: {$set: true},
                    fulfilled: {$set: false},
                }
            });
            break;
        }
        case 'LOAD_PROJECTS_FULFILLED': {
            state = update(state, {
                list: {
                    pending: {$set: false},
                    fulfilled: {$set: true},
                    items: {$set: action.payload }
                }
            });
            break;
        }
        case 'LOAD_PROJECTS_REJECTED': {
            state = update(state, {
                list: {
                    pending: {$set: false},
                    fulfilled: {$set: false},
                    items: {$set: [] }
                }
            });
            break;
        }
        // CREATE PROJECT
        // case 'CREATE_PROJECT': {
        //     state = update(state, {
        //         current: {
        //             pending:{$set: false},
        //             fulfilled:{$set: true },
        //             data:{$set: api.get_default_project() }
        //         }
        //     });
        //     break;
        // }
        case 'SAVE_PROJECT_FULFILLED': {
            const { projectId, data } = action.payload
            const index = state.list.items.findIndex( p => p.id==projectId );
            state = update(state, {
                list:{
                    items: (index>-1) ? {$splice: [[index,1,data]]} : {$push: [data]}
                },
                current:{
                    pending:{$set: false},
                    fulfilled:{$set: true},
                    data: {$set: data}
                },
            })
            break;
        }
        // DELETE PROJECT
        case 'DELETE_PROJECT_FULFILLED': {
            const { projectId } = action.payload
            const index = state.list.items.findIndex( p => p.id==projectId );
            state = update(state, {
                list: {
                    items: {$splice: [[index,1]]}
                }
            });
            if(projectId == state.current.data.id){
                state = update(state, {
                    current:{
                        pending: {$set: false},
                        fulfilled: {$set: false},
                        data: {$set: {}},
                    }
                });
            }
            break;
        }

        // CHANGE POSITION
        case 'CHANGE_PROJECT_POSITION': {
            const { projectId, position} = action.payload
            const project = state.list.items.find(d=>d.id===projectId)
            const oldPosition = project.position
            let projects = state.list.items.filter(p=>p.prefix===project.prefix && p.plant === project.plant).sort((a,b)=>a.position-b.position)
            projects.splice(oldPosition,1)
            projects.splice(position,0,project)
            projects.map((p,i)=>{
                const index = state.list.items.findIndex(({id})=>id===p.id)
                state = update(state, {
                    list:{
                        items: {[index]: { $apply: item => ({...item,position:i}) }},
                    },
                });
            })
            state = update(state, {
                list:{
                    items: { $apply: items => items.sort((a,b)=>a.position-b.position) },
                },
            });
            break;
        }

        // LOAD PROJECT
        case 'LOAD_PROJECT_PENDING': {
            state = update(state, {
                current: {
                    pending: {$set: true},
                    fulfilled: {$set: false},
                    data: {$set: {}},
                    shouldSync: {$set: false},
                    waitToSync: {$set: false},
                    inSync: {$set: false},
                }
            });
            break;
        }
        case 'LOAD_PROJECT_FULFILLED': {
            state = update(state, {
                current: {
                    pending: {$set: false},
                    fulfilled: {$set: true},
                    data: {$set: action.payload},
                    shouldSync: {$set: action.payload.shouldSync},
                    waitToSync: {$set: action.payload.waitToSync},
                    inSync: {$set: action.payload.inSync},
                },
                export: {
                    syncs: {$set: action.payload.syncs},
                }
            });
            break;
        }
        case 'LOAD_PROJECT_REJECTED': {
            state = update(state, {
                current: {
                    pending: {$set: false},
                    fulfilled: {$set: false},
                }
            });
            break;
        }
        case 'LOAD_PROGRAMS_FULFILLED': {
            state = update(state, {
                programs:{$set: action.payload},
            });
            break;
        }
        case 'LOAD_FILTER_FULFILLED': {
            const filter = action.payload
            state = update(state, {
                filter:{$set: filter}
            });
            break;
        }
        // LOAD SYNCJOBS
        case 'SYNC_PROJECT': {
            state = update(state, {
                current: {
                    shouldSync: {$set: false},
                    waitToSync: {$set: true},
                    inSync: {$set: false},
                }
            });
            break;
        }
        case 'LOAD_SYNCJOBS_PENDING': {
            state = update(state, {
                sync: {
                    pending: {$set: true},
                    fulfilled: {$set: false},
                    // jobs: {$set: []}  // triggeres project reload
                }
            });
            break;
        }
        case 'LOAD_SYNCJOBS_FULFILLED': case 'UPDATE_SYNCJOBS': {
            const newState = {
                sync: {
                    pending: {$set: false},
                    fulfilled: {$set: true},
                    jobs:{$set: action.payload}
                },
            }

            const projectJob = action.payload.find(j=>j.project_id===state.current.data.id)
            if( projectJob ) {
                newState.current = { shouldSync: {$set: false} }
                if( projectJob.status === 'waiting' ) newState.current = { ...newState.current, waitToSync: {$set: true}, inSync: {$set: false}  }
                if( projectJob.status === 'active' ) newState.current = { ...newState.current, waitToSync: {$set: false}, inSync: {$set: true} }
            } else newState.current = { waitToSync: {$set: false}, inSync:{$set: false} }

            state = update(state, newState);
            break;
        }
        case 'LOAD_SYNCJOBS_REJECTED': {
            state = update(state, {
                sync: {
                    pending: {$set: false},
                    fulfilled: {$set: false},
                    jobs: {$set: [] }
                }
            });
            break;
        }
        case 'REORDER_SYNCJOB': {
            const { jobId, position } = action.payload
            const job = state.sync.jobs.find(j=>j.id===jobId)
            const index = state.sync.jobs.findIndex(j=>j.id===jobId)
            const jobs = [...state.sync.jobs]
            jobs.splice(index,1)
            jobs.splice(position,0,job)
            state = update(state, {
                sync: {
                    pending: {$set: false},
                    fulfilled: {$set: false},
                    jobs: {$set: jobs }
                }
            });
            break;
        }
        case 'RESET_ALL': {
            state = update(state, {
                current: {
                    pending: {$set: false},
                    fulfilled: {$set: false},
                    data: {$set: {} }
                }
            });
            break;
        }
        case 'EXPORT_PENDING': {
            state = update(state, {
                export: {
                    pending: {$set: true},
                    fulfilled: {$set: false},
                    export_id: {$set: action.payload.export_id}
                }
            });
            break;
        }
        case 'EXPORT_FULFILLED': {
            state = update(state, {
                export: {
                    pending: {$set: false},
                    fulfilled: {$set: true},
                    export_id: {$set: null}
                }
            });
            break;
        }
        case 'EXPORT_REJECTED': {
            state = update(state, {
                export: {
                    pending: {$set: false},
                    fulfilled: {$set: false},
                    export_id: {$set: null}
                }
            });
            break;
        }
        case 'GET_EXPORT_BLACKLIST_PENDING': {
            state = update(state, {
                export: {
                    blacklist: {$set: []},
                }
            });
            break;
        }
        case 'GET_EXPORT_BLACKLIST_FULFILLED': {
            state = update(state, {
                export: {
                    blacklist: {$set: action.payload}
                }
            });
            break;
        }
        case 'GET_EXPORT_BLACKLIST_REJECTED': {
            state = update(state, {
                export: {
                    blacklist: {$set: []},
                }
            });
            break;
        }
        case 'SET_HEIGHT_CONNECT_TRANSFER': {
            const { heightId, connect_transfer } = action.payload
            const index = state.export.rows.findIndex(r=>r.id==heightId)
            const height = {
                ...state.export.rows[index],
                connect_transfer
            }
            state = update(state, {
                export: {rows: (index>-1) ? {$splice: [[index,1,height]]} : {$push: [height]}}
            });
            break;
        }
        case 'SET_HEIGHT_CONNECT_TRANSFER_FULFILLED': {
            const height = action.payload
            const index = state.export.rows.findIndex(r=>r.id==height.id)
            const editHeight = {
                ...state.export.rows[index],
                connect_transfer: height.connect_transfer,
                connect_transfer_date: height.connect_transfer_date,
                warning: height.warning
            }
            state = update(state, {
                export: {rows: (index>-1) ? {$splice: [[index,1,editHeight]]} : {$push: [editHeight]} }
            });
            break;
        }
    }
    return state
}

// EXPORT
export default reducer;