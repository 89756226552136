import React from "react";
import { render } from "react-dom";

import userManager from "./utils/userManager";
import { loadUser } from "redux-oidc";

import Root from "./components/root";

import 'typeface-roboto';
import 'typeface-audiowide';

import "./index.css";
import * as serviceWorker from "./serviceWorker";

import store from "./store";

import "./preRender";

if (window.top !== window.self) {
    window.top.location = window.location;
}

loadUser(store, userManager);

render(<Root store={store} />, document.getElementById("root"));

// window.open('http://localhost:3000/connect/google', 'login', "left=710,top=100,width=500,height=700")

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
