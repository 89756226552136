import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import muiThemeable from 'material-ui/styles/muiThemeable';
import queryString from 'query-string';

// ACTIONS
import { act_ChangeMapStatus } from '../actions/appActions'

// COMPONENTS
import SelectField from 'material-ui/SelectField';
import MenuItem  from 'material-ui/MenuItem';
import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton';

import InfoIcon from 'material-ui/svg-icons/action/info';

class StatusSelector extends React.Component {

    constructor() {
        super();
    }
    componentWillMount() {
        const { mapStatus, location, history, dispatch } = this.props
        let query = queryString.parse(location.search);
        if(!query.status) {
            query.status = mapStatus
            history.replace(`${this.props.location.pathname}?${queryString.stringify(query)}`)
        } 
        else {
            if(query.status != mapStatus) dispatch( act_ChangeMapStatus(query.status) )
        } 
    }

    handleChangeMapStatus(val) {
        let query = queryString.parse(this.props.location.search);
            query.status = val
        this.props.history.push(`${this.props.location.pathname}?${queryString.stringify(query)}`)
        this.props.dispatch( act_ChangeMapStatus(val) )
    }
    render() {
        const { location, mapStatus, muiTheme } = this.props
        const {palette} = muiTheme 
        const styles = {
            label:{ color:palette.textColor, fontWeight:500, fontSize:14, marginRight:10 },
            statusSelector:{ width:280 },
            infoTrigger:{  },
            info:{ width:'auto', maxWidth:'170px', padding:'5px 10px' },
            infoFlag:{ display:'inline-block', verticalAlign:'middle', height:12, width:24, marginRight:20 },
            infoLabel:{ verticalAlign:'middle', fontSize:12, fontWeight:500, color:palette.textColor }
        }
        const query = queryString.parse(this.props.location.search)
        return <React.Fragment>
            <span style={styles.label}>{'Status:'}</span>
            <SelectField
                style={ styles.statusSelector }
                labelStyle={ {fontSize:14}}
                value={ query.status || mapStatus }
                onChange={ (e,k,v) => this.handleChangeMapStatus(v) }
            >
            {   
                [
                    {label:'Gesamtaussage Arbeitsplatzgüte',value:'gesamtaussage'}, 
                    {label:'Materialbereitstellung', value:'materialbereitstellung'}, 
                    {label:'Auslastung',value:'workload'},
                    {label:'Bauteilgüte',value:'parts'},
                    {label:'Schraubfälle',value:'screwcases'},
                    {label:'Betriebsmittel',value:'resources'}
                ].map( s => 
                    <MenuItem key={s.value} value={s.value} primaryText={s.label}/>
                )
            }
            </SelectField>
            <IconMenu
                iconButtonElement={<IconButton style={ styles.infoTrigger }><InfoIcon/></IconButton>}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                targetOrigin={{horizontal: 'right', vertical: 'top'}}
                menuStyle={ styles.info }
            >
                <div><span style={{...styles.infoFlag, border:`${palette.defaultColor} solid 1px`}}/><span style={styles.infoLabel}>{'Leertakt'}</span></div>
                <div><span style={{...styles.infoFlag, backgroundColor:palette.defaultColor}}/><span style={styles.infoLabel}>{'Status nicht definiert'}</span></div>
                <div><span style={{...styles.infoFlag, backgroundColor:palette.nioColor}}/><span style={styles.infoLabel}>{'Status n.i.o.'}</span></div>
                <div><span style={{...styles.infoFlag, backgroundColor:palette.ioColor}}/><span style={styles.infoLabel}>{'Status i.o.'}</span></div>
            </IconMenu> 
        </React.Fragment>
    }
}


// EXPORT
export default withRouter(
    connect(store => ({
        mapStatus: store.app.mapStatus,
    }))(
        muiThemeable()(StatusSelector)
    )
)