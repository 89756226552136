import io from "socket.io-client";

const connect = (args, callbacks) => {
    const socket = io( process.env.REACT_APP_TMR_API_URL,{
        query:args
    });    
    socket.on("notification", callbacks.notification);
    socket.on("sync", callbacks.sync);
    socket.on("connect", () => {
        console.log('socket connected')
    })
    socket.on("disconnect", () => {
        console.log('socket disconnected')
        // socket.open()
    });
    return socket
}

export default { connect };
