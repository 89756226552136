"use strict";

// DEPENDENCIES
import React from 'react';

// COMPONENTS
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

// COMPONENT
class ConfirmDialog extends React.Component {

    constructor() {
        super();
        
    }

    handleConfirm() {
        this.props.onConfirm()
    }
    handleCancel() {
        this.props.onCancel()
    }

    render() {
        const styles = {
            title: { color:'#4A4A4A' },
            body: { color:'#4A4A4A' }
        }

        const actions = [
            <FlatButton
                label="Abbrechen"
                primary={true}
                keyboardFocused={true}
                onClick={this.handleCancel.bind(this)}
            />,
            <FlatButton
                label="Bestätigen"
                primary={true}
                keyboardFocused={true}
                onClick={this.handleConfirm.bind(this)}
            />
        ];

        return (
            <Dialog 
                title={ this.props.title } 
                actions={actions} 
                modal={false} 
                open={this.props.open} 
                onRequestClose={this.handleCancel.bind(this)}
                titleStyle={ styles.title }
                bodyStyle={ styles.body }
            >
            { this.props.text }
            </Dialog>
        )
    }
};

// EXPORT
export default ConfirmDialog;