"use strict";

// DEPENDENCIES
import { Observable } from 'rxjs';
import api from '../api';

// ACTIONS
import { act_ToggleTreeItem } from '../actions/planActions'
import { act_HandleError } from '../actions/appActions';

// -----------------------------------------------------
// EPICS
export const epc_LoadCycle = (action$,store) => {
    return action$.ofType('LOAD_CYCLE')
        .mergeMap( action => {
            const {projectId,cycleId} = action.payload
            return api.get_cycle({params:{projectId, cycleId}})
                .mergeMap( cycle => [
                    { type:'LOAD_CYCLE_FULFILLED', payload:cycle },
                    act_ToggleTreeItem({id:cycleId,type:'cycle',open:true}) 
                ])
                .catch( error => [
                    {type:'LOAD_CYCLE_REJECTED'},
                    act_HandleError(error)
                ])
        })
}

export const epc_CreateHeight = (action$,store) => {
    return action$.ofType('CREATE_HEIGHT')
        .mergeMap( action => {
            const { projectId, cycleId } = action.payload
            return api.create_height({params:{projectId, cycleId}})
                .mergeMap( height => [
                    { type:'CREATE_HEIGHT_FULFILLED', payload:{cycleId, height} }
                ])
                .catch( error => [
                    {type:'CREATE_HEIGHT_REJECTED'},
                    act_HandleError(error)
                ])
        })
}

export const epc_UpdateHeight = (action$,store) => {
    return action$.ofType('UPDATE_HEIGHT')
        .mergeMap( action => {
            const { projectId, cycleId, heightId, height} = action.payload
            const trans_data = {
                id: height.id, 
                pid: height.pid, 
                x1: height.values[0],
                x2: height.values[1],
                x3: height.values[2],
                x4: height.values[3],
                x5: height.values[4],
                x6: height.values[5],
                x7: height.values[6],
                x8: height.values[7],
                x9: height.values[8],
                x10: height.values[9],
            }
            return api.update_height({params:{projectId, cycleId, heightId},data:trans_data})
                .mergeMap( height => [
                    { type:'UPDATE_HEIGHT_FULFILLED', payload:{cycleId, heightId, height} }
                ])
                .catch( error => [
                    {type:'UPDATE_HEIGHT_REJECTED'},
                    act_HandleError(error)
                ])
        })
}

export const epc_SetHeightConnectTransfer = (action$,store) => {
    return action$.ofType('SET_HEIGHT_CONNECT_TRANSFER')
        .mergeMap( action => {
            const { projectId, cycleId, heightId, connect_transfer } = action.payload
            return api.set_height_connect_transfer({params:{projectId, cycleId, heightId}, data:{connect_transfer}})
                .mergeMap( height => [
                    { type:'SET_HEIGHT_CONNECT_TRANSFER_FULFILLED', payload:height }
                ])
                .catch( error => [
                    {type:'SET_HEIGHT_CONNECT_TRANSFER_REJECTED'},
                    act_HandleError(error)
                ])
                .startWith( {type:'SET_HEIGHT_CONNECT_TRANSFER_PENDING'} )
        })
}

export const epc_DeleteHeight = (action$,store) => {
    return action$.ofType('DELETE_HEIGHT')
        .mergeMap( action => {
            const { projectId, cycleId, heightId } = action.payload
            return api.delete_height({params:{projectId, cycleId, heightId}})
                .mergeMap( height => [
                    { type:'DELETE_HEIGHT_FULFILLED', payload:{cycleId, heightId} }
                ])
                .catch( error => [
                    {type:'DELETE_HEIGHT_REJECTED'},
                    act_HandleError(error)
                ])
        })
}
//export const epc_SaveHeight = (action$,store) => {
//     return action$.ofType('SAVE_HEIGHT')
//         .mergeMap( action => {
//             const { cycleId, height } = action.payload
//             return db.request$('saveheight$',{cycleId, height})
//                 .mergeMap( cycle => [
//                     {type:'SAVE_CYCLE_FULFILLED', payload:cycle},
//                     act_PushSnack('Takt wurde erfolgreich gespeichert')
//                 ])
//                 .catch(error => [
//                     {type:'SAVE_CYCLE_REJECTED', error},
//                     act_HandleError(error),
//                 ])
//                 .startWith( {type:'SAVE_CYCLE_PENDING'} )
//         })
// }
//export const epc_DeleteHeight = (action$,store) => {
//     return action$.ofType('DELETE_HEIGHT')
//         .mergeMap( action => {
//             const { cycleId, heightId } = action.payload
//             return db.request$('deleteheight$',{cycleId, heightId})
//                 .mergeMap( cycle => [
//                     {type:'SAVE_CYCLE_FULFILLED', payload:cycle},
//                     act_PushSnack('Takt wurde erfolgreich gespeichert')
//                 ])
//                 .catch(error => [
//                     {type:'SAVE_CYCLE_REJECTED', error},
//                     act_HandleError(error),
//                 ])
//                 .startWith( {type:'SAVE_CYCLE_PENDING'} )
//         })
// }
//export const epc_SaveCycle = (action$,store) => {
//     return action$.ofType('SAVE_CYCLE')
//         .mergeMap( action => {
//             const {id, data} = action.payload
//             return db.request$('savecycle$',{cycleId:id, data})
//                 .mergeMap( cycle => [
//                     {type:'SAVE_CYCLE_FULFILLED', payload:workstation},
//                     act_PushSnack('Takt wurde erfolgreich gespeichert')
//                 ])
//                 .catch(error => [
//                     {type:'SAVE_CYCLE_REJECTED', error},
//                     act_HandleError(error),
//                 ])
//                 .startWith( {type:'SAVE_CYCLE_PENDING'} )
//         })
// }
