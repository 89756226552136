import React from 'react';

import update from 'immutability-helper';

import muiThemeable from 'material-ui/styles/muiThemeable';

import moment from 'moment';


// COMPONENTS
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';

// ICONS
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';

class CommentSection extends React.Component {
    
    constructor() {
        super();
        this.state = {

        }
    }

    handleCreate() {
        this.props.onCreate()
    }
    handleChange(commentId, text, scope) {
        this.props.onChange(commentId, text, scope)
    }
    handleDelete(commentId) {
        this.props.onDelete(commentId)
    }

    render() {
        const { muiTheme, comments } = this.props
        const palette = muiTheme.palette
        const styles = {
            wrapper: {
                padding:10,

            },
            add:{
                margin:'5px 0px'
            }
        }
        return (
            <div style={ styles.wrapper }>
            { comments.map(c=>
                <Comment
                    key={c.id}
                    comment={c}
                    palette={ palette }
                    editable={ true }
                    onChange={ this.handleChange.bind(this) }
                    onDelete={ this.handleDelete.bind(this) }
                />
            )}
            <FlatButton
                label="Kommentar hinzufügen"
                style={ styles.add}
                primary={true}
                onClick={this.handleCreate.bind(this)}
            />
            </div>
        )
    }
};


class Comment extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            text: this.props.comment.text
        }
    }
    // handleEdit() {
    //     this.setState( update(this.state, {
    //         edit: {$set: true },
    //     }));
    // }
    handleChange(e){
        this.setState( update(this.state, {
            text: {$set: e.target.value },
            // edit: {$set: true },
        }));
    }
    handleBlur() {
        if(this.props.comment.text!=this.state.text) this.props.onChange( this.props.comment.id, this.state.text, this.props.comment.scope )
        // this.setState( update(this.state, {
        //     edit: {$set: false },
        // }));
    }
    handleDelete() {
        this.props.onDelete( this.props.comment.id )
    }

    render() {
        const { palette, comment, editable } = this.props
        const styles = {
            item:{ position:'relative', marginTop:10 },
            metaWrapper:{ 
                position:'relative'
            },
            meta:{ 
                padding:'0 10px', lineHeight:'18px',
                fontSize:14, color:palette.midgrey,
                borderBottom:palette.lightgrey + ' solid 1px'
            },
            menu:{
                position:'absolute', top:-20, right:0
            },
            text: {
                padding:10, fontSize:14, color:palette.textColor
            },
            commentInputWrapper:{
                position:'relative', padding:'0 10px'
            },
            commentInput:{ 
                fontSize:14, height:'auto', lineHeight:'normal', width:'calc(100% - 20px)', padding:'0px 10px', color: palette.textColor,
                pointerEvents:'auto',
            },
            commentToogle:{ }
        }
        return (
            <div style={ styles.item }>
                <div style={ styles.metaWrapper}>
                    {/* <div style={ styles.meta }>{ comment.creator.name + ' | ' + moment(comment.creationDate).format('DD.MM.YYYY HH:mm') }</div> */}
                    <div style={ styles.meta }>{ moment(comment.creationDate).format('DD.MM.YYYY HH:mm') }</div>
                    <IconMenu
                        style={ styles.menu }
                        iconButtonElement={<IconButton><MoreVertIcon color={ palette.midgrey }/></IconButton>}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        targetOrigin={{horizontal: 'right', vertical: 'top'}}
                    >
                        {/* <MenuItem primaryText="Editieren" onClick={ this.handleEdit.bind(this) } /> */}
                        <MenuItem primaryText="Löschen" onClick={ this.handleDelete.bind(this) } />
                    </IconMenu>
                </div>
                {   !editable &&
                    <div style={ styles.text }>
                    { 
                        this.state.text.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })
                    }
                    </div>
                }
                {   editable &&
                    <div style={ styles.commentInputWrapper }>
                        <TextField
                            hintText="Neuer Kommentar"
                            fullWidth
                            underlineShow={false}
                            multiLine={true}
                            rows={1}
                            rowsMax={10}
                            value={ this.state.text } 
                            onChange={ this.handleChange.bind(this) }
                            onBlur={ this.handleBlur.bind(this) }
                        />
                    </div>
                }
            </div>
        )
    }
};

export default muiThemeable()(CommentSection)