"use strict";

// DEPENDENCIES
import React from 'react';

import update from 'immutability-helper';

// MuiTheme
import muiThemeable from 'material-ui/styles/muiThemeable';

// COMPONENTS
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'
import IconButton from 'material-ui/IconButton';
import ExpandIcon from 'material-ui/svg-icons/navigation/expand-more';

// COMPONENT
export class ExpansionPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initOpen:this.props.open || false,
            open:this.props.open || false
        }
    }

    componentWillReceiveProps(nextProps) {
        if((nextProps.open || false) != this.state.initOpen){
            this.setState( update(this.state, { 
                open:{ $set: nextProps.open || false}
            }));
        }
    }

    handleBarTouch() {
        if(this.props.fullBarTrigger) this.handleExpand()
    }

    handleExpand() {
        this.setState( update(this.state, {
            open: {$apply: el=>!el}
        }));
    }

    render() {
        const palette = this.props.muiTheme.palette 
        const getMaxHeight = () => {
            let maxHeight = this.props.maxHeight + 'px' || 'none';
            return maxHeight
        }
        const getExpandAngle = () => {
            if(this.props.revert) return `rotate(${ (this.state.open) ? '0' : '180deg'})`
            else return `rotate(${ (this.state.open) ? '180deg' : '0deg'})`
        }
        const styles = {
            wrapper:{ ...this.props.style },
            bar:{ 
                position:'relative',
                height:48,
                padding:0, paddingRight:0, marginTop:14, 
                backgroundColor:'none', borderBottom:this.props.muiTheme.palette.lightgrey + ' solid 1px', 
                cursor:(this.props.fullBarTrigger)?'pointer':'auto',
                overflow:'hidden',
                ...this.props.barStyle 
            },
            trigger:{ position:'absolute', top:0, bottom:0, right:0, left:0, cursor:'pointer'},
            expand:{ 
                transform: getExpandAngle(),
                transition:'300ms'
            },
            icon:{ marginRight:24 },
            title:{ 
                fontSize:16, fontWeight:500, color: palette.textColor, 
                ...this.props.titleStyle  
            },
            flag:{ position:'absolute', top:0, bottom:0, transition:'300ms' },
            leftFlag:{ left:(this.state.open)?-8:0 },
            rightFlag:{ right:(this.state.open)?-16:0 },
            panel:{ maxHeight:getMaxHeight(), overflowY: this.props.maxHeight? 'scroll' : 'visible', ...this.props.panelStyle}
        }
        const getTitle = () => { 
            let t = this.props.title
            if(this.props.autoLength) t += ` (${ (this.props.children) ? this.props.children.length : 0})`
            return t
        }
        return (
            <div style={ styles.wrapper }>
                <Toolbar style={ styles.bar }>
                    {   this.props.fullBarTrigger &&
                        <div style={styles.trigger} onClick={this.handleBarTouch.bind(this)}/>
                    }
                    <ToolbarGroup style={{pointerEvents:'none'}}>
                        { this.props.icon && React.cloneElement(this.props.icon, {style: styles.icon}) }
                    </ToolbarGroup>
                    <ToolbarGroup style={{flexGrow:1,pointerEvents:'none' }}>
                        <ToolbarTitle style={ styles.title } text={ getTitle() }/>
                    </ToolbarGroup>
                    <ToolbarGroup style={{pointerEvents:'none'}}>
                        <IconButton onClick={this.handleExpand.bind(this)} style={ styles.expand }>
                            <ExpandIcon/>
                        </IconButton>
                    </ToolbarGroup>
                    { this.props.leftFlag && React.cloneElement(this.props.leftFlag, {style: {...styles.flag, ...styles.leftFlag} }) }
                    { this.props.rightFlag && React.cloneElement(this.props.rightFlag, {style: {...styles.flag, ...styles.rightFlag} }) }
                </Toolbar>
                { this.state.open &&
                    <div style={ styles.panel }>
                    {
                        this.props.children
                    }
                    </div>
                }
            </div>
        )
    }
};

export default muiThemeable()(ExpansionPanel)
