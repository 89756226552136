


export const act_LoginUser = () => ({ type:'LOGIN_USER' })

export const act_LoadMe = () => ({ type:'LOAD_ME' })

export const act_SaveUser = (payload) => ({ type:'SAVE_USER', payload})

export const act_Subscribe = (payload) => ({ type:'SUBSCRIBE', payload})

export const act_UnSubscribe = (payload) => ({ type:'UNSUBSCRIBE', payload})

export const act_LoginAdmin = (payload) => ({ type:'LOGIN_ADMIN', payload})

export const act_LogoutAdmin = (payload) => ({ type:'LOGOUT_ADMIN', payload})
