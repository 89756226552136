import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { OidcProvider } from 'redux-oidc';
import userManager from '../utils/userManager';

import Home from './home'
import Project from './project'
import User from './user'
import Settings from './settings'
import Splash from './splash'

const App = React.lazy(() => import(/* webpackChunkName: "app" */ './app'));

const Root = ({ store }) => (
    <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
            <Router>
                <Suspense fallback={<Splash/>}>
                    <App>
                        <Route exact path='/' component={Home}/>
                        <Route path='/projects/:id' component={Project}/>

                        <Route path='/user' component={User}/>
                        <Route path='/settings' component={Settings}/>
                    </App>
                </Suspense>
            </Router>
        </OidcProvider>
    </Provider>
)

// Root.propTypes = {
//   store: PropTypes.object.isRequired
// }

export default Root


// DOM


