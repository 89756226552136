
import update from 'immutability-helper';

// DEFAULT
const defaultState = {
    view: {
        open:false,
        anchor:null,
    },
    list: []
};


// REDUCER
const reducer = (state=defaultState, action) => {
    switch (action.type) {
        case 'LOAD_PROJECT': {
            state = update(state, {
                list: { $set: [] },
            });
            break;
        }
        case 'SET_NOTIFICATIONS': {
            const { task, notifications } = action.payload
            if(task==='set'){
                state = update(state, {
                    list: { $set: notifications },
                });
            } else if (task==='remove'){
                const removeIds = notifications.map(n=>n.id)
                const list = state.list.filter(n=>removeIds.indexOf(n.id)===-1)
                state = update(state, {
                    list: { $set: list },
                });
            } else if (task==='update'){
                notifications.forEach( n=> {
                    const index = state.list.findIndex(l=>l.id===n.id)
                    state = update(state, {
                        list: (index>-1) ? {$splice: [[index,1,n]]} : {$push: [n]},
                    });
                })
            }
            break;
        }

        case 'SHOW_NOTIFICATIONS': {
            state = update(state, {
                view:{ 
                    open: { $set:true },
                    anchor: { $set: action.payload }
                }
            });
            break;
        }
        case 'HIDE_NOTIFICATIONS': {
            state = update(state, {
                view:{ 
                    open: { $set:false },
                }
            });
            break;
        }
        case 'RESET_STATUS': {
            state = update(state, {
                list: {$set: []}
            });
            break;
        }
    }
    return state
}

// EXPORT
export default reducer;