import update from 'immutability-helper';
import userManager from "../utils/userManager";
const storedSettings = localStorage.getItem('settings')

// DEFAULT
const defaultState = {
    me: {
        data: {},
        subscriptions: [],
        settings: storedSettings ? JSON.parse(storedSettings) : {},
        rights: [],
        isAdmin: false
    },
    list: [],
}

// REDUCER
const reducer = (state=defaultState, action) => {
    switch (action.type) {
        case 'LOAD_ME_FULFILLED': {
            const { data, subscriptions, settings, roles } = action.payload
            state = update(state, {
                me: {
                    data: {$set: data},
                    subscriptions: {$set: subscriptions},
                    // settings: {$set: settings},
                    roles: {$set: roles},
                    isAdmin: {$set: roles.indexOf('AUDI_TMR_ADMIN')>-1},
                }
            });
            break;
        }
        case 'SAVE_USER_FULFILLED': {
            const data = action.payload
            const index = state.list.findIndex( u => u.id==data.id );
            state = update(state, {
                list: (index>-1) ? {$splice: [[index,1,data]]} : {$push: [data]},
                me: {data: {$apply: p=> (p.id==data.id) ? data : p}}
            });
            break;
        }
        case 'SUBSCRIBE': {
            const { projectId, sectionId } = action.payload
            state = update(state, {
                me: {subscriptions: {$push: [{projectId, sectionId}]}}
            });
            break;
        }
        case 'UNSUBSCRIBE': {
            const { projectId, sectionId } = action.payload
            const index = state.me.subscriptions.findIndex( s => s.projectId==projectId && s.sectionId==sectionId );
            state = update(state, {
                me: {subscriptions: {$splice: [[index,1]]}},
            });
            break;
        }
        case 'SAVE_SETTINGS': {
            const settings = action.payload
            state = update(state, {
                me: {
                    settings: {$set: settings}
                }
            });
            localStorage.setItem('settings',JSON.stringify(settings))
            break;
        }
        // case 'redux-oidc/USER_EXPIRED': {
        //     userManager.signinRedirect();
        //     break;
        // }
        
    }
    return state
}

// EXPORT
export default reducer;