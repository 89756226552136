"use strict";

// DEPENDENCIES
import React from 'react';

import _ from 'lodash'

// MuiTheme
import muiThemeable from 'material-ui/styles/muiThemeable';

// COMPONENTS
import { 
    XYPlot, FlexibleWidthXYPlot,
    XAxis, YAxis, HorizontalGridLines,
    LineSeries, LineMarkSeries, VerticalRectSeries, AreaSeries,
    RadialChart,
    DiscreteColorLegend,
    Hint
} from 'react-vis'
import '../../node_modules/react-vis/dist/style.css';

import Menu from 'material-ui/Menu';
import MenuItem  from 'material-ui/MenuItem';

// ICONS
import DummyIcon from 'material-ui/svg-icons/av/stop';



export const ChartSpAp = props => {

    const { project, height, width, data, palette  } = props

    const getColor = (index, value) => {
        switch (index) {
            case 0: return palette.primary1Color
            case 1: return palette.defaultColor
        }
    }
    const getIcon = (index, value) => {
        return <DummyIcon/>
    }
    const value = data[0].value

    return (
        <DonutChart
            height={ height }
            width={ width }
            data={ data.map( (s,i) => ({
                theta: s.value, 
                label: s.name, 
                icon: getIcon(i,s.value),
                color: getColor(i,s.value)
            }) ) }
            value={ value.toLocaleString('de-De',{maximumFractionDigits:0}) }
            valueStyle={{ color:palette.textColor }}
            palette={ palette }
        />
    )
}

export const ChartMB = props => {
      
    const { project, height, width, data, palette  } = props

    const getColor = (index, value) => {
        switch (index) {
            case 0: return palette.primary1Color
            case 1: return palette.defaultColor
        }
    }
    const getIcon = (index, value) => {
        return <DummyIcon/>
    }
    const value = data[0].value

    return (
        <DonutChart
            height={ height }
            width={ width }
            data={ data.map( (s,i) => ({
                theta: s.value, 
                label: s.name, 
                icon: getIcon(i,s.value),
                color: getColor(i,s.value)
            }) ) }
            value={ value.toLocaleString('de-De',{maximumFractionDigits:0}) }
            valueStyle={{ color:palette.textColor }}
            palette={ palette }
        />
    )
}

export const ChartWorker = props => {

    const { height, width, data, palette  } = props

    return (
        <SingleValueChart
            height={ height }
            width={ width }
            value={ data.value }
            palette={ palette}
        />
    )
}

export const ChartWorkload = props => {

    const { project, height, width, data, palette  } = props

    const getColor = (index, value) => {
        switch (index) {
            case 0: return (value>=project.min_workload && value<=project.max_workload) 
                ? palette.ioColor
                : palette.nioColor
            case 1: return palette.defaultColor
        }
    }
    const getIcon = (index, value) => {
        return <DummyIcon/>
    }
    const value = data[0].value*100

    return (
        <DonutChart
            height={ height }
            width={ width }
            data={ data.map( (s,i) => ({
                theta: s.value*100, 
                label: s.name, 
                icon: getIcon(i,s.value), 
                color: getColor(i,s.value)
            }) ) }
            value={ value.toLocaleString('de-De',{maximumFractionDigits:2}) +'%' }
            valueStyle={{ color:palette.textColor }}
            palette={ palette }
        />
    )
}

export const ChartTimeOverage = props => {
  
    const { height, width, data, palette  } = props

    const value = data.value.toLocaleString('de-De',{maximumFractionDigits:4}) + ' min'

    return (
        <SingleValueChart
            height={ height }
            width={ width }
            value={ value }
            palette={ palette}
        />
    )
}

export const ChartHeight = props => {

    const { height, width, data, palette  } = props

    const series = data.map( (d,i) => ({
        data: d.data,
        label: d.name,
        icon: <DummyIcon/>,
        color:palette[`diagram${i+1}Color`]
    }) )

    // const xValues = Array.from(new Array(cycles.length),(v,i)=>i+.5);
    // const maxXValues = Math.round( (width-130) / 40)
    // const valueCounter = Math.max(1,Math.round(cycles.length / maxXValues))
    // const xValuesSizeFiltered = xValues.filter( (v, i) => i % valueCounter == 0 );
    // const xFormat = (v) => cycles[Math.floor(v)].number 
    return (
        <LineChart
            height={ height }
            width={ width }
            series={ series }
            // xDomain={ [0,cycles.length] } 
            // xTickFormat={ xFormat }
            // xTickValues={ xValuesSizeFiltered }
            yDomain={ [0,250] }
            palette={ palette }
        />
    )
}

export const ChartStatusGesamtaussage = props => {
  
    const { height, width, data, palette  } = props

    const getColor = (index, value) => {
        switch (index) {
            case 0: return palette.ioColor
            case 1: return palette.nioColor
            case 2: return palette.defaultColor
        }
    }
    const getIcon = (index, value) => {
        return <DummyIcon/>
    }
    const value = data.reduce((p,c)=>p+c.value,0)

    return (
        <DonutChart
            height={ height }
            width={ width }
            data={ data.map( (s,i) => ({
                theta: s.value, 
                label: s.name, 
                icon: getIcon(i,s.value), 
                color: getColor(i,s.value)
            }) ) }
            value={ value.toLocaleString('de-De',{maximumFractionDigits:0}) }
            valueStyle={{ color:palette.textColor }}
            palette={ palette }
        />
    )
}

export const ChartStatusMaterialbereitstellung = props => {
  
    const { height, width, data, palette  } = props

    const getColor = (index, value) => {
        switch (index) {
            case 0: return palette.ioColor
            case 1: return palette.nioColor
            case 2: return palette.defaultColor
        }
    }
    const getIcon = (index, value) => {
        return <DummyIcon/>
    }
    const value = data.reduce((p,c)=>p+c.value,0)

    return (
        <DonutChart
            height={ height }
            width={ width }
            data={ data.map( (s,i) => ({
                theta: s.value, 
                label: s.name, 
                icon: getIcon(i,s.value), 
                color: getColor(i,s.value)
            }) ) }
            value={ value.toLocaleString('de-De',{maximumFractionDigits:0}) }
            valueStyle={{ color:palette.textColor }}
            palette={ palette }
        />
    )
}

export const ChartStatusWorkload = props => {
  
    const { height, width, data, palette  } = props

    const getColor = (index, value) => {
        switch (index) {
            case 0: return palette.ioColor
            case 1: return palette.nioColor
            case 2: return palette.defaultColor
        }
    }
    const getIcon = (index, value) => {
        return <DummyIcon/>
    }
    const value = data.reduce((p,c)=>p+c.value,0)

    return (
        <DonutChart
            height={ height }
            width={ width }
            data={ data.map( (s,i) => ({
                theta: s.value, 
                label: s.name, 
                icon: getIcon(i,s.value), 
                color: getColor(i,s.value)
            }) ) }
            value={ value.toLocaleString('de-De',{maximumFractionDigits:0}) }
            valueStyle={{ color:palette.textColor }}
            palette={ palette }
        />
    )
}

export const ChartStatusParts = props => {
  
    const { height, width, data, palette  } = props

    const getColor = (index, value) => {
        switch (index) {
            case 0: return palette.ioColor
            case 1: return palette.nioColor
            case 2: return palette.defaultColor
        }
    }
    const getIcon = (index, value) => {
        return <DummyIcon/>
    }
    const value = data.reduce((p,c)=>p+c.value,0)

    return (
        <DonutChart
            height={ height }
            width={ width }
            data={ data.map( (s,i) => ({
                theta: s.value, 
                label: s.name, 
                icon: getIcon(i,s.value), 
                color: getColor(i,s.value)
            }) ) }
            value={ value.toLocaleString('de-De',{maximumFractionDigits:0}) }
            valueStyle={{ color:palette.textColor }}
            palette={ palette }
        />
    )
}

export const ChartStatusScrewcases = props => {

    const { height, width, data, palette  } = props

    const getColor = (index, value) => {
        switch (index) {
            case 0: return palette.ioColor
            case 1: return palette.nioColor
            case 2: return palette.defaultColor
        }
    }
    const getIcon = (index, value) => {
        return <DummyIcon/>
    }
    const value = data.reduce((p,c)=>p+c.value,0)

    return (
        <DonutChart
            height={ height }
            width={ width }
            data={ data.map( (s,i) => ({
                theta: s.value, 
                label: s.name, 
                icon: getIcon(i,s.value), 
                color: getColor(i,s.value)
            }) ) }
            value={ value.toLocaleString('de-De',{maximumFractionDigits:0}) }
            valueStyle={{ color:palette.textColor }}
            palette={ palette }
        />
    )
}

export const ChartStatusBemis = props => {
       
    const { height, width, data, palette  } = props

    const getColor = (index, value) => {
        switch (index) {
            case 0: return palette.ioColor
            case 1: return palette.nioColor
            case 2: return palette.defaultColor
        }
    }
    const getIcon = (index, value) => {
        return <DummyIcon/>
    }
    const value = data.reduce((p,c)=>p+c.value,0)

    return (
        <DonutChart
            height={ height }
            width={ width }
            data={ data.map( (s,i) => ({
                theta: s.value, 
                label: s.name, 
                icon: getIcon(i,s.value), 
                color: getColor(i,s.value)
            }) ) }
            value={ value.toLocaleString('de-De',{maximumFractionDigits:0}) }
            valueStyle={{ color:palette.textColor }}
            palette={ palette }
        />
    )
}

export const SingleValueChart = props => {

    const { value, color, height, width, palette } = props

    const styles = {
        chartBox:{ display:'flex', justifyContent:'center', alignItems:'center', width, height, overflow:'hidden', },
        value:{ 
            marginBottom:24, 
            color:palette.textColor, fontSize:38, fontWeight:'bold', fontFamily:'Roboto'
        },
        empty:{ fontSize:14, textAlign:'center', color:palette.textColor, lineHeight:height+'px'}
    }

    return (
        <div style={ styles.chartBox }>
            {   value 
                ? <div style={ styles.value }>{value}</div>
                : <div style={ styles.empty }>{'Für diesen Bereich sind keine Daten verfügbar.'}</div>
            }
        </div>
    )
}

export class DonutChart extends React.Component {

    constructor() {
        super() 
        this.state = {
            hint:false
        }
    }

    render() {
        const { value, data, palette, height, width, valueStyle } = this.props
        const { hint } = this.state

        const styles = {
            chartBox:{ position:'relative', width:'100%', overflow:'hidden', whiteSpace:'nowrap' },
            chart:{ position:'relative', display:'inline-block', verticalAlign:'middle' },
            total:{ 
                position:'absolute', top:0, bottom:0, left:0, right:0,
                display:'flex',flexDirection:'column',alignItems:'center', justifyContent:'center',
                lineHeight:'30px', textAlign:'center',
                fontFamily:'Roboto', fontSize:20, fontWeight:'bold', color: palette.textColor,
                ...valueStyle
            },
            hint:{ backgroundColor:palette.darkgrey, borderRadius:2, padding:'5px 10px', fontSize:12, fontFamily:'Roboto', color:'white', whiteSpace:'nowrap' },
            hintValue:{ fontWeight:'bold' },
            empty:{ fontSize:14, textAlign:'center', color:palette.textColor, lineHeight:height+'px'}
        }

        return (
            <div style={ styles.chartBox }>
                {   data.length>0 && 
                    <React.Fragment>
                        <div style={ styles.chart }>
                            <div style={ styles.total }>{ value }</div>
                            <RadialChart
                                width={height} height={height}
                                innerRadius={60}
                                radius={80}
                                data={ data.map( d => ({theta:d.theta, color:d.color, label:d.label})) }
                                getAngle={d => d.theta}
                                colorType="literal"
                                animation
                                onValueMouseOver={v => this.setState({hint:v})} 
                                onSeriesMouseOut={v => this.setState({hint: false})}
                            >
                            {   hint && 
                                <Hint value={ hint } orientation={ 'topleft' }>
                                    <div style={ styles.hint }>
                                        <span>{ hint.label+': ' }</span>
                                        <span style={ styles.hintValue }>{ hint.theta.toLocaleString('de-De',{maximumFractionDigits:2}) }</span>
                                    </div>
                                </Hint>
                            }
                            </RadialChart>
                        </div>
                        <CustomLegend data={ data } height={height}/>
                    </React.Fragment>
                }
                {   data.length==0 && 
                    <div style={ styles.empty }>{'Für diesen Bereich sind keine Daten verfügbar.'}</div>
                }
            </div>
        )
    }
}

export class LineChart extends React.Component {

    constructor() {
        super()
        this.state = {
            hint:false
        }
    }

    render() {
        const { height, width, series, xDomain, xTickValues, xTickFormat, yDomain, palette } = this.props
        const { hint } = this.state

        const styles = {
            chartBox:{ position:'relative', textAlign:'center', width:'100%', overflow:'hidden', whiteSpace:'nowrap' },
            chart:{ position:'relative', display:'inline-block', verticalAlign:'middle' },
            hint:{ backgroundColor:palette.darkgrey, borderRadius:2, padding:'5px 10px', fontSize:12, fontFamily:'Roboto', color:'white', whiteSpace:'nowrap' },
            hintValue:{ fontWeight:'bold' },
            empty:{ fontSize:14, color:palette.textColor, lineHeight:height+'px'}
        }
        return (
            <div style={ styles.chartBox }>
                {   series.length>0 &&
                    <React.Fragment>
                        <div style={ styles.chart }>
                            <XYPlot margin={{left:70, top:20}} width={width-150} height={height} animation colorType="literal" xDomain={xDomain} yDomain={yDomain}>
                                <XAxis tickValues={ xTickValues } tickFormat={ xTickFormat }/>
                                <YAxis/>
                                <HorizontalGridLines/>
                                {   series.map( s => 
                                    <LineSeries 
                                        key={s.label} 
                                        data={ s.data }
                                        color={ s.color }
                                    />
                                )}
                            </XYPlot>
                        </div>
                        <CustomLegend data={ series } height={height}/>
                    </React.Fragment>
                }
                {   series.length==0 && 
                    <div style={ styles.empty }>{'Für diesen Bereich sind keine Daten verfügbar.'}</div>
                }
            </div>
        )
    }
} 

const CustomLegend = props => {
    const { data, height } = props
    const styles={
        legend:{ display:'inline-block', verticalAlign:'middle', width:130, height, textAlign:'left', overflowY:'auto' },
        legendItem:{ minHeight:30, lineHeight:'30px', fontSize:14 },
        innerDiv:{ paddingLeft:40 },
        legendIcon:{ margin:'5px 8px', height:20, width:'auto' },
    }
    return (
        <Menu style={ styles.legend } autoWidth={false}>
        {   data.filter(d=>d.legend!=false).map( (d,i) =>
            <MenuItem
                key={ i }
                style={ styles.legendItem }
                innerDivStyle={ styles.innerDiv }
                leftIcon={ (d.icon) ? React.cloneElement(d.icon, {style:{...styles.legendIcon, fill:d.color}}) : null }
                primaryText={ d.label }
            />
        )}
        </Menu>
    )
}