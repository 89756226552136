
import update from 'immutability-helper';

// DEFAULT
const defaultState = {
    list: {
        pending:false,
        fulfilled:false,
        items:[]
    },
    current: {
        pending:false,
        fulfilled:false,
        data:{},
        blacklist:[],
        items:[]
    },
    charts: []
};


// REDUCER
const reducer = (state=defaultState, action) => {
    switch (action.type) {
        case 'LOAD_CHART_CONFIG_FULFILLED': {
            state = update(state, {
                charts: {$set: action.payload}
            });
            break;
        }
        case 'LOAD_DASHBOARDS_PENDING': {
            state = update(state, {
                list: {
                    pending: {$set: true},
                    fulfilled: {$set: false},
                }
            });
            break;
        }
        case 'LOAD_DASHBOARDS_FULFILLED': {
            state = update(state, {
                list: {
                    pending: {$set: false},
                    fulfilled: {$set: true},
                    items: {$set: action.payload}
                }
            });
            break;
        }
        case 'LOAD_DASHBOARDS_REJECTED': {
            state = update(state, {
                list: {
                    pending: {$set: false},
                    fulfilled: {$set: false},
                    items: {$set: []}
                }
            });
            break;
        }
        // case 'LOAD_DASHBOARD': {
        //     const { dashboardId } = action.payload
        //     const data = Object.assign( {}, state.list.items.find(d=>d.id==dashboardId) )
        //     state = update(state, {
        //         current: {
        //             data: {$set: data},
        //         }
        //     });
        //     break;
        // }
        case 'LOAD_DASHBOARD_PENDING': {
            state = update(state, {
                current: {
                    pending: {$set: true},
                    fulfilled: {$set: false},
                }
            });
            break;
        }
        case 'LOAD_DASHBOARD_FULFILLED': {
            const { dashboard, blacklist, items } = action.payload
            state = update(state, {
                current: {
                    pending: {$set: false},
                    fulfilled: {$set: true},
                    data: {$set: dashboard},
                    blacklist: {$set: blacklist},
                    items: {$set: items},
                }
            });
            break;
        }
        case 'LOAD_DASHBOARD_REJECTED': {
            state = update(state, {
                current: {
                    pending: {$set: false},
                    fulfilled: {$set: false},
                    data: {$set: {} },
                    blacklist: {$set: [] },
                    items: {$set: [] }
                }
            });
            break;
        }
        case 'CREATE_DASHBOARD_FULFILLED': {
            state = update(state, {
                list: {items: {$push: [action.payload] }}
            });
            break;
        }
        case 'DELETE_DASHBOARD': {
            const { dashboardId } = action.payload
            const index = state.list.items.findIndex( d => d.id == dashboardId )
            state = update(state, {
                list: {items: { $splice: [[index,1]]}},
            });
            if( dashboardId == state.current.data.id){
                state = update(state, {
                    current:{
                        data: {$set: {} },
                        blacklist: {$set: [] },
                        items: {$set: [] }
                    },
                });
            }
            break;
        }
        case 'DUPLICATE_DASHBOARD_FULFILLED': {
            state = update(state, {
                list: {items: {$push: [action.payload] }}
            });
            break;
        }
        case 'UPDATE_DASHBOARD_DATA_FULFILLED': {
            const data = action.payload
            const dashboardId = data.id
            const index = state.list.items.findIndex( d => d.id == dashboardId )
            state = update(state, {
                list: {items: { $splice: [[index,1,data]]}},
            });
            state = update(state, {
                list:{
                    items: { $apply: items => items.sort((a,b)=>a.position-b.position) },
                },
            });
            if( dashboardId == state.current.data.id){
                state = update(state, {
                    current:{
                        data: {$set: data }
                    },
                });
            }
            break;
        }
        case 'CHANGE_DASHBOARD_BLACKLIST_FULFILLED': {
            const {dashboard,blacklist} = action.payload
            state = update(state, {
                current:{
                    data: {$set: dashboard },
                    blacklist: {$set: blacklist },
                },
            });
            break;
        }
        case 'CHANGE_DASHBOARD_POSITION': {
            const { dashboardId, position} = action.payload
            const dashboard = state.list.items.find(d=>d.id===dashboardId)
            const oldPosition = dashboard.position
            let dashboards = state.list.items.filter(d=>d.public===dashboard.public).sort((a,b)=>a.position - b.position)
            dashboards.splice(oldPosition,1)
            dashboards.splice(position,0,dashboard)
            dashboards.map((d,i)=>{
                const index = state.list.items.findIndex(({id})=>id===d.id)
                state = update(state, {
                    list:{
                        items: {[index]: { $apply: item => ({...item,position:i}) }},
                    },
                });
            })
            state = update(state, {
                list:{
                    items: { $apply: items => items.sort((a,b)=>a.position-b.position) },
                },
            });
            break;
        }
        case 'ADD_CHART_FULFILLED': {
            state = update(state, {
                current:{
                    items: {$push: [action.payload]}
                },
            });
            break;
        }
        case 'CHANGE_CHART_BLACKLIST_FULFILLED': {
            const item = action.payload
            const index = state.current.items.findIndex(el=>el.id==item.id)
            state = update(state, {
                current:{
                    items: {[index]: {
                        blacklist_id: {$set: item.blacklist_id},
                        blacklist: {$set: item.blacklist}
                    }}
                },
            });
            break;
        }
        case 'CHANGE_CHART_SIZE_FULFILLED': {
            const item = action.payload
            const index = state.current.items.findIndex(el=>el.id==item.id)
            state = update(state, {
                current:{
                    items: {[index]: {
                        size: {$set: item.size}
                    }}
                },
            });
            break;
        }
        case 'CHANGE_CHART_POSITION': {
            const { dashboardItemId, position} = action.payload
            const oldPosition = state.current.items.findIndex(d=>d.id==dashboardItemId)
            const item = state.current.items[oldPosition]
            state = update(state, {
                current:{
                    items: { $splice: [[oldPosition, 1], [position, 0, item]] },
                },
            });
            state = update(state, {
                current:{
                    items: { $apply: items => items.map((el,i)=>({...el,position:i})) },
                },
            });
            break;
        }

        case 'DELETE_CHART_FULFILLED': {
            const { dashboardItemId } = action.payload
            const index = state.current.items.findIndex(d=>d.id==dashboardItemId)
            state = update(state, {
                current:{
                    items: { $splice: [[index,1]]},
                },
            });
            break;
        }

        case 'LOAD_CHART_DATA_PENDING': {
            const { dashboardItemId } = action.payload
            const index = state.current.items.findIndex(d=>d.id==dashboardItemId)
            state = update(state, {
                current:{
                    items: {[index]: {
                        pending: {$set: true},
                        rejected: {$set: false}
                    }}
                },
            });
            break;
        }

        case 'LOAD_CHART_DATA_FULFILLED': {
            const { dashboardItemId, data, updates } = action.payload
            const index = state.current.items.findIndex(d=>d.id==dashboardItemId)
            state = update(state, {
                current:{
                    items: {[index]: {
                        pending: {$set: false},
                        fulfilled: {$set: true},
                        rejected: {$set: false},
                        data: {$set: data},
                        updates: {$set: updates}
                    }}
                },
            });
            break;
        }

        case 'LOAD_CHART_DATA_REJECTED': {
            const { dashboardItemId } = action.payload
            const index = state.current.items.findIndex(d=>d.id==dashboardItemId)
            state = update(state, {
                current:{
                    items: {[index]: {
                        pending: {$set: false},
                        fulfilled: {$set: false},
                        rejected: {$set: true},
                        data: {$set: null}
                    }}
                },
            });
            break;
        }
    }
    return state
}

// EXPORT
export default reducer;