
// DEPENDENCIES
import React from 'react';
import update from "immutability-helper";

// MuiTheme
import muiThemeable from 'material-ui/styles/muiThemeable'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { lightTheme, darkTheme } from '../theme';

// COMPONENTS
import IconMenu from 'material-ui/IconMenu';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import IconButton from 'material-ui/IconButton';
import InfoIcon from 'material-ui/svg-icons/action/info';
import { List, ListItem } from 'material-ui/List';
import Checkbox from 'material-ui/Checkbox';
import CircularProgress from 'material-ui/CircularProgress';
// ICONS
import { BlacklistIcon } from '../assets/icons'


// COMPONENT
class Blacklist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open:false,
            change:false,
            blacklist:this.props.blacklist
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState(update(this.state,{
            blacklist: {$set:nextProps.blacklist},
            change: {$set:false}
        }))
    }
    handleOpen() {
        this.setState(update(this.state,{
            open: {$set:true}
        }))
        if(this.props.onOpen) setTimeout(this.props.onOpen,0)
    }
    handleClose(open,reason){
        if(open) return 
        this.setState(update(this.state,{
            open: {$set:false}
        }))
        if(this.state.change && this.props.onClose) this.props.onClose(this.state.blacklist)
    }
    handleSelectItem(type,id) {
        let blacklist = this.state.blacklist.slice()
        const index = blacklist.findIndex(itm=>itm.type===type&&itm.id===id)
        if(index>-1) blacklist.splice(index,1)
        else blacklist.push({type,id})

        if(this.props.onChange) this.props.onChange(blacklist)
        else{
            this.setState(update(this.state,{
                change: {$set:true},
                blacklist: {$set:blacklist}
            }))
        }
    }
    render() {
        const { open, blacklist } = this.state
        const { plan, buttonType, buttonStyle, muiTheme } = this.props
        const palette = muiTheme.palette
        const styles = {
            iconBtn:{
                ...buttonStyle,
                color: blacklist.length>0 ? palette.primary1Color : palette.midgrey
            },
            flatBtn:{
                ...buttonStyle,
                color: blacklist.length>0 ? 'white' : palette.textColor,
                backgroundColor: blacklist.length>0 ? palette.primary1Color : palette.midgrey
            },
            raisedBtn:{
                ...buttonStyle,
                backgroundColor: blacklist.length>0 ? palette.primary1Color : palette.midgrey
            },
            tooltip:{ backgroundColor:'white', color:palette.textColor },
            progress:{ 
                position:'relative', width:'100%', height:100,
                display:'flex', justifyContent:'center', alignItems:'center',
                backgroundColor:'rgba(0,0,0,0)'
            },
            menu:{ width:400},//, maxHeight:'700px' } 
            list:{display:'block'}
        }        
        const getButton = () => {
            switch (buttonType) {
                case 'Flatbutton': return <FlatButton key={1} style={ styles.flatBtn } label="Kontext-Filter" primary={true}/>
                case 'RaisedButton': return <RaisedButton key={1} style={ styles.raisedBtn } label="Kontext-Filter" primary={true}/>
                default: case 'IconButton': return (
                    <IconButton style={styles.iconBtn} tooltip='Kontext-Filter' tooltipPosition='top-center' tooltipStyles={ styles.tooltip }>
                        <BlacklistIcon color={styles.iconBtn.color}/>
                    </IconButton> 
                )
            }
        }
        return (
            <MuiThemeProvider muiTheme={ lightTheme }>
            <React.Fragment>
                <IconMenu
                    // style={styles.btn}
                    iconButtonElement={ getButton() }
                    // anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    // targetOrigin={{horizontal: 'right', vertical: 'top'}}
                    open={ open }
                    onRequestChange={ this.handleClose.bind(this) }
                    onClick={ this.handleOpen.bind(this) }
                    menuStyle={ styles.menu }
                    listStyle={ styles.list }
                >
                {   !plan.fulfilled && 
                    <div style={ styles.progress }>
                        <CircularProgress size={50} thickness={4}/> 
                    </div>
                }
                {   plan.fulfilled &&
                    <List>
                    {   plan.sections.map( s => 
                        <ListItem 
                            key={ s.id }
                            primaryText={ <div style={ styles.listItemLabel }>{s.name}</div> } 
                            leftIcon={ 
                                <Checkbox
                                    style={ styles.checkbox }
                                    iconStyle={ styles.checkboxIcon }
                                    checked={ !blacklist.find(itm=>itm.type==='section'&&itm.id===s.id) }
                                    onCheck={ (e,v) => this.handleSelectItem('section',s.id) }
                                />
                            }
                            primaryTogglesNestedList={false} 
                            innerDivStyle={ styles.listItemInnerDiv }
                            nestedItems={
                                plan.cycles.filter(c => c.parentId===s.id).map( c=> 
                                    <ListItem 
                                        key={ c.id }
                                        primaryText={ <div style={ styles.listItemLabel }>{`${c.number} - ${c.name}`}</div> }
                                        leftIcon={ 
                                            <Checkbox
                                                style={ styles.checkbox }
                                                iconStyle={ styles.checkboxIcon }
                                                checked={ !blacklist.find(itm=>itm.type==='cycle'&&itm.id===c.id) }
                                                onCheck={ (e,v) => this.handleSelectItem('cycle',c.id) }
                                            />
                                        }
                                        primaryTogglesNestedList={false} 
                                        innerDivStyle={ styles.listItemInnerDiv }
                                        nestedItems={
                                            plan.workstations.filter(w=> w.parentId==c.id).map( w => 
                                                <ListItem 
                                                    key={ w.id }
                                                    primaryText={ <div style={ styles.listItemLabel }>{`${w.number} - ${w.name}`}</div> }
                                                    leftIcon={ 
                                                        <Checkbox
                                                            style={ styles.checkbox }
                                                            iconStyle={ styles.checkboxIcon }
                                                            checked={ !blacklist.find(itm=>itm.type==='workstation'&&itm.id===w.id) }
                                                            onCheck={ (e,v) => this.handleSelectItem('workstation',w.id) }
                                                        />
                                                    }
                                                >
                                                </ListItem>
                                            )
                                        }
                                    />
                                )
                            }
                        />
                    )}
                    </List> 
                }
                </IconMenu>
            </React.Fragment>
            </MuiThemeProvider>
        )
    }
};



// EXPORT
export default muiThemeable()(Blacklist)
