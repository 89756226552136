
import { Observable } from 'rxjs';

// ACTIONS
import { act_HandleError } from '../actions/appActions';
import api from '../api';


// -----------------------------------------------------
// EPICS
export const epc_LoadPlans = (action$) => {
    return action$.ofType('LOAD_PLANS')
        .mergeMap( action => api.get_plans()
            .mergeMap( payload => [
                {type:'LOAD_PLANS_FULFILLED', payload}
            ])
            .catch( error => [
                {type:'LOAD_PLANS_REJECTED',error},
                act_HandleError(error)
            ])
            .startWith({ type:'LOAD_PLANS_PENDING' })
        )
}

export const epc_LoadPlan = (action$, store) => {
    return action$.ofType('LOAD_PLAN')
        .mergeMap( action => {
            const projectId = action.payload
            if(projectId==='new') return [{type:'LOAD_PLAN_REJECTED'}]
            else return api.get_plan({params:{projectId}})
                .mergeMap( payload => [
                    {type:'LOAD_PLAN_FULFILLED', payload}
                ])
                .catch(error => [
                    {type:'LOAD_PLAN_REJECTED', error},
                    act_HandleError(error)
                ])
                .startWith( {type:'LOAD_PLAN_PENDING'} )    
        })
}
export const epc_LoadTempPlan = (action$, store) => {
    return action$.ofType('LOAD_TEMP_PLAN')
        .mergeMap( action => {
            const {line_key, plan_key} = action.payload
            return api.get_raw_plan({data:{line_key, plan_key}})
                .mergeMap( payload => [
                    {type:'LOAD_TEMP_PLAN_FULFILLED', payload}
                ])
                .catch(error => [
                    {type:'LOAD_TEMP_PLAN_REJECTED', error},
                    act_HandleError(error)
                ])
                .startWith( {type:'LOAD_TEMP_PLAN_PENDING'} )    
        })
}