// ACTIONS
import { act_LoadProject } from '../actions/projectActions'
import { act_LoadPlan } from '../actions/planActions'
import { act_HandleError } from '../actions/appActions';
import api from '../api';
import { act_LoadDashboards } from '../actions/dashboardActions';
import { saveAs } from 'file-saver';
import JSZip from 'jszip'


// -----------------------------------------------------
// EPICS
export const epc_LoadProjects = (action$) => {
    return action$.ofType('LOAD_PROJECTS')
        .mergeMap( action => api.get_projects()
            .mergeMap( payload => [
                {type:'LOAD_PROJECTS_FULFILLED', payload}
            ])
            .catch( error => [
                {type:'LOAD_PROJECTS_REJECTED',error},
                act_HandleError(error)
            ])
            .startWith({ type:'LOAD_PROJECTS_PENDING' })
        )
}

export const epc_LoadProject = (action$, store) => {
    return action$.ofType('LOAD_PROJECT')
        .mergeMap( action => {
            const projectId = action.payload
            if(action.payload=='new') return [{type:'LOAD_PROJECT_FULFILLED', payload:api.get_default_project()}]
            else return api.get_project({params:{projectId}})
                .mergeMap( payload => [
                    {type:'LOAD_PROJECT_FULFILLED', payload},
                ])
                .catch(error => [
                    {type:'LOAD_PROJECT_REJECTED', error},
                    act_HandleError(error)
                ])
                .startWith( {type:'LOAD_PROJECT_PENDING'} )    
        })
}

export const epc_SaveProject = (action$, store) => {
    return action$.ofType('SAVE_PROJECT')
        .mergeMap( action => {
            const { projectId, data, history } = action.payload
            const request$ = (projectId=='new') 
                ?   api.create_project({data})
                :   api.update_project({params:{projectId}, data})
            return request$
                .mergeMap( payload => {
                    if(projectId=='new') history.push(`/projects/${payload.id}/plan`)
                    return [{type:'SAVE_PROJECT_FULFILLED', payload:{projectId: payload.id, data: payload}}] 
                })
                .catch(error => [
                    {type:'SAVE_PROJECT_REJECTED', error},
                    act_HandleError(error)
                ])
                .startWith( {type:'SAVE_PROJECT_PENDING'} )    
        })
}

export const epc_DeleteProject = (action$, store) => {
    return action$.ofType('DELETE_PROJECT')
        .mergeMap( action => {
            const { projectId } = action.payload
            return api.delete_project({params:{projectId}})
                .mergeMap( payload => [
                    {type:'DELETE_PROJECT_FULFILLED', payload:{projectId}}
                ])
                .catch(error => [
                    {type:'DELETE_PROJECT_REJECTED', error},
                    act_HandleError(error)
                ])
                .startWith( {type:'DELETE_PROJECT_PENDING'} )    
        })
}

export const epc_ChangeProjectPosition = (action$, store) => {
    return action$.ofType("CHANGE_PROJECT_POSITION").mergeMap(action => {
      const { projectId, position } = action.payload;
      const params = { projectId };
      const data = { position };
      return api.update_project_position({ params, data })
        .mergeMap(payload => [
          { type: "CHANGE_PROJECT_POSITION_FULFILLED", payload }
        ])
        .catch(err => [
          { type: "CHANGE_PROJECT_POSITION_REJECTED" },
          act_HandleError(err)
        ])
        .startWith({ type: "CHANGE_PROJECT_POSITION_PENDING" });
    });
  };

export const epc_LoadPrograms = (action$) => {
    return action$.ofType('LOAD_PROGRAMS')
        .mergeMap( action => {
            const { line_key } = action.payload
            return api.get_programs({data:{line_key}})
            .mergeMap( payload => [
                {type:'LOAD_PROGRAMS_FULFILLED', payload}
            ])
            .catch( error => [
                {type:'LOAD_PROGRAMS_REJECTED',error},
                act_HandleError(error)
            ])
            .startWith({ type:'LOAD_PROGRAMS_PENDING' })
        })
}

export const epc_LoadFilter = (action$) => {
    return action$.ofType('LOAD_FILTER')
        .mergeMap( action => {
            const { project_id, line_key, plan_key, program_id } = action.payload
            let data = { project_id, line_key, plan_key }; if(program_id) data = {...data,program_id}
            return api.get_filter({data})
            .mergeMap( payload => [
                {type:'LOAD_FILTER_FULFILLED', payload}
            ])
            .catch( error => [
                {type:'LOAD_FILTER_REJECTED',error},
                act_HandleError(error)
            ])
            .startWith({ type:'LOAD_FILTER_PENDING' })
        })
}
export const epc_LoadExportPreview = (action$) => {
    return action$.ofType('LOAD_EXPORT_PREVIEW')
        .mergeMap( action => {
            const { project_id, export_id } = action.payload
            return api.get_export_preview({params:{project_id,export_id}})
                .mergeMap( payload => [{type:'LOAD_EXPORT_PREVIEW_FULFILLED',payload}] )
                .catch( error => [
                    {type:'LOAD_EXPORT_PREVIEW_REJECTED',error},
                    act_HandleError(error)
                ])
                .startWith({ type:'LOAD_EXPORT_PREVIEW_PENDING' })
        })
}

export const epc_Export = (action$) => {
    return action$.ofType('EXPORT')
        .mergeMap( action => {
            const { project_id, export_id, base_sync_id, ref_sync_id } = action.payload
            return api.export({params:{project_id,export_id,base_sync_id, ref_sync_id}})
                .mergeMap( payload => [{type:'EXPORT_FULFILLED'}])
                .catch( error => [
                    {type:'EXPORT_REJECTED',error},
                    act_HandleError(error)
                ])
                .startWith({ type:'EXPORT_PENDING', payload:action.payload })
        })
}

export const epc_GetExportBlacklist = (action$) => {
    return action$.ofType('GET_EXPORT_BLACKLIST')
        .mergeMap( action => {
            const { project_id, export_id } = action.payload
            return api.get_export_blacklist({params:{project_id,export_id}})
                .mergeMap( payload => [
                    // {type:'LOAD_EXPORT_PREVIEW', payload:action.payload},
                    {type:'GET_EXPORT_BLACKLIST_FULFILLED', payload}
                ])
                .catch( error => [
                    {type:'GET_EXPORT_BLACKLIST_REJECTED',error},
                    act_HandleError(error)
                ])
                .startWith({ type:'GET_EXPORT_BLACKLIST_PENDING' })
        })
}
export const epc_SetExportBlacklist = (action$) => {
    return action$.ofType('SET_EXPORT_BLACKLIST')
        .mergeMap( action => {
            const { project_id, export_id, blacklist } = action.payload
            return api.set_export_blacklist({params:{project_id,export_id},data:{blacklist}})
                .mergeMap( payload => [
                    // {type:'LOAD_EXPORT_PREVIEW', payload:action.payload},
                    {type:'SET_EXPORT_BLACKLIST_FULFILLED', payload}
                ])
                .catch( error => [
                    {type:'SET_EXPORT_BLACKLIST_REJECTED',error},
                    act_HandleError(error)
                ])
                .startWith({ type:'SET_EXPORT_BLACKLIST_PENDING' })
        })
}
export const epc_SyncProject = (action$) => {
    return action$.ofType('SYNC_PROJECT')
        .mergeMap( action => {
            const { projectId } = action.payload
            return api.sync_project({params:{projectId}})
                .mergeMap( payload => [
                    {type:'SYNC_PROJECT_FULFILLED', payload}
                ])
                .catch( error => [
                    {type:'SYNC_PROJECT_REJECTED',error},
                    act_HandleError(error)
                ])
                .startWith({ type:'SYNC_PROJECT_PENDING' })
        })
}
export const epc_LoadSyncJobs = (action$) => {
    return action$.ofType('LOAD_SYNCJOBS')
        .mergeMap( action => {
            return api.get_sync_jobs()
                .mergeMap( payload => [
                    {type:'LOAD_SYNCJOBS_FULFILLED', payload}
                ])
                .catch( error => [
                    {type:'LOAD_SYNCJOBS_REJECTED',error},
                    act_HandleError(error)
                ])
                .startWith({ type:'LOAD_SYNCJOBS_PENDING' })
        })
}
export const epc_CancelSyncJob = (action$) => {
    return action$.ofType('CANCEL_SYNCJOB')
        .mergeMap( action => {
            const { jobId } = action.payload
            return api.cancel_sync_job({params:{jobId}})
                .mergeMap( payload => [
                    {type:'CANCEL_SYNCJOB_FULFILLED', payload}
                ])
                .catch( error => [
                    {type:'CANCEL_SYNCJOB_REJECTED',error},
                    act_HandleError(error)
                ])
                .startWith({ type:'CANCEL_SYNCJOB_PENDING' })
        })
}
export const epc_ReorderSyncJob = (action$) => {
    return action$.ofType('REORDER_SYNCJOB')
        .mergeMap( action => {
            const { jobId, position } = action.payload
            return api.reorder_sync_job({params:{jobId}, data:{position}})
                .mergeMap( payload => [
                    {type:'REORDER_SYNCJOB_FULFILLED', payload}
                ])
                .catch( error => [
                    {type:'REORDER_SYNCJOB_REJECTED',error},
                    act_HandleError(error)
                ])
                .startWith({ type:'REORDER_SYNCJOB_PENDING' })
        })
}
