"use strict";

// DEPENDENCIES
import React from 'react';

import update from 'immutability-helper';

import { connect } from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';

// LOGIC
import { getaggrstatus } from '../logic'

// ACTIONS
import { 
    act_LoadWorkstation, 
    act_SetWorkloadStatus, act_SetPartStatus, act_SetScrewcaseStatus, act_SetResourceStatus, 
    act_UploadImage, act_DeleteImage,
    act_CreateComment, act_ChangeComment, act_DeleteComment,
    act_ChangePosition,
    act_FilterChange
} from '../actions/workstationActions'

// COMPONENTS
import View from './view'
import { UpdateBadge, StatusBadge } from './status';
import ExpansionPanel from './expansionPanel';
import ItemList from './itemList'
import PartStatusDialog from './partStatusDialog';
import ScrewcaseStatusDialog from './screwcaseStatusDialog';
import ResourceStatusDialog from './resourceStatusDialog';
import ImageViewer from './imageViewer'
import CommentWrapper from './commentWrapper';
import Warning from './warning'

import FlatButton from 'material-ui/FlatButton';
import SelectField from 'material-ui/SelectField';
import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton';
import MenuItem  from 'material-ui/MenuItem';

import InfoIcon from 'material-ui/svg-icons/action/info';
import CommentIcon from 'material-ui/svg-icons/communication/comment';
import ErrorIcon from 'material-ui/svg-icons/alert/error';


// COMPONENT
class Workstation extends React.Component {

    constructor() {
        super();
        this.state = { 
            editPart: null,
            editScrewcase: null,
            editResource: null
        }
    }

    componentWillMount() {
        const workstationId = this.props.match.params.id
        this.init(workstationId)
    }
    componentWillReceiveProps(nextProps) {
        const currWorkstationId = this.props.match.params.id, nextWorkstationId = nextProps.match.params.id
        if(nextWorkstationId != currWorkstationId) this.init(nextWorkstationId )
    }
    init(workstationId) {
        const projectId = this.props.project.id
        this.props.dispatch( act_LoadWorkstation({projectId,workstationId}) )
    }

    handleWorkloadStatusChange(status){
        const projectId = this.props.project.id, workstationId = this.props.match.params.id
        this.props.dispatch( act_SetWorkloadStatus({projectId, workstationId, status}) )
    }

    handleEditPartStatus(id){
        this.setState( update(this.state, {
            editPart: {$set: id},
        }));
    }
    handleCancelEditPartStatus(){
        this.setState( update(this.state, {
            editPart: {$set: null},
        }));
    }
    handleSetPartStatus(partId, status){
        const projectId = this.props.project.id, workstationId = this.props.match.params.id
        this.props.dispatch( act_SetPartStatus({projectId, workstationId, partId, status}) )
        this.setState( update(this.state, {
            editPart: {$set: null},
        }));
    }

    handleEditScrewcaseStatus(id){
        this.setState( update(this.state, {
            editScrewcase: {$set: id},
        }));
    }
    handleCancelEditScrewcaseStatus(){
        this.setState( update(this.state, {
            editScrewcase: {$set: null},
        }));
    }
    handleSaveScrewcaseStatus(screwcaseId, status){
        const projectId = this.props.project.id, workstationId = this.props.match.params.id
        this.props.dispatch( act_SetScrewcaseStatus({projectId, workstationId, screwcaseId, status}) )
        this.setState( update(this.state, {
            editScrewcase: {$set: null},
        }));
    }

    handleEditResourceStatus(id){
        this.setState( update(this.state, {
            editResource: {$set: id},
        }));
    }
    handleCancelEditResourceStatus(){
        this.setState( update(this.state, {
            editResource: {$set: null},
        }));
    }
    handleSaveResourceStatus(resourceId, status){
        const projectId = this.props.project.id, workstationId = this.props.match.params.id
        this.props.dispatch( act_SetResourceStatus({projectId, workstationId, resourceId, status}) )
        this.setState( update(this.state, {
            editResource: {$set: null},
        }));
    }
    handleUploadImage(imageData) {
        const projectId = this.props.project.id, workstationId = this.props.match.params.id
        this.props.dispatch( act_UploadImage({projectId, workstationId, imageData}) )
    }
    handleDeleteImage(imageId) {
        const projectId = this.props.project.id, workstationId = this.props.match.params.id
        this.props.dispatch( act_DeleteImage({projectId, workstationId, imageId}) )
    }
    handleCreateComment(scope) {
        const projectId = this.props.project.id, workstationId = this.props.match.params.id
        this.props.dispatch( act_CreateComment({projectId, workstationId, scope}) )
    }
    handleChangeComment(commentId, text, scope) {
        const projectId = this.props.project.id, workstationId = this.props.match.params.id
        this.props.dispatch( act_ChangeComment({projectId, workstationId, commentId, text, scope}) )
    }
    handleDeleteComment(commentId) {
        const projectId = this.props.project.id, workstationId = this.props.match.params.id
        this.props.dispatch( act_DeleteComment({projectId, workstationId, commentId}) )
    }
    handleChangePosition(pos) {
        const projectId = this.props.project.id, workstationId = this.props.match.params.id
        this.props.dispatch( act_ChangePosition({projectId, workstationId, position: pos}) )
    }
    handleGoBack(){
        let url = this.props.match.url
        url = url.replace(/\/workstations.*$/,'')
        this.props.history.push(url)
    }

    handleFilterChange(topic, filter){
        this.props.dispatch( act_FilterChange({[topic]:filter}) )
    }

    render() {
        const { 
            pending, fulfilled, 
            data, status, workload, operations, parts, screwcases, resources, images, comments,
            filter, project, muiTheme 
        } = this.props
        const palette = muiTheme.palette
        const styles = {
            positionLabel:{ color:palette.textColor, fontWeight:500, fontSize:14, marginRight:10 },
            expansion: { marginBottom:15 },
            expansionBar: { backgroundColor:'rgba(255,255,255,.4', paddingLeft:20, paddingRight:10 },
            expansionPanel: { backgroundColor:'white'},
            panelInfo:{ verticalAlign:'middle', fontSize:12, color:palette.midgrey, marginLeft:10 },
            panelInfoIcon:{ verticalAlign:'middle', fill:palette.midgrey, marginLeft:20 },
            statusItem: { position:'relative', width:'100%', height:45 },
            statusBadge: { position:'absolute', top:0, left:0, width:'100%' },
            statusLabel: { position:'relative', lineHeight:'45px',fontSize:16, fontWeight:'bold', color:'white', textAlign:'center' },
            rowStatusBadge:{ height:23, width:'100%' },
            noteWrapper:{ position:'relative', padding:'20px 10px 0px'},
            note:{ width:'100%', fontSize:14 },
            infoTrigger:{ display:'inline-block', verticalAlign:'middle', height:46, width:46, padding:11 },
            info:{ width:'auto', maxWidth:'400px', padding:'5px 10px' },
        }        
        const getPartsStatusBadge = () => {
            const matArr = parts.map( p => p.superStatus.st_materialbereitstellung )
            const gesArr = parts.map( p => p.superStatus.st_gesamtaussage )
            return <StatusBadge statusA={ getaggrstatus(matArr) } statusB={ getaggrstatus(gesArr) } defaultColor={palette.defaultColor} goodColor={palette.ioColor} badColor={palette.nioColor}/>
        }
        const getScrewcasesStatusBadge = () => {
            const statusArr = screwcases.map( s => s.status )
            return <StatusBadge statusA={ getaggrstatus(statusArr) } defaultColor={palette.defaultColor} goodColor={palette.ioColor} badColor={palette.nioColor}/>
        }
        const getResourcesStatusBadge = () => {
            const statusArr = resources.map( r => r.status )
            return <StatusBadge statusA={ getaggrstatus(statusArr) } defaultColor={palette.defaultColor} goodColor={palette.ioColor} badColor={palette.nioColor}/>
        }
        const panelInfoBadge = (warningsCount, commentsCount) => (
            <React.Fragment>
                {   warningsCount ?
                    <React.Fragment>
                        <ErrorIcon style={styles.panelInfoIcon}/>
                        <span style={styles.panelInfo}>{`${warningsCount} Warnungen`}</span>
                    </React.Fragment> : ''
                }
                {   commentsCount ? 
                    <React.Fragment>
                        <CommentIcon style={styles.panelInfoIcon}/>
                        <span style={styles.panelInfo}>{`${commentsCount} Kommentare`}</span>
                    </React.Fragment>  : ''
                }
            </React.Fragment>     
        )       
        const statusHeader = (info) =>
            <React.Fragment>
                <span>{'Status'}</span>
                <IconMenu
                    iconButtonElement={<IconButton style={ styles.infoTrigger }><InfoIcon color={ palette.darkgrey }/></IconButton>}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    targetOrigin={{horizontal: 'right', vertical: 'top'}}
                    menuStyle={ styles.info }
                >
                    <div>{info}</div>
                </IconMenu> 
            </React.Fragment>

        const partColumns = [
            {title:'Teilenummer', key:'number', style:{width:'20%'}, filter:true},
            {title:'Benennung', key:'name', style:{width:'35%'}, filter:true},
            {title:'Arbeitsvorgang', key:'operations', style:{width:'20%'}, filter:true},
            {title:'BehaelterArt', key:'container_type', style:{width:'10%'}, filter:true},
            {title:'Behaelter', key:'container', style:{width:'10%'}, filter:true},
            {title:'PID', key:'pid', style:{width:'10%'}, filter:true},
            {title:'', key:'warning', style:{width:48, padding:0}, filter:false},
            {title:'Status', key:'status', style:{width:80}, filter:false},
        ] 
        const screwcaseColumns = [
            {title:'Schraubfall', key:'number', style:{width:'7.5%'}, filter:true},
            {title:'Arbeitsvorgang', key:'operations', style:{width:'15%'}, filter:true},
            {title:'Kategorie', key:'category', style:{width:'7.5%'}, filter:true},
            {title:'Drehmoment', key:'torque', type:'number', style:{width:'7.5%'}, filter:true},
            {title:'Schlüsselweite', key:'wrenchsize', style:{width:'7.5%'}, filter:true},
            {title:'Verfahren', key:'method', style:{width:'7.5%'}, filter:true},
            {title:'Bemerkung', key:'annotation', style:{width:'32.5%'}, filter:true},
            {title:'PID', key:'pid', style:{width:'10%'}, filter:true},
            {title:'', key:'warning', style:{width:48, padding:0}, filter:false},
            {title:statusHeader('Richtige Zuordnung verknüpft mit Quellsystem DPE und Ausschluss von Verwechslungsmöglichkeit'), key:'status', style:{width:80}, filter:false},
        ]
        const resourceColumns = [
            {title:'Schluessel', key:'number', style:{width:'10%'}, filter:true},
            {title:'Benennung', key:'name', style:{width:'55%'}, filter:true},
            {title:'Arbeitsvorgang', key:'operations', style:{width:'20%'}, filter:true},
            {title:'PID', key:'pid', style:{width:'10%'}, filter:true},
            {title:'', key:'warning', style:{width:48, padding:0}, filter:false},
            {title:statusHeader('Richtige Zuordnung und Übereinstimmung mit Quellsystem ZBM'), key:'status', style:{width:80}, filter:false},
        ]
        const operationColumns = [
            {title:"AVO",key:"number", style:{ width:"7.5%" },filter:true},
            {title:"Kurztext", key:"name", style:{ width:"auto" }, filter:true},
            {title:"Ver.-Zeit", key:"av_time", type:'number', style:{ width:"7.5%" }, filter:true},
            {title:"gew. Ver.-Zeit", key:"g_time", type:'number', style:{ width:"7.5%" }, filter:true},
            {title:"PID", key:"pid", style: { width:"10%" }, filter:true },
            {title:"Klassifikation", key:"classification", style:{ width:"7.5%" }, filter:true }
        ];
        const partUpdates = Array.prototype.concat(...parts.map(p=>p.changelog.map(c=>({ id:p.data.id, ...c }))))
        const screwcaseUpdates = Array.prototype.concat(...screwcases.map(s=>s.changelog.map(c=>({ id:s.data.id, ...c }))))
        const resourceUpdates = Array.prototype.concat(...resources.map(r=>r.changelog.map(c=>({ id:r.data.id, ...c }))))

        return (
            <View
                pending={pending}
                fulfilled={fulfilled}
                title={ `${data.number} - ${data.name}` } 
                rightActions={[
                    <React.Fragment key={'change_position'}>
                        <span style={styles.positionLabel}>Position:</span>
                        <SelectField
                            key={'position'}
                            hintText="Position im Takt"
                            value={ data.position }
                            onChange={ (e,k,v) => this.handleChangePosition(v) }
                        >
                        {   [{label:'Vorne',value:'front'}, {label:'Links', value:'left'}, {label:'Rechts', value:'right'}, {label:'Hinten', value:'back'}].map( p => 
                            <MenuItem key={p.value} value={p.value} primaryText={p.label}/>
                        )}
                        </SelectField>
                    </React.Fragment>
                ]}
                onNavigateBack={this.handleGoBack.bind(this)}
                NavigateBackTooltip={ 'Zum Takt'}
            >
            {   fulfilled && <div>
                <ExpansionPanel 
                    open={true} 
                    title={'Status'} 
                    style={ styles.expansion } 
                    barStyle={ styles.expansionBar } 
                    panelStyle={ styles.expansionPanel }
                    fullBarTrigger
                >
                    <div style={ {...styles.statusItem, marginBottom:1} }>
                        <StatusBadge style={ styles.statusBadge } statusA={ status.st_gesamtaussage } defaultColor={palette.defaultColor} goodColor={palette.ioColor} badColor={palette.nioColor}/>
                        <div style={ styles.statusLabel }>{'Gesamtaussage Arbeitsplatzgüte'}</div>
                    </div>
                    <div style={ styles.statusItem }>
                        <StatusBadge style={ styles.statusBadge } statusA={ status.st_materialbereitstellung } defaultColor={palette.defaultColor} goodColor={palette.ioColor} badColor={palette.nioColor}/>
                        <div style={ styles.statusLabel }>{'Materialbereitstellung'}</div>
                    </div>
                </ExpansionPanel>

                <ExpansionPanel 
                    open={false} 
                    title={'Auslastung'} 
                    style={ styles.expansion }
                    barStyle={ styles.expansionBar } 
                    panelStyle={ {...styles.expansionPanel, padding:10} } 
                    leftFlag={ <UpdateBadge update={ workload.changelog.length>0 } color={palette.updateColor}/> }
                    rightFlag={ <StatusBadge statusA={ workload.status } defaultColor={palette.defaultColor} goodColor={palette.ioColor} badColor={palette.nioColor}/> }
                    fullBarTrigger
                >
                    <WorkloadItem 
                        target={{min:project.min_workload, max:project.max_workload}}
                        workload={workload}
                        onChange={ this.handleWorkloadStatusChange.bind(this) }
                        palette={ palette }
                    />
                    <CommentWrapper
                        comments={ comments.filter(c=>c.scope=='workload') }
                        onCreate={ () => this.handleCreateComment('workload') }
                        onChange={ this.handleChangeComment.bind(this) }
                        onDelete={ this.handleDeleteComment.bind(this) }
                    />
                </ExpansionPanel>
                
                <ExpansionPanel 
                    title={<React.Fragment>
                        {`Einbauteile (${parts.length})`}
                        {panelInfoBadge(
                            parts.filter(p=>p.warning).length,
                            comments.filter(c=>c.scope=='parts').length
                        )}
                    </React.Fragment>} 
                    style={ styles.expansion }
                    barStyle={ styles.expansionBar } 
                    panelStyle={ {...styles.expansionPanel, padding:10} }  
                    leftFlag={ <UpdateBadge update={ parts.reduce((p,c)=>p+c.changelog.length,0) > 0 } color={palette.updateColor}/> }
                    rightFlag={ getPartsStatusBadge() }
                    fullBarTrigger
                >   
                    <ItemList 
                        columns={ partColumns } 
                        rows={ parts.map( p => ({
                            ...p.data,
                            warning: p.warning ? <Warning type={'part'} code={p.warning}/> : '',
                            status: <StatusBadge 
                                style={ styles.rowStatusBadge } 
                                statusA={ p.superStatus.st_materialbereitstellung } statusB={ p.superStatus.st_gesamtaussage } 
                                defaultColor={palette.defaultColor} goodColor={palette.ioColor} badColor={palette.nioColor}
                                onClick={ ()=>this.handleEditPartStatus(p.data.id) }
                            />
                        }) )}
                        updates={ partUpdates } 
                        rowHeight={ 48 }
                        identifier={'id'}
                        filter={ filter && filter.part }
                        onFilterChange={ (filter) => this.handleFilterChange('part',filter) }
                    />
                    <CommentWrapper
                        comments={ comments.filter(c=>c.scope=='parts') }
                        onCreate={ () => this.handleCreateComment('parts') }
                        onChange={ this.handleChangeComment.bind(this) }
                        onDelete={ this.handleDeleteComment.bind(this) }
                    />
                </ExpansionPanel>
                <ExpansionPanel 
                    title={<React.Fragment>
                        {`Schraubfälle (${screwcases.length})`}
                        {panelInfoBadge(
                            screwcases.filter(s=>s.warning).length,
                            comments.filter(c=>c.scope=='screwcases').length
                        )}
                    </React.Fragment>} 
                    style={ styles.expansion }
                    barStyle={ styles.expansionBar } 
                    panelStyle={ {...styles.expansionPanel, padding:10} } 
                    leftFlag={ <UpdateBadge update={ screwcases.reduce((p,c)=>p+c.changelog.length,0) > 0 } color={palette.updateColor}/> }
                    rightFlag={ getScrewcasesStatusBadge() }
                    fullBarTrigger
                >
                    <ItemList 
                        columns={ screwcaseColumns } 
                        rows={ screwcases.map( s => ({
                            ...s.data,
                            warning: s.warning ? <Warning type={'screwcase'} code={s.warning}/> : '',
                            status: <StatusBadge 
                                style={ styles.rowStatusBadge } 
                                statusA={ s.status }  
                                info={ 'Richtige Zuordnung verknüpft mit Quellsystem DPE und Ausschluss von Verwechslungsmöglichkeit'}
                                defaultColor={palette.defaultColor} goodColor={palette.ioColor} badColor={palette.nioColor}
                                onClick={ ()=>this.handleEditScrewcaseStatus(s.data.id) }
                            />
                        }) )}
                        updates={ screwcaseUpdates } 
                        rowHeight={ 48 }
                        identifier={'id'}
                        filter={ filter && filter.screwcase }
                        onFilterChange={ (filter) => this.handleFilterChange('screwcase',filter) }
                    />
                    <CommentWrapper
                        comments={ comments.filter(c=>c.scope=='screwcases') }
                        onCreate={ () => this.handleCreateComment('screwcases') }
                        onChange={ this.handleChangeComment.bind(this) }
                        onDelete={ this.handleDeleteComment.bind(this) }
                    />
                </ExpansionPanel>

                <ExpansionPanel 
                    title={<React.Fragment>
                        {`Betriebsmittel (${resources.length})`}
                        {panelInfoBadge(
                            resources.filter(r=>r.warning).length,
                            comments.filter(c=>c.scope=='resources').length
                        )}
                    </React.Fragment>} 
                    style={ styles.expansion }
                    barStyle={ styles.expansionBar } 
                    panelStyle={ {...styles.expansionPanel, padding:10} } 
                    leftFlag={ <UpdateBadge update={ resources.reduce((p,c)=>p+c.changelog.length,0) > 0 } color={palette.updateColor}/> }
                    rightFlag={ getResourcesStatusBadge() }
                    fullBarTrigger
                >
                    <ItemList 
                        columns={ resourceColumns } 
                        rows={ resources.map( r => ({
                            ...r.data,
                            warning: r.warning ? <Warning type={'resource'} code={r.warning}/> : '',
                            status: <StatusBadge 
                                style={ styles.rowStatusBadge } 
                                statusA={ r.status }  
                                info={ 'Richtige Zuordnung und Übereinstimmung mit Quellsystem ZBM'}
                                defaultColor={palette.defaultColor} goodColor={palette.ioColor} badColor={palette.nioColor}
                                onClick={ ()=>this.handleEditResourceStatus(r.data.id) }
                            />
                        }) )}
                        updates={ resourceUpdates } 
                        rowHeight={ 48 }
                        identifier={'id'}
                        filter={ filter && filter.resource }
                        onFilterChange={ (filter) => this.handleFilterChange('resource',filter) }
                    />
                    <CommentWrapper
                        comments={ comments.filter(c=>c.scope=='resources') }
                        onCreate={ () => this.handleCreateComment('resources') }
                        onChange={ this.handleChangeComment.bind(this) }
                        onDelete={ this.handleDeleteComment.bind(this) }
                    />
                </ExpansionPanel>

                <ExpansionPanel 
                    title={`Arbeitsvorgänge (${operations.length})`} 
                    style={ styles.expansion }
                    barStyle={ styles.expansionBar } 
                    panelStyle={ {...styles.expansionPanel, padding:10} } 
                    fullBarTrigger
                >
                    <ItemList 
                        columns={ operationColumns } 
                        rows={ operations.map(o=>o.data) }
                        rowHeight={ 48 }
                        identifier={'id'}
                        filter={ filter && filter.operation }
                        onFilterChange={ (filter) => this.handleFilterChange('operation',filter) }
                    />
                </ExpansionPanel>

                <ExpansionPanel 
                    title={`Layout`} 
                    style={ styles.expansion }
                    barStyle={ styles.expansionBar } 
                    panelStyle={ {...styles.expansionPanel, padding:10} } 
                    fullBarTrigger
                >
                    <ImageViewer
                        list={ images || [] }
                        onUpload={ this.handleUploadImage.bind(this) }
                        onDelete={ this.handleDeleteImage.bind(this) }
                        editable={ true }
                    />
                </ExpansionPanel>
                

                <PartStatusDialog 
                    open={ this.state.editPart != null }
                    part={ parts.find( p=>p.data.id==this.state.editPart ) || {} }
                    onCancel={ this.handleCancelEditPartStatus.bind(this) }
                    onSave={ this.handleSetPartStatus.bind(this) }
                /> 
                <ScrewcaseStatusDialog 
                    open={ this.state.editScrewcase != null }
                    screwcase={ screwcases.find( s=>s.data.id==this.state.editScrewcase) || {} }
                    onCancel={ this.handleCancelEditScrewcaseStatus.bind(this) }
                    onSave={ this.handleSaveScrewcaseStatus.bind(this) }
                /> 
                <ResourceStatusDialog 
                    open={ this.state.editResource != null }
                    resource={ resources.find( r=>r.data.id==this.state.editResource ) || {} }
                    onCancel={ this.handleCancelEditResourceStatus.bind(this) }
                    onSave={ this.handleSaveResourceStatus.bind(this) }
                />
            </div>}
            </View>
        )
    }
};

class WorkloadItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            classificationVisible: true
        }
    }
    handleToggleClassificationVisibility () {
        this.setState( update(this.state, { 
            classificationVisible: {$apply: (prev) => !prev},
        }));
    }
    handleChange (value) {
        if(value==this.props.workload.status) value=-1;
        this.props.onChange(value)
    }
    render() {
        const { target, workload, palette } = this.props
        const { value, value_min, value_max, per_model, per_category, status } = workload
        const styleSets = { 
            inline: { display:'inline-block', verticalAlign:'middle' }
        }
        const styles = {
            item:{ display:'flex', justifyContent:'space-between', alignItems:'flex-start'},
            tracks:{ position:'relative', flexGrow:1 },
            targetWrapper: { position: 'absolute', top:0, bottom:0, left:0, right:0, marginLeft:90, marginRight:60, pointerEvents:'none'},
            target: { position:'absolute', top:0, bottom:0, left:target.min*100+'%', right:(1-target.max)*100+'%', backgroundColor:palette.ioColor+'50', transition:'300ms'},
            models:{ position:'relative' },
            model:{ display:'flex', alignItems:'center', padding:'30px 10px' },
            label:{ textAlign:'left', width:80, flexShrink:0, margin:0 },
            track:{ position:'relative', width:'100%', padding:'5px 0px', marginRight:50, backgroundColor:'#e5e5e5'},
            handle: { position:'absolute', top:0, bottom:0, width:3 },
            avgLabel: { position:'absolute', bottom:'calc(100% + 2px)', left:-50, right:-50, fontSize:14, fontWeight:'bold', textAlign:'center', whiteSpace:'nowrap' },
            minmaxLabel: { position:'absolute', top:'calc(100% + 2px)', fontSize:12, fontWeight:'bold', whiteSpace:'nowrap', margin:'0px 2px' },
            classifications:{ position:'relative' },
            classification:{ position:'relative', height:this.state.classificationVisible ? 20 : 0, transition:'300ms', overflow:'hidden' },
            classificationTrack: { height:2, backgroundColor: palette.textColor},
            classificationLabel: { margin:'2px 5px', fontSize:12, color:palette.textColor, textAlign:'left', whiteSpace:'nowrap' },
            status:{ padding:'18px 0px'},
            statusBtn:{ 
                height:45, width:65, minWidth:65,
                marginLeft:3,
                color:'white', fontSize:14, 
                textAlign:'center', 
                backgroundColor:palette.defaultColor,
                borderRadius:0
            },
            statusLabel: { textTransform:'none', fontWeight:'normal' },
            nio:{ backgroundColor:palette.nioColor },
            io:{ backgroundColor:palette.ioColor },
            infoTrigger:{ ...styleSets.inline, height:46, width:46, padding:11 },
            info:{ width:'auto', maxWidth:'400px', padding:'5px 10px' },
        }
        const getBtnStyle = (status) => {
            const val = this.props.workload.status;
            const baseStyle = styles.statusBtn;
            let stateStyle = {};
            switch (status) {
                case 0: {if(val==0){ stateStyle = styles.nio }; break }
                case 1: {if(val==1){ stateStyle = styles.io }; break }
            }
            return { ...baseStyle, ...stateStyle}
        }
        const getPosition = (value) => ({
            left:`calc(${ Math.min(value*100,102) }% - 1px)`
        })
        const getColor = (value) => {
            const color = (target.min <= value && value <= target.max) ? '#202020' : palette.nioColor
            return { backgroundColor:color, color }
        }
        return (
            <div style={ styles.item }>
                <div style={ styles.tracks}>
                    <FlatButton  label={`Klassifikation ${this.state.classificationVisible ? 'ausblenden' : 'einblenden'}`} onClick={this.handleToggleClassificationVisibility.bind(this)}/>
                    <IconMenu
                        iconButtonElement={<IconButton style={ styles.infoTrigger }><InfoIcon color={ palette.darkgrey }/></IconButton>}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        targetOrigin={{horizontal: 'right', vertical: 'top'}}
                        menuStyle={ styles.info }
                    >
                    {   per_category.map(cat=>
                        <div key={cat.key}>{`${cat.label}: ${cat.classifications.join(',')}`}</div>
                    )}
                    </IconMenu> 
                    <div style={styles.models}>
                        <div style={ styles.targetWrapper }>
                            <div style={ styles.target }/>
                        </div>
                        {   [{model:'Alle',min:value_min, avg: value, max:value_max, per_category}].concat(per_model).map( m=> {
                            const categories = m.per_category.filter(cat=>cat.avg>0)
                            return (
                                <div key={m.model} style={styles.model}>
                                    <h4 style={styles.label}>{m.model}</h4>
                                    <div style={ styles.track }>
                                        { categories.length > 0 &&
                                            <div style={{...styles.classifications, width:m.avg*100+'%'}}>
                                            {   categories.map((cat,index)=>
                                                <div key={`${m.model}_${cat.key}`} style={ {...styles.classification, left:(index ? categories.slice(0,index).reduce((a,b)=>a+b.avg,0) : 0)*100+'%'}}>
                                                    <div style={ styles.classificationLabel }>
                                                        { parseFloat(cat.avg*100).toLocaleString('de-De',{maximumFractionDigits:2})+'%'+ ' ' + cat.label }
                                                    </div>
                                                    <div style={ {...styles.classificationTrack, width:cat.avg*100+'%'} }/>
                                                </div>
                                            )}
                                            </div>
                                        }
                                        <div style={ {...styles.handle, ...getPosition(m.avg), ...getColor(m.avg)} }>
                                            <div style={styles.avgLabel}>{ 'Ø ' + parseFloat(m.avg*100).toLocaleString('de-De',{maximumFractionDigits:2}) +'%' }</div>
                                        </div>
                                        <div style={ {...styles.handle, ...getPosition(m.min), ...getColor(m.min)} }>
                                            <div style={{...styles.minmaxLabel, left:'auto', right:0}}>{'min ' + parseFloat(m.min*100).toLocaleString('de-De',{maximumFractionDigits:2}) +'%'}</div>
                                        </div>
                                        <div style={ {...styles.handle, ...getPosition(m.max), ...getColor(m.max)} }>
                                            <div style={{...styles.minmaxLabel, left:0, right:'auto'}}>{ 'max ' + parseFloat(m.max*100).toLocaleString('de-De',{maximumFractionDigits:2}) +'%' }</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div style={ styles.status}>
                    <IconMenu
                        iconButtonElement={<IconButton style={ styles.infoTrigger }><InfoIcon color={ palette.darkgrey }/></IconButton>}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        targetOrigin={{horizontal: 'right', vertical: 'top'}}
                        menuStyle={ styles.info }
                    >
                        <div>{'Auslastung innerhalb der definierten Range. Kein Bandstop aufgrund Zeitspreizung'}</div>
                    </IconMenu> 
                    <FlatButton name={0} label={'n.i.O.'} style={ getBtnStyle(0) } labelStyle={ styles.statusLabel } onClick={ () => this.handleChange(0) }/>
                    <FlatButton name={1} label={'i.O.'} style={ getBtnStyle(1) } labelStyle={ styles.statusLabel } onClick={ () => this.handleChange(1) }/>
                </div>
            </div>
        )
    }
} 

// EXPORT
export default connect(store => ({
    pending: store.workstation.pending,
    fulfilled: store.workstation.fulfilled,

    data: store.workstation.data,
    status: store.workstation.status,
    workload: store.workstation.workload,
    operations: store.workstation.operations,
    parts: store.workstation.parts,
    screwcases: store.workstation.screwcases,
    resources: store.workstation.resources,
    images: store.workstation.images,
    comments: store.workstation.comments,

    filter: store.workstation.filter,

    project: store.project.current.data
}))(
    muiThemeable()(Workstation)
);