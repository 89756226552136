"use strict";

// DEPENDENCIES from 
import { Observable } from 'rxjs'
import api from '../api';

// ACTIONS
import { act_HandleError} from '../actions/appActions';
import { act_ToggleTreeItem } from '../actions/planActions'


// -----------------------------------------------------
// EPICS
export const epc_LoadSection = (action$, store) => {
    return action$.ofType('LOAD_SECTION')
        .mergeMap( action => {
            const { projectId, sectionId } = action.payload
            const section = store.getState().plan.current.sections.find(s=>s.id==sectionId)
            return [
                { type:'LOAD_SECTION_FULFILLED', payload: section },
                act_ToggleTreeItem({id:sectionId,type:'section',open:true}) 
            ]
        })
        
}
       