
export const act_InitApp = () => ({ type:'INIT_APP' })

export const act_LoadConfiguration = (payload) => ({type:'LOAD_CONFIGURATION',payload})
    
export const act_HandleError = (payload) => ({ type:'HANDLE_ERROR', payload })

export const act_PushError = (payload) => ({ type:'PUSH_ERROR', payload })

export const act_PushSnack = (payload) => ({ type:'PUSH_SNACK', payload })

export const act_HideSnack = () => ({ type:'HIDE_SNACK' })

export const act_ZoomIn = (payload) => ({type:'ZOOM_IN',payload})

export const act_ZoomOut = (payload) => ({type:'ZOOM_OUT',payload})

export const act_ChangeMapStatus = (payload) => ({type:'CHANGE_MAP_STATUS',payload})

export const act_ChangeSearchTopic = (payload) => ({type:'CHANGE_SEARCH_TOPIC',payload})

