
export const act_LoadCycle = (payload) => ({ type:'LOAD_CYCLE', payload })

export const act_CreateHeight = (payload) => ({ type:'CREATE_HEIGHT', payload })

export const act_UpdateHeight = (payload) => ({ type:'UPDATE_HEIGHT', payload })

export const act_DeleteHeight = (payload) => ({ type:'DELETE_HEIGHT', payload })

export const act_EditHeight = (payload) => ({ type:'EDIT_HEIGHT', payload })

export const act_CancelEditHeight = (payload) => ({ type:'CANCEL_EDIT_HEIGHT', payload })

export const act_SetHeightConnectTransfer = (payload) => ({ type:'SET_HEIGHT_CONNECT_TRANSFER', payload })


