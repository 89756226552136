
export const act_LoadProjects = (payload) => ({ type:'LOAD_PROJECTS', payload })

export const act_CreateProject = (payload) => ({ type:'CREATE_PROJECT', payload })

export const act_LoadProject = (payload) => ({ type:'LOAD_PROJECT', payload })

export const act_SaveProject = (payload) => ({ type:'SAVE_PROJECT', payload })

export const act_DeleteProject = (payload) => ({ type:'DELETE_PROJECT', payload })

export const act_ChangeProjectPosition = (payload) => ({ type:'CHANGE_PROJECT_POSITION', payload })

export const act_LoadPrograms = (payload) => ({ type:'LOAD_PROGRAMS', payload })

export const act_LoadFilter = (payload) => ({ type:'LOAD_FILTER', payload })

export const act_ResetAll = (payload) => ({ type:'RESET_ALL', payload })

export const act_ResetStatus = (payload) => ({ type:'RESET_STATUS', payload })

export const act_Export = (payload) => ({ type:'EXPORT', payload })

export const act_LoadExportPreview = (payload) => ({ type:'LOAD_EXPORT_PREVIEW', payload })

export const act_GetExportBlacklist = (payload) => ({ type:'GET_EXPORT_BLACKLIST', payload })

export const act_SetExportBlacklist = (payload) => ({ type:'SET_EXPORT_BLACKLIST', payload })

export const act_LoadSyncJobs = (payload) => ({ type:'LOAD_SYNCJOBS', payload })

export const act_SyncProject = (payload) => ({ type:'SYNC_PROJECT', payload })

export const act_ReorderSyncJob = (payload) => ({ type:'REORDER_SYNCJOB', payload })

export const act_CancelSyncJob = (payload) => ({ type:'CANCEL_SYNCJOB', payload })

export const act_UpdateSyncJobs = (payload) => ({ type:'UPDATE_SYNCJOBS', payload })



