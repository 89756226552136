"use strict";

// DEPENDENCIES
import React from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';

// LOGIC
import { getaggrstatus } from '../logic'

import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import Menu from 'material-ui/Menu';
import MenuItem  from 'material-ui/MenuItem';

// ICONS
import DirectionIcon from 'material-ui/svg-icons/content/forward';
import PositionIcon from 'material-ui/svg-icons/content/select-all';

// COMPONENT
class CycleItem extends React.Component {

    constructor() {
        super();
    }

    componentWillMount() {
       
    }
    componentWillReceiveProps(nextProps) {

    }

    handleSelectCycle() {
        const { onSelectCycle } = this.props
        if(onSelectCycle) onSelectCycle(this.props.cycle.id)
    }
    handleSelectWorkstation(e,id) {
        e.stopPropagation()
        if(!id) return
        this.props.onSelectWorkstation(id)
    }
    handleChangePosition(e, wId, pos) {
        e.stopPropagation()
        this.props.onPositionChange(wId, pos)
    }

    render() {
        const { min, cycle, mapStatus, workstations, style, notifications, muiTheme } = this.props
        
        const noPosWp = workstations.filter(w=>!w.position)
        
        const palette = muiTheme.palette
        const np = (min) ? 120 : 360,
              w = (min) ? 120 : 'calc(100% - 400px)', 
              h = (min) ? 100 : 300, 
              wW = (min) ? 20 : 60,
              fS = (min) ? 12 : 14
            //   wM = (min) ? 'vertical-rl' : 'normal', tO = (min) ? 'upright' : 'normal'

        const styles = {
            cycle:{ 
                position:'relative',
                display:'inline-block', verticalAlign:'middle',
                height:h, width:w, lineHeight: h + 'px',
                margin:(min) ? '25px 10px 25px 0px' : '25px 0px',
                backgroundColor:'white',
                cursor:'pointer',
                transition:'opacity 400ms',
                ...style
            },
            cycleLabel:{ 
                position:'absolute', top:0, bottom:0, left:0, right:0, lineHeight:h+'px',
                textAlign:'center', color:palette.textColor, fontWeight:500, fontSize:14, 
            },
            directionIcon:{ position:'absolute', top:0, bottom:0, left:5, right:0, margin:'auto', height:'65%', width:'65%', fill:palette.lightgrey},
            workstationWrapper:{ position:'absolute', display:'flex' },
            front:{ right:0, top:wW, bottom:wW, width: workstations.filter(w=>w.position=='front').length*wW, },
            back:{ left:0, top:wW, bottom:wW, width:workstations.filter(w=>w.position=='back').length*wW, }, 
            left:{ top:0, left:0, right:0, height:wW }, 
            right:{ bottom:0, left:0, right:0, height:wW },
            workstation:{ 
                position:'relative', 
                flexGrow:1, margin:1,
                textAlign:'center', 
                cursor:'pointer',
                transition:'opacity 400ms'
            },
            workstationLabel:{ 
                position:'absolute', top:0, left:(min)?'5%':'20px', right:(min)?'5%':48, overflow:'hidden', textOverflow:'ellipsis',
                fontSize:fS, fontWeight:500, lineHeight:wW+'px', whiteSpace:'nowrap',
                pointerEvents:'none',
            },
            workstationLabelRotated:{
                position:'absolute', top:min?'50%':'45%', left:'50%', width:.9*(h-2*wW)-(min?0:45), overflow:'hidden', textOverflow:'ellipsis',
                fontSize:fS, fontWeight:500, lineHeight:'normal', whiteSpace:'nowrap',
                transform:'translateX(-50%) translateY(-50%) rotate(90deg)',
                pointerEvents:'none'
            },
            posBtnH:{ position:'absolute', top:0, bottom:0, right:0, height:48, margin:'auto', lineHeight:'normal' },
            posBtnV:{ position:'absolute', bottom:0, right:0, left:0, width:48, margin:'auto', lineHeight:'normal' },
            noposWrapper:{ position:'absolute', width:np, left:'100%', top:0, marginLeft:40 },
            noposLabel:{ lineHeight:'40px', fontSize:fS, color:palette.textColor, fontWeight:500, textAlign:'left' },
            noposMin:{
                position:'absolute', top:'100%', left:0, right:0, lineHeight:'30px',
                fontSize:fS, fontWeight:500, color:palette.textColor,  textAlign:'center'
            },
            updateFlagV:{
                position:'absolute', top:0, bottom:0, left:0, width:min?5:10, 
                borderRight:'white solid 1px', backgroundColor:palette.updateColor
            },
            updateFlagH:{
                position:'absolute', top:0, right:0, left:0, height:min?5:10, 
                borderBottom:'white solid 1px', backgroundColor:palette.updateColor
            }
        }
        const getStatus = (w) => {
            let status
            switch (mapStatus) {
                case 'gesamtaussage': {status = w.status.st_gesamtaussage; break}
                case 'materialbereitstellung': {status = w.status.st_materialbereitstellung; break}
                case 'workload': {status = w.status.st_workload; break}
                case 'parts': {status = w.status.st_parts; break}
                case 'screwcases': {status = w.status.st_screwcases; break}
                case 'resources': {status = w.status.st_resources; break}
            }
            return status
            return -1
        }
        const getStatusStyle = (status) => {
            switch (status) {
                case 0: return {backgroundColor: palette.nioColor, color:'white'} 
                case 1: return {backgroundColor: palette.ioColor, color:palette.textColor}
                case -1: return {backgroundColor: palette.defaultColor, color:palette.textColor}
            }
        }
        const getWPStatusStyle = (w) => {
            return getStatusStyle( getStatus(w) )
        }
        const getNoposMinColor = () => {
            let color
            switch (getaggrstatus( noPosWp.map(w => getStatus(w) ) )) {
                case 0: {color = palette.nioColor; break}
                case 1: {color = palette.ioColor; break}
                case -1: {color = palette.midgrey; break}
            }
            return color
        } 

        const getWorkstationLabel = (w) => (min) ? w.number : `${w.number} - ${w.name}`
        const getWorkstationLabelStyle = (pos) => 
            (pos=='front' || pos=='back') 
            ? styles.workstationLabelRotated
            : styles.workstationLabel
        const getPosBtnStyle = (pos) => 
            (pos=='front' || pos=='back') 
            ? styles.posBtnV
            : styles.posBtnH
        const getUpdateFlag = (wId,pos) => {
            const n = notifications.filter(n=> n.workstation_id==wId)
            if(n.length) return <div style={(pos=='front' || pos=='back')?styles.updateFlagH:styles.updateFlagV}></div>
            else return null
        } 
        return (
            <div style={ styles.cycle } onClick={ this.handleSelectCycle.bind(this) }>
                <DirectionIcon style={ styles.directionIcon }/>
                <div style={ styles.cycleLabel }>{ cycle.number }</div>

                {
                    ['left', 'right', 'front', 'back'].map( pos =>
                        <div key={pos} style={ {...styles.workstationWrapper, ...styles[pos]} }>
                        {   workstations.filter(w=>w.position==pos).map( w => {
                            const id = w.id
                            return <div key={id} style={ {...styles.workstation, opacity:w.visible?1:.3, ...getWPStatusStyle(w) } } onClick={ (e) => this.handleSelectWorkstation(e,id) }>
                                { getUpdateFlag(id,pos) }
                                <div style={ getWorkstationLabelStyle(pos) }>{ getWorkstationLabel(w) }</div>
                                {   !min &&
                                    <IconMenu
                                        style={ getPosBtnStyle(pos) }
                                        iconButtonElement={
                                            <IconButton onClick={ (e) => e.stopPropagation() }><PositionIcon color={getWPStatusStyle(w).color}/></IconButton>
                                        }
                                        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                                        targetOrigin={{horizontal: 'left', vertical: 'top'}}
                                    >
                                    {   [{label:'Vorne',value:'front'}, {label:'Links', value:'left'}, {label:'Rechts', value:'right'}, {label:'Hinten', value:'back'}].map( p => 
                                        <MenuItem key={p.value} value={p.value} primaryText={p.label} onClick={ (e) => this.handleChangePosition(e, w.id, p.value) }/>
                                    )}
                                    </IconMenu>
                                }
                            </div>
                        })}
                        </div>
                    )
                }
                
                {   min && noPosWp.length>0 &&
                    <div style={ {...styles.noposMin, color:getNoposMinColor()} }>{ `+ ${noPosWp.length} AP ungetauft` }</div>
                }
                
                {   !min && noPosWp.length>0 &&
                    <div style={ styles.noposWrapper }>
                        <div style={ styles.noposLabel }>{ 'ungetaufte Arbeitsplätze:' }</div>
                    {   noPosWp.map( w => {
                        const id = w.id
                        return <div key={id} style={ {...styles.workstation, opacity:w.visible?1:.3, height:wW, ...getWPStatusStyle(w) } } onClick={ (e) => this.handleSelectWorkstation(e,id) }>
                            <div style={ styles.workstationLabel }>{ getWorkstationLabel(w) }</div>

                            <IconMenu
                                style={ getPosBtnStyle('nopos') }
                                iconButtonElement={
                                    <IconButton onClick={ (e) => e.stopPropagation() }><PositionIcon/></IconButton>
                                }
                                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                                targetOrigin={{horizontal: 'left', vertical: 'top'}}
                            >
                            {   [{label:'Vorne',value:'front'}, {label:'Links', value:'left'}, {label:'Rechts', value:'right'}, {label:'Hinten', value:'back'}].map( p => 
                                <MenuItem key={p.value} value={p.value} primaryText={p.label} onClick={ (e) => this.handleChangePosition(e, w.id, p.value) }/>
                            )}
                            </IconMenu>

                        </div>
                    })}
                    </div>
                }

            </div>
        )
    }
};

// EXPORT
export default muiThemeable()(CycleItem)
