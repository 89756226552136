// DEPENDENCIES
import React from 'react';

// MuiTheme
import muiThemeable from 'material-ui/styles/muiThemeable';

// COMPONENTS
import FlatButton from 'material-ui/FlatButton';
import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton';
import MenuItem from 'material-ui/MenuItem';

import InfoIcon from 'material-ui/svg-icons/action/info';
import ArrowIcon from 'material-ui/svg-icons/action/trending-flat';


// COMPONENT
export const UpdateBadge = (props) => { 
    const styles = {
        badge:{ 
            display:'inline-block', 
            verticalAlign:'middle', 
            height:'100%', width:8,
            ... props.style
        },
        updated:{ backgroundColor:props.color },
    }
    const getStyle = (status) => {
        switch (props.update) {
            case false: { return styles.badge };
            case true: { return {...styles.badge, ...styles.updated } };
            default: { return styles.badge };
        }
    }
    return (
        <div style={ getStyle() }></div>
    )
};

export const UpdateItem = (props) => { 
    const styleSets = {
        inline: { display:'inline-block', verticalAlign:'middle'}
    }
    const styles = {
        item:{ 
            position:'relative',
            display:'flex', justifyContent:'flex-start', alignItems:'center',
            minWidth:500, height:46,
            margin:'2px 0px',
            backgroundColor:'#e5e5e5',
            fontSize:14,
            textAlign:'left'
        },
        badge:{ 
            display:'inline-block', 
            verticalAlign:'middle', 
            height:'100%', width:8,
            backgroundColor:props.color
        },
        label: {
            flexGrow:1,
            marginLeft:22, marginRight:30,
            color:'#4A4A4A'
        },
        rightBox:{ 
            textAlign:'right'
        },
        value: {
            ...styleSets.inline,
            minWidth:95, maxWidth:150, 
            height:'100%', lineHeight:'46px', 
            padding:'0px 10px',
            overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap',
            textAlign:'center',
        },
        prev:{
            backgroundColor:'rgba(0,0,0,.1)',
            color:'grey'
        },
        arrow:{
            ...styleSets.inline,
            width:60,
            color:'#4A4A4A', textAlign:'center'
        },
        curr:{
            backgroundColor:props.color,
            color:'white'
        }
    }
    return (
        <div style={ styles.item }>
            <div style={ styles.badge }/>
            <span style={ styles.label }>{props.name}</span>  
            <div style={ styles.rightBox }>
                <div style={ {...styles.value, ...styles.prev} }>{props.prev}</div>
                <div style={ styles.arrow }>
                    <ArrowIcon color={ styles.arrow.color }/>
                </div>
                <div style={ {...styles.value, ...styles.curr} }>{props.curr}</div>
            </div>
        </div>
    )
};

export const StatusBadge = (props) => {
    const isClickable = (
        props.onClick &&
        (props.statusA !==-2 && props.statusB !==-2)
    )
    const styles = {
        badge:{ 
            display:'inline-flex', 
            width:16, height:'100%',
            verticalAlign:'middle', 
            whiteSpace:'nowrap', fontSize:0, 
            cursor:isClickable ? 'pointer' : 'auto',
            ...props.style,
        },
        statusItem: {
            flexGrow:1, height:'100%'
        },
        statusDefault: { backgroundColor:props.defaultColor },
        statusRed: { backgroundColor:props.badColor },
        statusGreen: { backgroundColor:props.goodColor },
        statusRedBlocked: { 
            background:`repeating-linear-gradient(45deg, ${props.badColor}, ${props.badColor} 10px, #ad1e52 10px, #ad1e52 20px)`
        }
    }
    const getStyle = (status) => {
        switch (props[status]) {
            case undefined: { return {display:'none'} };
            case -2: { return {...styles.statusItem, ...styles.statusRedBlocked } };
            case -1: { return {...styles.statusItem, ...styles.statusDefault } };
            case 0: { return {...styles.statusItem, ...styles.statusRed } };
            case 1: { return {...styles.statusItem, ...styles.statusGreen } };
            default: { return {...styles.statusItem, ...styles.statusDefault } };
        }
    }

    const onClickHandler = () => {
        if(isClickable) props.onClick()
    }
    return (
        <div style={ styles.badge } onClick={ onClickHandler }>
            <div style={ getStyle('statusA') }></div>
            <div style={ getStyle('statusB') }></div>
        </div>
    )
};

export class StatusItem extends React.Component {
    constructor() {
        super();
    }
    handleChange (e) {
        e.preventDefault()
        let value = parseInt(e.currentTarget.name);
        if(value==this.props.status.value) value=-1;
        this.props.onChange(this.props.identifier, value)
    }
    render() { 
        const { name, status, info, defaultColor, badColor, goodColor } = this.props
        const styleSets = { 
            inline: { display:'inline-block', verticalAlign:'middle' }
        }
        const styles = {
            item:{
                position:'relative',
                width:500,
                margin:'2px 0px',
                backgroundColor:'#303a42',
                fontSize:0
            },
            label:{ 
                ...styleSets.inline,
                lineHeight:'46px', paddingLeft:30, 
                color:'white', fontSize:14
            },
            rightBox:{
                position:'absolute',
                top:0, right:0, bottom:0,
                textAlign:'right'
            },
            status:{ 
                ...styleSets.inline,
                height:46, width:66, minWidth:66,
                marginLeft:3,
                color:'white', fontSize:14, 
                textAlign:'center', 
                backgroundColor:'rgba(255,255,255,.1)',
                borderRadius:0,
                cursor:'pointer'
            },
            statusLabel: { textTransform:'none', fontWeight:'normal' },
            infoTrigger:{ ...styleSets.inline, height:46, width:46, padding:11 },
            info:{ width:'auto', maxWidth:'400px', padding:'5px 10px' },
            nio:{ backgroundColor:badColor },
            nr:{ backgroundColor:defaultColor  },
            io:{ backgroundColor:goodColor  },
        }
        const getStyle = (status) => {
            const val = this.props.status;
            const baseStyle = styles.status;
            let stateStyle = {};
            switch (status) {
                case 0: {if(val==0){ stateStyle = styles.nio }; break }
                case 2: {if(val==2){ stateStyle = styles.nr }; break }
                case 1: {if(val==1){ stateStyle = styles.io }; break }
            }
            return { ...baseStyle, ...stateStyle}
        }
        return (
            <div style={ styles.item }>
                <div style={ styles.label }>{name}</div>
                <div style={ styles.rightBox }>
                    {   info && 
                        <IconMenu
                            iconButtonElement={<IconButton style={ styles.infoTrigger }><InfoIcon color={ 'rgba(255,255,255,.5)' }/></IconButton>}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                            targetOrigin={{horizontal: 'right', vertical: 'top'}}
                            menuStyle={ styles.info }
                        >
                            <div>{info}</div>
                        </IconMenu> 
                    }
                    <FlatButton name={0} label={'n.i.O.'} style={ getStyle(0) } labelStyle={ styles.statusLabel } onClick={ this.handleChange.bind(this) }/>
                    <FlatButton name={2} label={'n.r.'} style={ getStyle(2) } labelStyle={ styles.statusLabel } onClick={ this.handleChange.bind(this) }/>
                    <FlatButton name={1} label={'i.O.'} style={ getStyle(1) } labelStyle={ styles.statusLabel } onClick={ this.handleChange.bind(this) }/>
                </div>
            </div>
        )
    }
}

export const SuperStatusItem = (props) => { 
    const { info } = props
    const styleSets = { 
        inline: { display:'inline-block', verticalAlign:'middle' }
    }
    const styles = {
        item:{ 
            position:'relative',
            width:500,
            margin:'2px 0px',
            backgroundColor:'#303a42',
            fontSize:14,
            color:'white',
            ...props.style
        },
        label:{ 
            ...styleSets.inline,
            lineHeight:'46px', paddingLeft:30, 
        },
        rightBox:{
            position:'absolute',
            top:0, right:0, bottom:0,
            textAlign:'right'
        },
        status:{ 
            display:'inline-block',
            lineHeight:'46px', width:200,
            color:'white',  
            textAlign:'center', 
            backgroundColor:'rgba(255,255,255,.1)',
        },
        statusDefault: { backgroundColor:'rgba(255,255,255,.1)' },
        statusRed: { backgroundColor:props.badColor },
        statusGreen: { backgroundColor:props.goodColor },
        infoTrigger:{ ...styleSets.inline, height:46, width:46, padding:11 },
        info:{ width:'auto', maxWidth:'400px', padding:'5px 10px' },
    }
    const getStyle = () => {
        switch (props.status) {
            case -1: { return {...styles.status, ...styles.statusDefault } };
            case 0: { return {...styles.status, ...styles.statusRed } };
            case 1: { return {...styles.status, ...styles.statusGreen } };
            default: { return {...styles.status, ...styles.statusDefault } };
        }
    }
    const getStatusLabel = () => {
        switch (props.status) {
            case -1: { return '' };
            case 0: { return 'n.i.O' };
            case 1: { return 'i.O' };
            default: { return '' };
        }
    }
    return (
        <div style={ styles.item }>
            <div style={ styles.label }>{props.name}</div>
            <div style={ styles.rightBox }>
                {   info &&
                    <IconMenu
                        iconButtonElement={<IconButton style={ styles.infoTrigger }><InfoIcon color={ 'rgba(255,255,255,.5)' }/></IconButton>}
                        anchorOrigin={{horizontal: 'middle', vertical: 'bottom'}}
                        targetOrigin={{horizontal: 'middle', vertical: 'top'}}
                        menuStyle={ styles.info }
                    >
                        <div>{info}</div>
                    </IconMenu> 
                }
                <div style={ getStyle() }>{ getStatusLabel() }</div>
            </div>
        </div>
    )
};