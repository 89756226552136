import React from 'react';

import update from 'immutability-helper';
import muiThemeable from 'material-ui/styles/muiThemeable';

// LOGIC
import { getpartsuperstatus } from '../logic'

// COMPONENTS
import { UpdateItem, StatusItem, SuperStatusItem } from './status';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import Subheader from 'material-ui/Subheader';
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'

// COMPONENT
class PartStatusDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: this.props.part.status || {},
            superStatus: this.props.part.superStatus || {}
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState( update(this.state, {
            status: {$set: nextProps.part.status || {}},
            superStatus: {$set: nextProps.part.superStatus || {}}
        }));
    }

    handleSave() {
        this.props.onSave(this.props.part.data.id, this.state.status)
    }
    handleCancel() {
        this.props.onCancel()
    }

    handleChange(key,value) {
        if(value==this.state.status[key]) value=-1;
        const newStatus= update(this.state.status, {
            [key]: {$set: value}
        })
        this.setState( update(this.state, {
            status: {$set: newStatus},
            superStatus: { $set: getpartsuperstatus(newStatus) }
        }));

    }

    render() {
        const { part, muiTheme } = this.props
        const palette = muiTheme.palette
        const secTextColor = this.props.muiTheme.palette.secondaryTextColor;
        const styles = {
            root: {  },
            content:{ maxWidth:'1098px', width:'auto' },
            body: { paddingBottom:0, overflowY:'auto',  },
            header: { marginBottom:50, padding:'none', background:'none' },
            title: { color:'#4A4A4A', fontSize:24, fontWeight:'bold' },
            editor: { fontSize:14, color:secTextColor },
            superStatusBox: { marginBottom:50 },
            item: { height:45, backgroundColor:'grey' },
            box:{ display:'inline-block', verticalAlign:'top' },
            gap:{ width:50, height:0, },
            updateBox: {  },
            statusBox: {  },
            subheader: { lineHeight:'20px', paddingLeft:5, margin:'10px 0px', color:secTextColor, borderBottom:`${secTextColor} solid 1px` },
        }
        const statusMap = {
            st_behaelter: { label:'Behälter', info:'Definierter Behälter ist i.O. oder n.i.O.?' },
            st_regalbelegung: { label:'Regalbelegung', info:'Ist ein Regalbelegungsplan vorhanden und sind alle relevanten Behälter enthalten?' },
            st_layout_app: { label:'Layout APP', info:'Ist das APP-Taktlayout auf aktuellem Stand, insbesondere die Materialanstellung?' },
            st_mitlaufender_wagen: { label:'Mitlaufender Wagen', info:'Ist ein mitlaufender Wagen definiert und ist dieser ausgeplant?' },
            st_visualization: { label:'Visualisierung', info:'Werden für das Teil Informationen benötigt und sind diese entsprechend definiert und als Attribut in AP eingetragen?' },
            st_absicherung: { label:'Absicherung', info:'Ist eine Absicherungsmaßnahme für das Bauteil vorgesehen?' },
            st_umlaufteil: { label:'Umlaufteil', info:'Wird ein Umlaufteil benötigt, ist dieses vorhanden und einsatzbereit? Ist der Rücklieferungsprozess sichergestellt?' }
        }
        const actions = [
            <FlatButton
                label="Abbrechen"
                primary={true}
                onClick={this.handleCancel.bind(this)}
            />,
            <FlatButton
                label="Speichern"
                primary={true}
                onClick={this.handleSave.bind(this)}
            />
        ];
        return (
            <Dialog 
                actions={actions} 
                modal={true} 
                open={this.props.open} 
                onRequestClose={this.handleCancel.bind(this)}
                style={ styles.root }
                contentStyle={ styles.content }
                titleStyle={ styles.title }
                bodyStyle={ styles.body }
                title={(part.data) ? part.data.number + ' - ' + part.data.name : ''}
            >
                <div style={ styles.box }> 
                    <div style={ styles.superStatusBox }> 
                        <Subheader style={ styles.subheader }>Gesamtbewertung</Subheader>
                        <SuperStatusItem 
                            name={'Gesamtaussage Bauteilgüte'} status={ (this.state.superStatus) ? this.state.superStatus.st_gesamtaussage : -1 }
                            info={'Visualisierung, Absicherungsmaß-nahme, Umlaufteil definiert'}
                            defaultColor={palette.defaultColor} goodColor={palette.ioColor} badColor={palette.nioColor}
                        />
                        <SuperStatusItem 
                            name={'Materialbereitstellung'} status={ (this.state.superStatus) ? this.state.superStatus.st_materialbereitstellung : -1 }
                            info={'Behälter definiert, Regalbelegung vollständig, Taktlayout auf aktuellen Stand'}
                            defaultColor={palette.defaultColor} goodColor={palette.ioColor} badColor={palette.nioColor}
                        />
                    </div>
                    {   part.changelog && 
                        <div style={ styles.updateBox }> 
                            <Subheader style={ styles.subheader }>Änderungen seit dem letzten Speichern</Subheader>
                            {   
                                part.changelog.map( (c,i) => 
                                    <UpdateItem key={i} name={ c.key } prev={ c.prevValue } curr={ part.data[c.key] } style={ styles.updateItem } color={palette.updateColor}/>  
                                )
                            }
                        </div>
                    }
                </div>
                <div style={ {...styles.box, ...styles.gap }}/>
                <div style={ styles.box }> 
                    <Subheader style={ styles.subheader }>Status</Subheader>
                    { 
                        Object.keys(this.state.status).map( k =>
                            <StatusItem 
                                key={k}
                                identifier={k} 
                                name={statusMap[k].label}
                                status={ this.state.status[k] } 
                                info={ statusMap[k].info } 
                                onChange={ this.handleChange.bind(this) }
                                defaultColor={palette.defaultColor} goodColor={palette.ioColor} badColor={palette.nioColor}
                            />
                        )
                    }
                </div>
            </Dialog>
        )
    }
};

// EXPORT
export default muiThemeable()(PartStatusDialog);