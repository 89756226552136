// DEPENDENCIES
import update from 'immutability-helper';

const storedMapStatus = localStorage.getItem('mapStatus')

// DEFAULT
const defaultState = {
    ready: false,
    errors: [],
    snack:{
        open:false,
        msg:'',
        type:''
    },
    mapStatus: storedMapStatus ? JSON.parse(storedMapStatus) : 'gesamtaussage',
    configuration:{}
};

// REDUCER
const reducer = (state=defaultState, action) => {
    switch (action.type) {
        case 'INIT_APP': {
            state = update(state, {
                ready: {$set: false }
            });
            break;
        }
        case 'INIT_APP_FULFILLED': {
            state = update(state, {
                ready: {$set: true }
            });
            break;
        }
        case 'LOAD_CONFIGURATION_FULFILLED': {
            state = update(state, {
                configuration: {$set: action.payload }
            });
            break;
        }
        case 'CHANGE_MAP_STATUS': {
            state = update(state, {
                mapStatus: { $set: action.payload },
            });
            localStorage.setItem('mapStatus',JSON.stringify(action.payload))
            break;
        }
    }
    return state
}

// EXPORT
export default reducer;