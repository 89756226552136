"use strict";

// DEPENDENCIES
import React from 'react';

import { connect } from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';

// LOGIC
import { getaggrstatus } from '../logic'

// ACTIONS
import { act_ChangeMapStatus } from '../actions/appActions'
import { act_Subscribe, act_UnSubscribe } from '../actions/userActions'

// COMPONENTS
import View from './view'
import StatusSelector from './statusSelector'
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'
import NotificationsIcon from 'material-ui/svg-icons/social/notifications-active'
import NotificationsOffIcon from 'material-ui/svg-icons/social/notifications-off'
import IconButton from 'material-ui/IconButton';

// ICONS

// COMPONENT
class Plan extends React.Component {

    constructor() {
        super();
    }

    componentWillMount() {

    }
    componentWillReceiveProps(nextProps) {

    }
    handleSelectSection(sectionId) {
        this.props.history.push(`${this.props.match.url}/sections/${sectionId}`)
    }
    handleChangeMapStatus(val) {
        this.props.dispatch( act_ChangeMapStatus(val) )
    }
    handleSubscribeSection(e,sectionId,val) {
        const projectId = this.props.project.data.id
        if(val) this.props.dispatch( act_Subscribe({projectId, sectionId}) )
        else this.props.dispatch( act_UnSubscribe({projectId, sectionId}) )
        e.stopPropagation();
    }
    render() {
        const { project, plan, pending, fulfilled, sections, cycles, workstations, notifications, me, mapStatus, muiTheme }= this.props
        const palette = muiTheme.palette
        const styles = {
            planTypFlag:{ display:'inline-block', fontSize:14, fontWeight:'bold', color:palette.textColor, backgroundColor:palette.midgrey, borderRadius:7, padding:'5px 10px'},
            section:{ 
                alignItems:'flex-start',
                position:'relative', backgroundColor:'white', height:60, minHeight:60, lineHeight:'48px', marginTop:25, cursor:'pointer', transition:'opacity 400ms' 
            },
            sectionTitle:{ fontSize:16, fontWeight:500, color: palette.textColor },
            statusWrapper:{ 
                position:'absolute', bottom:3, left:1, right:1, height:6,
                display:'flex'
            },
            cycle:{
                width:'100%',margin:'0px 2px',//borderLeft:'white solid 2px',borderRight:'white solid 2px',
            },
            tooltip:{ backgroundColor:'white', color:palette.textColor },
            emptyCycle:{
                border:`${palette.defaultColor} solid 1px`
                // borderLeft:`${palette.lightgrey} solid 2px`,borderRight:`${palette.lightgrey} solid 2px`, borderBottom:`${palette.lightgrey} solid 2px`
            },
            updateFlag: { 
                backgroundColor: palette.updateColor, borderRadius:12, padding:'5px 10px', lineHeight:'14px',
                color:'white', fontWeight:500, fontSize:12,
            }
        }
        const getStatusColor = s => {
            if(s===0) return palette.nioColor
            if(s===1) return palette.ioColor
            if(s===-1) return palette.defaultColor
        }
        const getCycles = (sId) => {
            const c = cycles.filter( c => c.parentId == sId )
            const spans = c.map( c => {
                const style = workstations.filter(w=>w.parentId==c.id).length 
                    ? {...styles.cycle, backgroundColor: getStatusColor( getaggrstatus( workstations.filter( w=>w.parentId===c.id).map(w=>w.status['st_' + mapStatus]) )) }
                    : {...styles.cycle, ...styles.emptyCycle} 
                return <span key={c.id} style={style}/>
            })
            return spans
        }
        const getUpdateFlag = (sId) => {
            const n = notifications.filter(n=> n.section_id==sId)
            if(n.length) return <div style={styles.updateFlag}>{n.length}</div>
            else return null
        } 
        const isSubscribed = (sId) => (me.subscriptions.findIndex(s=>s.projectId==project.data.id && s.sectionId==sId) > -1)
        return (
            <View
                pending={pending}
                fulfilled={fulfilled}
                title={ plan.line_key }
                subtitle={ <span style={styles.planTypFlag}>{(plan.plan_key || '').toUpperCase()}</span>}
                rightActions={
                    <StatusSelector/>
                }
                onNavigateBack={null}
            >
            {   sections.map( s =>
                <Toolbar key={s.id} style={ {...styles.section, opacity:s.visible?1:.3} } onClick={ () => this.handleSelectSection(s.id) }>
                    <ToolbarGroup>
                        <ToolbarTitle style={ styles.sectionTitle } text={ s.name }/>
                    </ToolbarGroup>
                    <ToolbarGroup>
                        { getUpdateFlag(s.id) }
                        <IconButton 
                            tooltip={isSubscribed(s.id) ? 'Benachrichtigungen aktiv' : 'Benachrichtigungen inaktiv'} tooltipPosition='bottom-center' tooltipStyles={ styles.tooltip } 
                            onClick={ (e) => this.handleSubscribeSection(e,s.id,!isSubscribed(s.id))}
                        >
                        {   isSubscribed(s.id) && <NotificationsIcon color={palette.textColor}/>}
                        {   !isSubscribed(s.id) && <NotificationsOffIcon color={palette.midgrey}/>}
                        </IconButton>
                    </ToolbarGroup>
                    <div style={ styles.statusWrapper }>
                    {   getCycles(s.id) }
                    </div>
                </Toolbar>
            )}
            </View>
        )

    }
};

// EXPORT
export default connect(store => ({
    project: store.project.current,

    pending: store.plan.current.pending,
    fulfilled: store.plan.current.fulfilled,
    plan: store.plan.current.data,
    sections: store.plan.current.sections,
    cycles: store.plan.current.cycles,
    workstations: store.plan.current.workstations,
    me: store.user.me,
    mapStatus: store.app.mapStatus,
    notifications: store.notification.list
}))(
    muiThemeable()(Plan)
);