
export const act_LoadWorkstation = (payload) => ({ type:'LOAD_WORKSTATION', payload })

export const act_SetWorkloadStatus = (payload) => ({ type:'SET_WORKLOAD_STATUS', payload })

export const act_SetPartStatus = (payload) => ({ type:'SET_PART_STATUS', payload })

export const act_SetScrewcaseStatus = (payload) => ({ type:'SET_SCREWCASE_STATUS', payload })

export const act_SetResourceStatus = (payload) => ({ type:'SET_RESOURCE_STATUS', payload })

export const act_UploadImage = (payload) => ({ type:'UPLOAD_IMAGE', payload })

export const act_DeleteImage = (payload) => ({ type:'DELETE_IMAGE', payload })

export const act_ChangePosition = (payload) => ({ type:'CHANGE_POSITION', payload})

export const act_CreateComment = (payload) => ({ type:'CREATE_COMMENT', payload})

export const act_ChangeComment = (payload) => ({ type:'CHANGE_COMMENT', payload})

export const act_DeleteComment = (payload) => ({ type:'DELETE_COMMENT', payload})

export const act_FilterChange = (payload) => ({ type:'FILTER_CHANGE', payload})

