
import React from 'react';
import update from 'immutability-helper';

import { connect } from 'react-redux';

import _ from 'lodash'

// MUI-THEME
import muiThemeable from 'material-ui/styles/muiThemeable';

// ACTIONS
import { act_SaveUser } from '../actions/userActions.js'

// COMPONENTS
import { Panel } from './helper.js';
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';

// ICONS


// COMPONENT
class User extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            errors: { firstname:'', lastname:'', mail:'', department:'' }
        }
    }
    componentWillReceiveProps(nextProps){
        this.setState( update(this.state, {
            data: {$set: nextProps.data},
            errors: {$set: { firstname:'', lastname:'', mail:'', department:'' }}
        }));
    }

    handleChange(e) {
        e.preventDefault();
        const key = e.target.name, value = e.target.value;
        this.setState( update(this.state, {
            data:{ [key]: {$set: value} },
            errors:{ [key]: { $set: ''} }
        }));
    }

    handleSave() {
        // let errors = {}
        // let ready = true
        // Object.keys(this.state.errors).forEach( el => {
        //     if(this.state.data[el] == '') {
        //         ready = false;
        //         errors[el] = 'Dieser Wert muss gesetzt sein.'
        //     } else errors[el] = ''
        // })
        // const userId = this.props.data.id, data = this.state.data
        // if(ready) this.props.dispatch( act_SaveUser({userId, data}) )
        // else this.setState( update(this.state, { errors:{$merge: errors} }))
        
    }

    render() {
        const { data } = this.state
        const styles = {
            header: { 
                marginBottom:15, padding:'none', background:'none' 
            },
            title: { fontSize:30, fontWeight:'bold', color:'#4A4A4A' },
            input:{ width:400 },
        }
        const actions = [
            <FlatButton
                key={0}
                label="Speichern"
                primary={true}
                onClick={this.handleSave.bind(this)}
                disabled={ _.isEqual(data, this.props.data) }
            />
        ]
        return (
            <Panel width={600} actions={ actions } title={ 'Nutzerdaten' }>
                <div>
                    <TextField 
                        name={'id'}
                        style={ styles.input }
                        value={ data.gid } 
                        hintText="Geben Sie Ihren Windows Nutzernamen an"  
                        floatingLabelText="Windows Nutzername" 
                        disabled
                    />
                </div>
                <div>
                    <TextField 
                        name={'firstname'}
                        style={ styles.input }
                        value={ data.given_name } 
                        hintText="Geben Sie Ihren Vornamen an"  
                        floatingLabelText="Vorname" 
                        disabled
                    />
                    <TextField 
                        name={'lastname'}
                        style={ styles.input }
                        value={ data.family_name } 
                        hintText="Geben Sie Ihren Nachnamen an"  
                        floatingLabelText="Nachname" 
                        disabled
                    />
                </div>
                <div>
                    <TextField 
                        name={'email'}
                        style={ styles.input }
                        value={ data.email } 
                        hintText="Geben Sie Ihre eMail-Adresse an"  
                        floatingLabelText="eMail" 
                        disabled
                    />
                </div>
                <div>
                    <TextField 
                        name={'roles'}
                        style={ styles.input }
                        value={ data['x-groups'] } 
                        hintText="Geben Sie Ihre eMail-Adresse an"  
                        floatingLabelText="Rollen" 
                        disabled
                    />
                </div>
            </Panel>
        )
    }
};

// EXPORT
export default connect(store => ({
    data: store.oidc.user.profile
}))(
    muiThemeable()(User)
);