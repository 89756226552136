"use strict";

// DEPENDENCIES
import React from 'react';

import update from 'immutability-helper';

import { connect } from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';

// LOGIC
import { getaggrstatus } from '../logic'

// ACTIONS
import { 
    act_Export,
    act_LoadExportPreview,
    act_SetExportBlacklist
} from '../actions/projectActions'
import { act_SetHeightConnectTransfer } from '../actions/cycleActions'

// COMPONENTS
import View from './view'
import { Panel } from './helper'
import ItemList from './itemList'
import Warning from './warning'
import Blacklist from './blacklist';

import FlatButton from 'material-ui/FlatButton';
import Checkbox from 'material-ui/Checkbox';


// COMPONENT
class Export extends React.Component {

    constructor() {
        super();
        this.state = { 

        }
    }

    componentWillMount() {
        const export_id = this.props.match.params.id
        this.inti(export_id)
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.match.params.id != this.props.match.params.id)  this.inti(nextProps.match.params.id)
    }

    inti(export_id) {
        const project_id = this.props.project.id
        this.props.dispatch( act_LoadExportPreview({project_id, export_id}))
    }

    handleConnectTransfer(height, connect_transfer) {
        const projectId = this.props.project.id
        const action = act_SetHeightConnectTransfer({ projectId, cycleId: height.cycle_id, heightId: height.id, connect_transfer})
        this.props.dispatch( action )
    }
    handleCloseBlacklist(blacklist) {
        const project_id = this.props.project.id, export_id = this.props.data.id
        this.props.dispatch( act_SetExportBlacklist({project_id, export_id, blacklist}))
    }
    handleExport(topic, filter){
        this.props.dispatch( act_Export({project_id:this.props.project.id, export_id:this.props.match.params.id}) )
    }

    render() {
        const { pending, fulfilled, data, columns, rows, blacklist, plan, project, muiTheme 
        } = this.props
        const palette = muiTheme.palette
        const styles = {
            panel:{ margin:0 }
        }        
        const tableColumns = columns.map(c=>({...c, filter:true}))
        let tableRows = rows.slice()
            tableRows.splice(0,1)
            tableRows = tableRows.map(r=>{
                const row = {...r}
                if(data.id=='heights') {
                    row.connect_transfer = <Checkbox
                        checked={ r.connect_transfer===1||r.connect_transfer===true ? true : false }
                        onCheck={ (e,v) => this.handleConnectTransfer(r, v) }
                    />
                    row.warning = r.warning ? <Warning type={'height'} code={r.warning}/> : null
                }
                return row
            })
        
        return (
            <View
                pending={pending}
                fulfilled={fulfilled}
                title={ data.name } 
                rightActions={[
                    <Blacklist 
                        key={0}
                        buttonType={'Flatbutton'}
                        blacklist={blacklist} 
                        plan={plan} 
                        onClose={ this.handleCloseBlacklist.bind(this) }
                    />,
                    <FlatButton key={1} style={ styles.downloadBtn } label="Download" primary={true} onClick={this.handleExport.bind(this)}/>
                ]}
                onNavigateBack={null}
            >
                <Panel width={ '100%' } style={styles.panel}>
                    <ItemList 
                        columns={ tableColumns } 
                        rows={ tableRows }
                        rowHeight={ 48 }
                        identifier={'id'}
                    />
                </Panel>
            </View>
        )
    }
};

// EXPORT
export default connect(store => ({
    pending: store.project.export.pending,
    fulfilled: store.project.export.fulfilled,
    data: store.project.export.data,
    columns: store.project.export.columns,
    rows: store.project.export.rows,
    blacklist: store.project.export.blacklist,
    project: store.project.current.data,
    plan: store.plan.current
}))(
    muiThemeable()(Export)
);