import { combineEpics } from 'redux-observable';

import { epc_InitApp, epc_HandleError, epc_LoadConfiguration } from './appEpics';
import {  } from './userEpics';
import { epc_LoadMe, epc_SaveUser, epc_Subscribe, epc_UnSubscribe, 
    // epc_SaveSettings 
} from './userEpics';
import { epc_LoadProjects, epc_LoadProject, epc_SaveProject, epc_DeleteProject, epc_ChangeProjectPosition, epc_LoadPrograms, epc_LoadFilter, epc_LoadExportPreview, epc_Export, epc_SetExportBlacklist, epc_GetExportBlacklist, epc_LoadSyncJobs, epc_SyncProject, epc_CancelSyncJob, epc_ReorderSyncJob } from './projectEpics';
import { epc_LoadPlans, epc_LoadPlan, epc_LoadTempPlan } from './planEpics';
import { epc_LoadSection } from './sectionEpics';
import { epc_LoadCycle, epc_CreateHeight, epc_UpdateHeight, epc_DeleteHeight, epc_SetHeightConnectTransfer } from './cycleEpics';
import { 
    epc_LoadWorkstation, 
    epc_ChangePosition, epc_SetWorkloadStatus, epc_SetPartStatus, epc_SetScrewcaseStatus, epc_SetResourceStatus,
    epc_CreateComment, epc_ChangeComment, epc_DeleteComment,
    epc_UploadImage, epc_DeleteImage
} from './workstationEpics';
import { epc_Search } from './searchEpics'
import {
    epc_LoadChartConfig, 
    epc_LoadDashboards, epc_LoadDashboard, epc_CreateDashboard, epc_UpdateDashboardData, epc_ChangeDashboardBlacklist, epc_DuplicateDashboard, epc_DeleteDashboard, epc_ChangeDashboardPosition,
    epc_AddChart, epc_ChangeChartBlacklist, epc_ChangeChartSize, epc_ChangeChartPosition, epc_DeleteChart, epc_LoadChartData
} from './dashboardEpics';

const rootEpic = combineEpics(
    epc_InitApp, epc_HandleError, epc_LoadConfiguration,

    epc_LoadMe, epc_SaveUser, epc_Subscribe, epc_UnSubscribe, //epc_SaveSettings, 
    
    epc_LoadProjects, epc_LoadProject, epc_SaveProject, epc_DeleteProject, epc_ChangeProjectPosition, epc_LoadPrograms, epc_LoadFilter, epc_LoadExportPreview, epc_Export, epc_SetExportBlacklist, epc_GetExportBlacklist, epc_LoadSyncJobs, epc_SyncProject, epc_CancelSyncJob, epc_ReorderSyncJob,

    epc_LoadPlans, epc_LoadPlan, epc_LoadTempPlan,

    epc_LoadSection,

    epc_LoadCycle, epc_CreateHeight, epc_UpdateHeight, epc_DeleteHeight, epc_SetHeightConnectTransfer,

    epc_LoadWorkstation, 
    epc_ChangePosition, epc_SetWorkloadStatus, epc_SetPartStatus, epc_SetScrewcaseStatus, epc_SetResourceStatus, 
    epc_CreateComment, epc_ChangeComment, epc_DeleteComment,
    epc_UploadImage, epc_DeleteImage,

    epc_Search,

    epc_LoadChartConfig,
    epc_LoadDashboards, epc_LoadDashboard, epc_CreateDashboard, epc_UpdateDashboardData, epc_ChangeDashboardBlacklist, epc_DuplicateDashboard, epc_DeleteDashboard, epc_ChangeDashboardPosition,
    epc_AddChart, epc_ChangeChartBlacklist, epc_ChangeChartSize, epc_ChangeChartPosition, epc_DeleteChart, epc_LoadChartData
);

export default rootEpic