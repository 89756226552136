
import React from 'react';

import update from 'immutability-helper';

// MUI-THEME
import muiThemeable from 'material-ui/styles/muiThemeable';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { darkTheme, lightTheme } from '../theme.js';

// COMPONENTS
import {List, ListItem} from 'material-ui/List';
import IconButton from 'material-ui/IconButton';

import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import FlatButton from 'material-ui/FlatButton';

import ImageIcon from 'material-ui/svg-icons/image/image';
import AddImageIcon from 'material-ui/svg-icons/image/add-a-photo';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';

// COMPONENT
class ImageViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected:this.props.list.length ? 0 : -1
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.list!=this.props.list){
            this.setState( update(this.state, {
                // selected: {$set: nextProps.list[0].id||'' }
            }));
        }
    }

    handleSelect(index) {
        this.setState( update(this.state, {
            selected: {$set: index}
        }));
    }

    handleUpload(e) {
        const files = e.target.files;

        const uploadFile = files[0];

        let data = new FormData();
        data.append('file', uploadFile);
        data.append('position', 1);
        this.props.onUpload(data)
        this.setState( update(this.state, {
            selected: {$apply: (prev) => prev+1 },
        }));
    }

    handleDelete(id) {
        this.setState( update(this.state, {
            selected: {$apply: (prev) => prev-1 },
        }));
        this.props.onDelete(id)
    }

    render() {
        const { editable, list, style, muiTheme, imagePath } = this.props
        const { selected, checked } = this.state

        const palette = muiTheme.palette
        const styles = {
            wrapper:{
                display:'flex', 
                width:'100%', 
                // backgroundColor:this.props.muiTheme.palette.darkgrey,
                overflow:'hidden', whiteSpace:'nowrap',
                ...style
            },
            list:{ width:'25%' },
            listItem:{ fontSize:14 },
            itemLabel:{ overflow:'hidden', textOverflow:'ellipsis' },
            selected:{  
                color:palette.primary1Color,
            },
            menu:{ padding:0 },
            menuItem:{ height:38, fontSize:14 },
            uploadItem:{ color:palette.midgrey },
            viewer:{ width:'75%', padding:10, textAlign:'center' },
            image:{ maxWidth:'100%', maxHeight:450 },
        }
        const getItemStyle = (index) => index==selected ? {...styles.listItem, ...styles.selected } : styles.listItem
        return (
            <MuiThemeProvider muiTheme={ lightTheme }>
                <div style={ styles.wrapper }>
                    <List style={ styles.list }>
                        {   list.map( (img,index) => 
                            <ListItem 
                                key={img.id} 
                                style={ getItemStyle(index) }
                                primaryText={ <div style={ styles.itemLabel }>{ img.key }</div> }
                                leftIcon={ <ImageIcon color={ getItemStyle(index).color }/> }
                                rightIconButton={ editable && 
                                    <IconMenu
                                        style={ styles.menu }
                                        iconButtonElement={<IconButton><MoreVertIcon color={ palette.midgrey }/></IconButton>}
                                        anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                                        targetOrigin={{horizontal: 'right', vertical: 'top'}}
                                    >
                                    {
                                        <MenuItem primaryText="Löschen" style={ styles.menuItem } onClick={ () => this.handleDelete(img.id) }/>
                                    }
                                    </IconMenu> 
                                }
                                onClick={ () => this.handleSelect(index) }
                            />
                        )}
                        {   editable && 
                            <FlatButton containerElement='label' label='Bild hochladen'>
                                <input style={{display:'none'}} type="file" onChange={ this.handleUpload.bind(this) }/>
                            </FlatButton>
                        }
                    </List>
                    <div style={ styles.viewer }>
                    {   
                        <img style={ styles.image } src={ list[selected] ? list[selected].url : '' }/>
                    }
                    </div>
                </div>
            </MuiThemeProvider>
        )
    }
};

// EXPORT
export default muiThemeable()(ImageViewer)