
import update from 'immutability-helper';
import api from '../api';

// DEFAULT
const defaultState = {
    list: {
        pending:false,
        fulfilled:false,
        items:[]
    },
    current: {
        pending:false,
        fulfilled:false,
        data: {},
        program:{},
        sections: [],
        cycles: [],
        workstations: [],
        stats: {},
        date: ''
    },
    temp: {
        pending:false,
        fulfilled:false,
        data:{},
        sections: [],
        cycles: [],
        workstations: [],
    },
    search:{
        pending: false,
        fulfilled: false,
        activeSearchValue:'',
        results: []
    }
};

const defaultStatus = api.get_default_workstation_status()

const resetVis = (state) => {
    const { current } = state
    const sections = current.sections.map( s => ({...s, visible:true} ))
    const cycles = current.cycles.map( c => ({...c, visible:true} ))
    const workstations = current.workstations.map( w => ({...w, visible:true} ))//TODO remap after we switchd number as id
    return {sections,cycles,workstations}
}
const calcVis = (state) => {
    const { current, search } = state
    const locs = search.results.reduce((a,b)=>a.concat(b.selected?b.loc:[]),[]);    
    const sections = current.sections.map( s => ({...s, visible:locs.find(l=> l.section_id==s.id)?true:false} ))
    const cycles = current.cycles.map( c => ({...c, visible:locs.find(l=> l.cycle_id==c.id)?true:false} ))
    const workstations = current.workstations.map( w => ({...w, visible:locs.find(l=> l.workstation_id==w.id)?true:false} ))//TODO remap after we switchd number as id
    return {sections,cycles,workstations}
}


// REDUCER
const reducer = (state=defaultState, action) => {
    switch (action.type) {
        case 'LOAD_PLANS_PENDING': {
            state = update(state, {
                list: {
                    pending: {$set: true},
                    fulfilled: {$set: false},
                }
            });
            break;
        }
        case 'LOAD_PLANS_FULFILLED': {
            state = update(state, {
                list: {
                    pending: {$set: false},
                    fulfilled: {$set: true},
                    items: {$set: action.payload}
                }
            });
            break;
        }
        case 'LOAD_PLANS_REJECTED': {
            state = update(state, {
                list: {
                    pending: {$set: false},
                    fulfilled: {$set: false},
                    items: {$set: []}
                }
            });
            break;
        }
        case 'LOAD_PLAN_PENDING': {
            state = update(state, {
                current: {
                    pending: {$set: true},
                    fulfilled: {$set: false},
                }
            });
            break;
        }
        case 'LOAD_PLAN_FULFILLED': {
            const { data, sections, cycles, workstations, stats, program } = action.payload
            state = update(state, {
                current: {
                    pending: {$set: false},
                    fulfilled: {$set: true},
                    data: {$set: data},
                    sections: {$set: sections },
                    cycles: {$set: cycles },
                    workstations: {$set: workstations },
                    stats: {$set: stats},
                    program: {$set: program},
                },
                search:{
                    pending: {$set: false},
                    fulfilled: {$set: false},
                    results: {$set: []},
                    activeSearchValue: {$set: ''},
                }
            });
            state = update(state, {
                current: {$apply: c=>({...c,...resetVis(state)})}
            });
            break;
        }
        case 'LOAD_PLAN_REJECTED': {
            state = update(state, {
                current: {
                    pending: {$set: false},
                    fulfilled: {$set: false},
                    data: {$set: {} },
                    sections: {$set: [] },
                    cycles: {$set: [] },
                    workstations: {$set: [] },
                    stats: {$set: {} },
                    program: {$set: {} },
                }
            });
            break;
        }
        case 'LOAD_TEMP_PLAN_PENDING': {
            state = update(state, {
                temp: {
                    pending: {$set: true},
                    fulfilled: {$set: false},
                }
            });
            break;
        }
        case 'LOAD_TEMP_PLAN_FULFILLED': {
            const { data, sections, cycles, workstations } = action.payload
            state = update(state, {
                temp: {
                    pending: {$set: false},
                    fulfilled: {$set: true},
                    data: {$set: data},
                    sections: {$set: sections },
                    cycles: {$set: cycles },
                    workstations: {$set: workstations },
                }
            });
            break;
        }
        case 'LOAD_TEMP_PLAN_REJECTED': {
            state = update(state, {
                temp: {
                    pending: {$set: false},
                    fulfilled: {$set: false},
                    data: {$set: {} },
                    sections: {$set: [] },
                    cycles: {$set: [] },
                    workstations: {$set: [] },
                }
            });
            break;
        }
        case 'TOGGLE_TREE_ITEM': {
            const { id, type, open, self } = action.payload
            let sId = 0, cId = 0, wId = 0
            let sIndex = -1, cIndex = -1, wIndex = -1
            const plan = state.current
            if(type=='workstation'){
                wId = id
                cId = plan.workstations.find(w=>w.id===wId).parentId
                sId = plan.sections.find(s => s.id === plan.cycles.find(c=>c.id === cId).parentId).id
            }
            else if(type=='cycle'){
                cId = id
                sId = plan.cycles.find(c=>c.id === id).parentId
            }
            else if(type=='section'){
                sId = id
            }
            if(type!=='section' || self) sIndex= plan.sections.findIndex(s=>s.id===sId)
            if(type!=='cycle' || self) cIndex= plan.cycles.findIndex(c=>c.id===cId)
            if(type!=='workstation' || self) wIndex= plan.workstations.findIndex(w=>w.id===wId)
                
            if(sIndex>-1){
                state = update(state, {
                    current: {sections: {[sIndex]: {open: {$apply: ps=> type==='section' ? open||!ps : true } }} },
                });
            }
            if(cIndex>-1){
                state = update(state, {
                    current: {cycles: {[cIndex]: {open: {$apply: ps=> type==='cycle' ? open||!ps : true } }} },
                });
            }
            if(wIndex>-1){
                state = update(state, {
                    current: {workstations: {[wIndex]: {open: {$apply: ps=> type==='workstation' ? open||!ps : true } }} },
                });
            }
            break;

        }
        case 'SEARCH': {
            state = update(state, {
                search:{
                    activeSearchValue: {$set: action.payload.query},
                }
            });
            break;
        }
        case 'SEARCH_PENDING': {
            state = update(state, {
                search:{
                    pending: {$set: true},
                    // fulfilled: {$set:false},
                    results: {$set: []}
                }
            });
            break;
        }
        case 'SEARCH_FULFILLED': {
            state = update(state, {
                search:{
                    pending: {$set: false},
                    fulfilled: {$set:true},
                    results: {$set: action.payload.map(p=>({...p,selected:true}))}
                }
            });
            state = update(state, {
                current: {$apply: c=>({...c,...calcVis(state)})}
            });
            break;
        }
        case 'CANCEL_SEARCH': {
            state = update(state, {
                search:{
                    pending: {$set: false},
                    fulfilled: {$set:false},
                    activeSearchValue: {$set: ''},
                    results: {$set: []}
                },
                current: {$apply: c=>({...c,...resetVis(state)})}
            });
            break;
        }
        case 'SELECT_SEARCH_ITEM': {
            const { type, id, selected } = action.payload
            const index = state.search.results.findIndex(r=>r.type==type&&r.id==id)
            state = update(state, {
                search:{
                    results: {[index]: {selected: {$set: selected}}}
                }
            });
            state = update(state, {
                current: {$apply: c=>({...c,...calcVis(state)})}
            });
            break;
        }
        case 'SELECT_SEARCH_TYPE': {
            const { type, selected } = action.payload
            let results = state.search.results.map(r=>r.type===type?{...r,selected}:r)
            state = update(state, {
                search:{
                    results: {$set: results}
                }
            });
            state = update(state, {
                current: {$apply: c=>({...c,...calcVis(state)})}
            });
            break;
        }
        case 'CHANGE_POSITION': {
            const { workstationId, position } = action.payload
            const index = state.current.workstations.findIndex( w => w.id==workstationId )
            state = update(state, {
                current: { workstations: {
                    [index]: {position: {$set: position }}
                }}
            });
            break;
        }
        case 'LOAD_WORKSTATION_FULFILLED': {
            const workstation = action.payload
            const index = state.current.workstations.findIndex( w => w.id==workstation.data.id )
            state = update(state, {
                current: {
                    workstations: {[index]: {status: {$set: workstation.status} } }
                }
            });
            break;
        }
        case 'RESET_STATUS': {
            const workstations = state.current.workstations.map(w=>({...w,status:defaultStatus}))
            state = update(state, {
                current: {
                    workstations: {$set: workstations}
                }
            });
            break;
        }
    }
    return state
}

// EXPORT
export default reducer;