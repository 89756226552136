import React from "react";

import { connect } from 'react-redux';
import update from "immutability-helper";
import _ from 'lodash'


import { 
  act_SaveProject, 
  act_LoadPrograms, act_LoadFilter,
  act_DeleteProject, act_ResetAll
} from '../actions/projectActions';
import { act_LoadTempPlan } from '../actions/planActions';



// THEME
import muiThemeable from "material-ui/styles/muiThemeable";

// COMPONENTS
import { Panel } from './helper.js';
import DeleteDialog from './confirmDialog';
import Blacklist from './blacklist';
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import IconButton from "material-ui/IconButton";
import FlatButton from "material-ui/FlatButton";
import Slider from "material-ui/Slider";
import { List, ListItem } from 'material-ui/List';
import Checkbox from 'material-ui/Checkbox';

import UncheckedCheckboxIcon from 'material-ui/svg-icons/toggle/check-box-outline-blank'
import IndeterminateCkeckboxIcon from 'material-ui/svg-icons/toggle/indeterminate-check-box'

import CarIcon from "material-ui/svg-icons/maps/directions-car";
import WorkloadIcon from "material-ui/svg-icons/action/alarm-on";
import TrafficIcon from "material-ui/svg-icons/maps/traffic";
import StableIcon from "material-ui/svg-icons/action/lock-outline";
import InputIcon from "material-ui/svg-icons/action/input";
import continuousColorLegend from "react-vis/dist/legends/continuous-color-legend";

// COMPONENT
class ProjectConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      errors: Object.keys(this.props.data).reduce((acc, cur) => { acc[cur] = ""; return acc; }, {}),
      deleteDialogOpen: false
    };
  }
  componentWillMount() {
    this.init(this.props.data)
  }
  componentWillReceiveProps(nextProps) {
    if(!_.isEqual(this.props.data, nextProps.data)) {
      this.setState(update(this.state,{
        data: {$set: nextProps.data},
        errors: {$set: Object.keys(nextProps.data).reduce((acc, cur) => { acc[cur] = ""; return acc; }, {}) },
      }))
    }
    // if(this.props.data.id !== nextProps.data.id) this.init(nextProps.data)
  }
  init(data){
    const { id, line_key, plan_key, program_id } = data
    if(line_key) {
      this.getPrograms(line_key)
    }
    if(program_id && program_id > 0){
      this.getFilter(id, line_key, plan_key, program_id)
    }
  }
  handleSave() {
    const data = this.state.data;
    let errors = {};
    let ready = true;
    if (data.prefix == "") {
      ready = false;
      errors.prefix = "Dieser Wert muss gesetzt sein";
    }
    if (data.plant == "") {
      ready = false;
      errors.plant = "Dieser Wert muss gesetzt sein";
    }
    if( !data.line_key || data.line_key == '' ) { 
      ready=false; 
      errors.line_key = 'Dieser Wert muss gesetzt sein' 
    }
    if( !data.program_id || data.program_id == '' ) { 
      ready=false; 
      errors.program_id = 'Dieser Wert muss gesetzt sein' 
    }
    const { history } = this.props
    if (ready) this.props.dispatch( act_SaveProject({projectId:data.id, data, history}) )
    else this.setState(update(this.state, { errors: { $merge: errors } }));
  }

  handleChange(key, value) {
    this.setState( update(this.state, {
        data: {
          [key]: { $set: value }
        },
        errors: {
          [key]: { $set: "" }
        }
      })
    );
  }

  handleWorkloadMinChange(e, v) {
    this.setState( update(this.state, {
        data: {
          min_workload: { $set: v },
          max_workload: { $set: Math.min(Math.max(v + 0.01, this.state.data.max_workload), 1) }
        }
      })
    );
  }
  handleWorkloadMaxChange(e, v) {
    this.setState( update(this.state, {
        data: {
          min_workload: { $set: Math.max(Math.min(v - 0.01, this.state.data.min_workload), 0) },
          max_workload: { $set: v }
        }
      })
    );
  }

  handleChangePlan(plan) {
    if(plan.line_key!=this.state.data.line_key){
      this.getPrograms(plan.line_key)
    }
    this.setState(
      update(this.state, {
        data: {
          plan_key: { $set: plan.key },
          line_key: { $set: plan.line_key },
          program_id: {$set: null},
          filter_keys: {$set: 'all'},
          blacklist: {$set: []},
        },
        errors: {
          line_key: { $set: "" }
        }
      })
    );
  }

  handleChangeProgram(program_id) {
    const { id, line_key, plan_key } = this.state.data
    this.getFilter(id, line_key, plan_key, program_id)
    this.setState(
      update(this.state, {
        data: {
          program_id: {$set: program_id},
          filter_keys: {$set: 'all'}
        },
        errors: {
          program_id: { $set: "" }
        }
      })
    );
  }

  handleSelectProject(project_name){
    let keys = []
    if(this.state.data.filter_keys === 'all'){
        keys = this.props.filter.filter(f=>f.project!=project_name).map(p=>p.project)
    } else {
        keys = this.state.data.filter_keys.split(',')
        if(keys.includes(project_name)) keys = keys.filter(k=>k!=project_name)
        else {
            const projectModels = this.props.filter.find(f=>f.project===project_name).models.map(m=>m.key)
            keys = [...keys.filter(k=>!projectModels.includes(k)), project_name]
        }
    }
    const keys_str = this.props.filter.every(f=> keys.includes(f.project))
      ? 'all'
      : keys.sort().join(',')
    this.setState(update(this.state,{
      data:{
        filter_keys: {$set: keys_str}
      }
    }))
  } 
  handleSelectModel(key){
    let keys = []
    const project = this.props.filter.find(f=>f.models.find(m=>m.key===key))
    if(this.state.data.filter_keys === 'all'){
        keys = this.props.filter.filter(f=>f.project!=project.project).map(p=>p.project)
        keys.push(...project.models.filter(m=>m.key!==key).map(m=>m.key))
    } else {
        keys = this.state.data.filter_keys.split(',')
        if(keys.includes(key)) keys = keys.filter(k=>k!=key)
        else if(keys.includes(project.project)) keys = [...keys.filter(k=>k!=project.project),...project.models.filter(m=>m.key!==key).map(m=>m.key)]
        else if(project.models.every(m=>[...keys,key].includes(m.key))) keys = [...keys.filter(k=>!project.models.map(m=>m.key).includes(k)),project.project]
        else keys.push(key)
    }
    const keys_str = this.props.filter.every(f=> keys.includes(f.project))
      ? 'all'
      : keys.sort().join(',')
    this.setState(update(this.state,{
      data:{
        filter_keys: {$set: keys_str}
      }
    }))
  }

  handleRequestDeleteProject(projectId) { 
    this.setState(update(this.state,{
      deleteDialogOpen: {$set: true},
    }))
  }
  handleDeleteProject() { 
    const projectId = this.props.data.id
    this.props.dispatch( act_DeleteProject({projectId}) ) 
    
    this.props.history.push('/')
  }
  handleCancelDeleteProject() { 
    this.setState(update(this.state,{
      deleteDialogOpen: {$set: false},
    }))
  }
  handleCancelCreateProject() { 
    this.props.dispatch(act_ResetAll())
    this.props.history.push('/')
  }
  handleLoadTempPlan() {
    const { line_key, plan_key } = this.props.temp_plan.data
    if(line_key!=this.state.data.line_key || plan_key!=this.state.data.plan_key){
      this.props.dispatch( act_LoadTempPlan({line_key:this.state.data.line_key, plan_key:this.state.data.plan_key}) )
    }
  }
  handleChangeBlacklist(blacklist) {
    this.setState(update(this.state,{
      data:{
        blacklist: {$set: blacklist}
      }
    }))
  }
  getPrograms(line_key){
    this.props.dispatch( act_LoadPrograms({line_key}) ) 
  }
  getFilter(project_id, line_key, plan_key, program_id){
    this.props.dispatch( act_LoadFilter({project_id, line_key, plan_key, program_id}) )
  }

  render() {
    const { data, deleteDialogOpen, errors } = this.state;
    const { temp_plan, plans, programs, filter, muiTheme } = this.props;
    const palette = muiTheme.palette;

    const selectedPlan =
      data.line_key && data.plan_key
        ? plans.find(
            p => p.key === data.plan_key && p.line_key === data.line_key
          )
        : null;


    const secondaryTextColor = palette.secondaryTextColor;

    const styles = {
      panel: { margin:'15px 30px'},
      subtitle: { color: secondaryTextColor, marginTop: 30 },
      subheader: {
        padding: "0px 10px",
        color: secondaryTextColor,
        fontSize: 14
      },
      subheaderIcon: { verticalAlign: "middle" },
      subheaderText: { marginLeft: 12, verticalAlign: "sub" },

      section: {
        padding: 10,
        marginTop: 15,
        backgroundColor: "white",
        borderRadius: 10,
        border: "#757575 solid 1px"
      },

      inputfield: {
        display: "inline-block",
        verticalAlign: "bottom",
        margin: "0px 10px"
      },
      prefix: { width: 100 },
      plant: { width: 200 },
      name: { width: 200 },
      plan: { width: data.line_key ? "calc(100% - 68px)" : "calc(100% - 20px)" },
      errorStyle: {},
      // errorStyle: { color:'#EE0D67' },

      workload: { position: "relative", height: 80, margin: "0 60px" },
      sliderWrapper: {
        position: "absolute",
        height: 18,
        width: "100%",
        left: 0,
        top: 0,
        bottom: 0,
        margin: "auto"
      },
      slider: { margin: 0 },
      track: {
        position: "absolute",
        backgroundColor: "#e5e5e5",
        height: 2,
        width: "100%",
        top: 0,
        bottom: 0,
        margin: "auto"
      },
      range: {
        position: "absolute",
        backgroundColor: palette.primary1Color,
        height: 3,
        top: 0,
        bottom: 0,
        margin: "auto"
      },
      sliderValue: {
        position: "absolute",
        width: 50,
        padding: "5px 0px",
        textAlign: "center",
        bottom: 0,
        fontSize: 16,
        color: "white",
        borderRadius: 5,
        backgroundColor: palette.primary1Color
      },
      percentageTrack:{
        position:'relative',
        marginTop:3, height:3,
        backgroundColor:palette.lightgrey
      },
      percentageBar: {
        position:'absolute',
        left:0, top:0, bottom:0,
        backgroundColor:palette.primary1Color,
        transition:'200ms'
      },
      programDefaultLabel:{ color:palette.midgrey, fontWeight:500, marginLeft:10, textTransform:'uppercase' },
      description: { color:secondaryTextColor, fontSize: 14 }
    };

    const isProjectSelected = project => {
      return data.filter_keys == 'all' || data.filter_keys.split(',').includes(project.project)
    }
    const isModelSelected = model => {
        const modelProject = filter.find(f=>f.models.find(m=>m.key===model.key))
        return data.filter_keys == 'all' || data.filter_keys.split(',').includes(modelProject.project) || data.filter_keys.split(',').includes(model.key)
    }
    let leftActions = []
    if(data.id === 'new') leftActions.push(
      <FlatButton
        key={0}
        label="Abbrechen"
        primary={false}
        onClick={this.handleCancelCreateProject.bind(this)}
      />
    ) 
    else leftActions.push(
      <FlatButton
        key={0}
        label="Projekt löschen"
        primary={false}
        onClick={this.handleRequestDeleteProject.bind(this)}
      />
    )
    const actions = [
      <FlatButton
          key={0}
          label="Speichern"
          primary={true}
          onClick={this.handleSave.bind(this)}
          disabled={ _.isEqual(data, this.props.data) }
      />
  ]

    return (
      <Panel width={650} title={'Projekt-Einstellungen'} actions={ actions } leftActions={ leftActions }>
        <div style={styles.section}>
          <div style={styles.subheader}>
            <CarIcon color={secondaryTextColor} style={styles.subheaderIcon} />
            <span style={styles.subheaderText}>{'Project'}</span>
          </div>
          <div>
            <SelectField
              floatingLabelText={"Präfix"}
              style={{ ...styles.inputfield, ...styles.prefix }}
              errorStyle={styles.errorStyle}
              value={data.prefix || ""}
              errorText={errors.prefix}
              onChange={(e, i, v) => this.handleChange("prefix", v)}
            >
              {["VW","VN","SK","SE","AU","PO","BY","LB","BG","MN","SC","DU"].map((el, i) => (
                <MenuItem
                  key={i}
                  style={styles.menuItem}
                  value={el}
                  primaryText={el}
                />
              ))}
            </SelectField>
            <TextField
              style={{ ...styles.inputfield, ...styles.plant }}
              errorStyle={styles.errorStyle}
              hintText="z.B. 21"
              floatingLabelText={"Werk"}
              value={data.plant || ""}
              errorText={errors.plant}
              onChange={(e, v) =>
                this.handleChange("plant", v.toUpperCase())
              }
            />
            <TextField
              style={{ ...styles.inputfield, ...styles.name }}
              hintText="z.B. ML1"
              floatingLabelText={"Bezeichnung"}
              value={data.name || ""}
              onChange={(e, v) => this.handleChange("name", v.toUpperCase())}
            />
          </div>
        </div>

        <div style={styles.section}>
          <div style={styles.subheader}>
            <WorkloadIcon
              color={secondaryTextColor}
              style={styles.subheaderIcon}
            />
            <span style={styles.subheaderText}>Durchschnittsauslastung</span>
          </div>
          <div style={styles.workload}>
            <div style={styles.sliderWrapper}>
              <Slider
                sliderStyle={styles.slider}
                value={data.min_workload}
                onChange={this.handleWorkloadMinChange.bind(this)}
              />
            </div>
            <div style={styles.sliderWrapper}>
              <Slider
                sliderStyle={styles.slider}
                value={data.max_workload}
                onChange={this.handleWorkloadMaxChange.bind(this)}
              />
            </div>
            <div style={styles.track} />
            <div
              style={{
                ...styles.range,
                left: data.min_workload * 100 + "%",
                width: (data.max_workload - data.min_workload) * 100 + "%"
              }}
            />

            <div
              style={{
                ...styles.sliderValue,
                left: `calc(${data.min_workload * 100}% - ${
                  styles.sliderValue.width
                }px)`
              }}
            >
              {Math.round(data.min_workload * 100) + "%"}
            </div>
            <div
              style={{
                ...styles.sliderValue,
                left: `calc(${data.max_workload * 100}%)`
              }}
            >
              {Math.round(data.max_workload * 100) + "%"}
            </div>
          </div>
        </div>

        <div style={styles.section}>
          <div style={styles.subheader}>
            <InputIcon
              color={secondaryTextColor}
              style={styles.subheaderIcon}
            />
            <span style={styles.subheaderText}>{'Datenbasis'}</span>
          </div>

          <div>
            <SelectField
              floatingLabelText={"Plan"}
              style={{ ...styles.inputfield, ...styles.plan }}
              errorStyle={styles.errorStyle}
              value={selectedPlan}
              errorText={errors.line_key}
              onChange={(e, i, v) => {
                this.handleChangePlan(v);
              }}
            >
              {plans.map((el, i) => (
                <MenuItem
                  key={i}
                  style={styles.menuItem}
                  value={el}
                  primaryText={el.name}
                />
              ))}
            </SelectField>
            { data.line_key &&
              <Blacklist 
                blacklist={data.blacklist} 
                plan={temp_plan} 
                onOpen={ this.handleLoadTempPlan.bind(this) }
                onChange={this.handleChangeBlacklist.bind(this) }
              />
            }
          </div>

          { data.line_key && <React.Fragment>
            <div>
              <SelectField
                floatingLabelText={"Fahrzeugprogramm"}
                style={{ ...styles.inputfield, ...styles.plan }}
                errorStyle={styles.errorStyle}
                value={data.program_id}
                errorText={errors.program_id}
                onChange={(e, i, v) => this.handleChangeProgram(parseInt(v))}
              >
                {programs.map((p, i) => (
                  <MenuItem
                    key={p.id}
                    style={styles.menuItem}
                    value={p.id}
                    primaryText={ <React.Fragment>
                      {p.name}
                      {i===0 && <span style={styles.programDefaultLabel}>Default</span>}
                    </React.Fragment> }
                  />
                ))}
              </SelectField>
            </div>

            
            { data.program_id &&
              <List>
              { filter.map( f => 
                <ListItem 
                    key={ f.project }
                    primaryText={ <React.Fragment>
                      {f.project} 
                      <div style={styles.percentageTrack}>
                        <div style={{...styles.percentageBar, width:`${f.percentage}%`}}/>
                      </div>
                    </React.Fragment> }
                    leftIcon={ 
                      <Checkbox
                          style={ styles.checkbox }
                          iconStyle={ styles.checkboxIcon }
                          checked={ isProjectSelected(f) }
                          onCheck={ (e,v) => this.handleSelectProject(f.project) }
                          uncheckedIcon={ f.models.some(isModelSelected)
                              ? <IndeterminateCkeckboxIcon/> 
                              : <UncheckedCheckboxIcon/> 
                          }
                      />
                    }
                    primaryTogglesNestedList={false} 
                    // style={  } 
                    // innerDivStyle={ styles.listItemInnerDiv }
                    nestedItems={
                        f.models.map( m=> 
                            <ListItem 
                                key={ m.key }
                                primaryText={ <React.Fragment>
                                  {m.name }
                                  <div style={styles.percentageTrack}>
                                    <div style={{...styles.percentageBar, width:`${m.percentage}%`}}/>
                                  </div>
                                </React.Fragment> }
                                leftIcon={ 
                                  <Checkbox
                                    style={ styles.checkbox }
                                    iconStyle={ styles.checkboxIcon }
                                    checked={ isModelSelected(m) }
                                    onCheck={ (e,v) => this.handleSelectModel(m.key) }
                                  />
                                }
                                // style={  } 
                                // innerDivStyle={ styles.listItemInnerDiv }
                            >
                            
                            </ListItem>
                        )
                    }
                >
                </ListItem>
              )}
              </List> 
            }
            </React.Fragment> }
        </div>

        <div style={styles.section}>
          <div style={styles.subheader}>
            <StableIcon
              color={secondaryTextColor}
              style={styles.subheaderIcon}
            />
            <span style={styles.subheaderText}>Stabiler Arbeitsplatz</span>
          </div>
          <div style={styles.description}>
            <p>
                {'Die Berechnung, ob ein Arbeitsplatz stabil oder annähernd stabil ist, erfolgt auf Basis der hier in folgender Form festgelegten Bedingungen.'}
            </p>
            <p>{'[Kennzahl] [Zeichen] [Wert]; [Kennzahl] [Zeichen] [Wert]; ...'}</p>
            <p>
               {'[Kennzahl]: Avg | Min | Max | Zsp (Durchscnitts-, Min-, Max-Auslatung und Zeitspreizung)'}<br/>
               {'[Zeichen]: <  | > | <= | >= | ='}<br/>
               {'[Wert]: in Prozent'}
            </p>
            <p>
                {'Defaults: '}<br/>
                {'stabil: AVG >= 89%; AVG <= 100%; MAX <= 105%; ZSP <= 10%'}<br/>
                {'annähernd stabil: AVG >= 84%; AVG < 89%; ZSP <= 10%'}
            </p>
          </div>    
          <div>
            <TextField
                style={{ width:'100%' }}
                errorStyle={styles.errorStyle}
                floatingLabelText={"stabiler Arbeitsplatz"}
                value={data.stable_condition || ""}
                errorText={errors.stable_condition}
                onChange={(e, v) =>
                    this.handleChange("stable_condition", v)
                }
            />
          </div>
          <div>
            <TextField
                style={{ width:'100%' }}
                errorStyle={styles.errorStyle}
                floatingLabelText={"annähernd stabiler Arbeitsplatz"}
                value={data.partlyStable_condition || ""}
                errorText={errors.partlyStable_condition}
                onChange={(e, v) =>
                    this.handleChange("partlyStable_condition", v)
                }
            />
          </div>
        </div>

        <div style={styles.section}>
          <div style={styles.subheader}>
            <TrafficIcon
              color={secondaryTextColor}
              style={styles.subheaderIcon}
            />
            <span style={styles.subheaderText}>Kennzahlenprämissen</span>
          </div>
          <div style={styles.description}>
            <p>
                {'Kennzahlprämissen steuern die Ampelfarben der Kennzahlen in den Exporten. Die Prämissen werden als Zeichenketten in folgender Form angegeben:'}
            </p>
            <p>{'[Farbe] [Zeichen] [Wert] [Zeichen] [Farbe] [Zeichen] [Wert] [Zeichen] [Farbe] ...'}</p>
            <p>
               {'[Farbe]: Rot | Gelb | Grün | Hexcode'}<br/>
               {'[Zeichen]: <  | > | <= | >= | ='}<br/>
               {'[Wert]: in Prozent'}
            </p>
            <p>
                {'Defaults: '}<br/>
                {'Auslastung: ROT < 85% <= GELB < 89% <= GRÜN <= 100% < ROT'}<br/>
                {'Wertschöpfung: ROT < 49% <= GELB < 52% <= GRÜN'}<br/>
                {'Stabiler Arbeitsplatz: ROT < 39% <= GELB < 42% <= GRÜN'}
            </p>
          </div>    
          <div>
            <TextField
                style={{ width:'100%' }}
                errorStyle={styles.errorStyle}
                hintText="[#color] [operator] [value] [operator] [#color] [operator] [value] [operator] [#color]"
                floatingLabelText={"Auslastung"}
                value={data.workload_premises || ""}
                errorText={errors.workload_premises}
                onChange={(e, v) =>
                    this.handleChange("workload_premises", v)
                }
            />
          </div>
          <div>
            <TextField
                style={{ width:'100%' }}
                errorStyle={styles.errorStyle}
                hintText="[#color] [operator] [value] [operator] [#color] [operator] [value] [operator] [#color]"
                floatingLabelText={"Wertschöpfung"}
                value={data.valueAdded_premises || ""}
                errorText={errors.valueAdded_premises}
                onChange={(e, v) =>
                    this.handleChange("valueAdded_premises", v)
                }
            />
          </div>
          <div>
            <TextField
                style={{ width:'100%' }}
                errorStyle={styles.errorStyle}
                hintText="[#color] [operator] [value] [operator] [#color] [operator] [value] [operator] [#color]"
                floatingLabelText={"stabiler Arbeitsplatz"}
                value={data.stable_premises || ""}
                errorText={errors.stable_premises}
                onChange={(e, v) =>
                    this.handleChange("stable_premises", v)
                }
            />
          </div>

        </div>
        <DeleteDialog
            open={ deleteDialogOpen }
            title={ 'Möchtest du das Projekt wirklich löschen'}
            onCancel={ this.handleCancelDeleteProject.bind(this) }
            onConfirm={ this.handleDeleteProject.bind(this) }
        />
      </Panel>
    );
  }
}

// EXPORT
export default connect(store => ({
  data: store.project.current.data,
  plans: store.plan.list.items,
  temp_plan: store.plan.temp,
  programs: store.project.programs,
  filter: store.project.filter,
}))(
  muiThemeable()(ProjectConfig)
);
