const update = require('immutability-helper');

const storedFilter = localStorage.getItem('filter')

// DEFAULT
const defaultState = {
    pending:false,
    fulfilled:false,
    data: {},
    status: {},
    workload: {},
    operations: [],
    parts: [],
    screwcases: [],
    resources: [],
    images: [],
    comments: [],
    filter: storedFilter ? JSON.parse(storedFilter) : {
        part:[],
        screwcase:[],
        resource:[],
        operation:[]
    }
}

// REDUCER
const reducer = (state=defaultState, action) => {
    switch (action.type) {
        case 'LOAD_WORKSTATION_PENDING': {
            state = update(state, {
                pending: {$set: true},
                fulfilled: {$set: false},
            });
            break;
        }
        case 'LOAD_WORKSTATION_FULFILLED': {
            state = update(state, {$merge: action.payload})
            state = update(state, {
                pending: {$set: false},
                fulfilled: {$set: true},
            });
            break;
        }
        case 'LOAD_WORKSTATION_REJECTED': {
            state = update(state, {
                pending: {$set: false},
                fulfilled: {$set: false},
                data:{$set: {}},
            });
            break;
        }
        case 'CHANGE_POSITION': {
            const { workstationId, position } = action.payload
            if(state.data.id==workstationId){
                state = update(state, {
                    data: {position: {$set: position }},
                });
            }
            break;
        }
        case 'SET_WORKLOAD_STATUS': {
            const { status } = action.payload
            state = update(state, {
                workload: {
                    status: {$set: status }
                },
            });
            break;
        }
        case 'SET_PART_STATUS': {
            const { partId, status } = action.payload
            const index = state.parts.findIndex(p=> p.data.id==partId)
            state = update(state, {
                parts: {[index]: {
                    status: {$set: status }
                }},
            });
            break;
        }
        case 'SET_SCREWCASE_STATUS': {
            const { screwcaseId, status } = action.payload
            const index = state.screwcases.findIndex(s=> s.data.id==screwcaseId)
            state = update(state, {
                screwcases: {[index]: {
                    status: {$set: status }
                }},
            });
            break;
        }
        case 'SET_RESOURCE_STATUS': {
            const { resourceId, status } = action.payload
            const index = state.resources.findIndex(s=> s.data.id==resourceId)
            state = update(state, {
                resources: {[index]: {
                    status: {$set: status }
                }},
            });
            break;
        }
        case 'CREATE_COMMENT_FULFILLED': {
            const comment = action.payload
            state = update(state, {
                comments: {$push: [comment] }
            });
            break;
        }
        case 'CHANGE_COMMENT_FULFILLED': {
            const comment = action.payload
            const index = state.comments.findIndex(c=> c.id==comment.id)
            state = update(state, {
                comments: {$splice: [[index,1,comment]]},
            });
            break;
        }
        case 'DELETE_COMMENT_FULFILLED': {
            const { commentId } = action.payload
            const index = state.comments.findIndex(c=> c.id==commentId)
            state = update(state, {
                comments: {$splice: [[index,1]]},
            });
            break;
        }
        case 'UPLOAD_IMAGE_FULFILLED': {
            const image = action.payload
            state = update(state, {
                images: {$push: [image]},
            });
            break;
        }
        case 'DELETE_IMAGE_FULFILLED': {
            const { imageId } = action.payload
            const index = state.images.findIndex(img=> img.id==imageId)
            state = update(state, {
                images: {$splice: [[index,1]]},
            });
            break;
        }
        case 'FILTER_CHANGE': {
            state = update(state, {
                filter: {$merge: action.payload } 
            });
            localStorage.setItem('filter',JSON.stringify(state.filter))
            break;
        }
    }
    return state
}

// EXPORT
export default reducer