// DEPENDENCIES
import React from 'react';

import update from 'immutability-helper';

// MuiTheme
import muiThemeable from 'material-ui/styles/muiThemeable';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

// COMPONENTS
import CircularProgress from 'material-ui/CircularProgress';
import { Toolbar } from 'material-ui';
import { ToolbarGroup } from 'material-ui/Toolbar';
import { ToolbarTitle } from 'material-ui/Toolbar';


// COMPONENT

export const Panel = muiThemeable()((props) => {
    const { pending, title, children, actions, leftActions, width, style, contentStyle, muiTheme } = props
    const palette = muiTheme.palette;

    const styles = {
        panel:{ 
            display:'flex', flexDirection:'column',
            position:'relative',
            height:'calc(100% - 30px)', width:width || 800,
            margin:'15px 30px',
            backgroundColor:'white', border:'none', //border:'white solid 1px',
            overflow:'hidden',
            ...style
        },
        header: { 
            padding:'none', background:'none',
            padding:'0px 20px',
        },
        title: { fontSize:30, fontWeight:'bold', color:palette.textColor  },
        content:{
            flexGrow:1, overflowY:'auto',
            padding:'0px 20px 20px',
            ...contentStyle
        },
        actionWrapper:{ 
            display:'flex', justifyContent:'space-between', alignItems:'center',
            minHeight:50, padding:'0px 20px',
            borderTop:'#E9EDF0 solid 1px',
        },
        progress:{ 
            position:'absolute', top:0, bottom:0, right:0, left:0,
            display:'flex', justifyContent:'center', alignItems:'center',
            backgroundColor:'rgba(0,0,0,0)'
        }
    }
    return (
        <div style={ styles.panel}>
            { title && 
                <Toolbar style={ styles.header }>
                    <ToolbarGroup>
                        <ToolbarTitle text={ title } style={ styles.title }/>
                    </ToolbarGroup>
                </Toolbar>
            }
            <div style={ styles.content }>
            {
                children
            }
            </div>
            { (actions || leftActions) && 
                <div style={ styles.actionWrapper }>
                    <div>
                    {   
                        leftActions
                    }
                    </div>
                    <div>
                    {
                        actions
                    }
                    </div>
                </div>
            }
            {   pending && 
                <div style={ styles.progress }>
                    <CircularProgress size={80} thickness={5}/> 
                </div>
            }
        </div>
    )
})

export const SortableList = SortableContainer( props => <div style={ props.style } className={ props.className }>{ props.children }</div> );
export const SortableItem = SortableElement( props => <div style={ props.style } className={ props.className }>{ props.children }</div> );
export const DragHandle = SortableHandle( props => <div style={ props.style } className={ props.className }>{ props.children }</div>)