import { createUserManager } from 'redux-oidc';
import {WebStorageStateStore} from 'oidc-client';

const userManagerConfig = {
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    authority: process.env.REACT_APP_AUTH_URL,
    response_type: process.env.REACT_APP_AUTH_RESPONSE_TYPE,
    // responseType: process.env.REACT_APP_AUTH_RESPONSE_TYPE,
    // webAuthResponseType: process.env.REACT_APP_AUTH_RESPONSE_TYPE,
    scope: process.env.REACT_APP_AUTH_SCOPE,
    audience: "tmr_webui",
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URL,
    silent_redirect_uri: process.env.REACT_APP_AUTH_SILENT_REDIRECT_URL,
    post_logout_redirect_uri: process.env.REACT_APP_AUTH_LOGOFF_REDIRECT_URL,
    metadataUrl: process.env.REACT_APP_AUTH_METADATAURL,
    grantType: "password",
};

const userManager = createUserManager(userManagerConfig);

export default userManager;