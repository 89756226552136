// DEPENDENCIES
import merge from 'lodash.merge';

import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { redA400, blueGrey900, white } from 'material-ui/styles/colors';

import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';

const primary1Color = '#0068aa'//'#00BAFE' // '#17D0D9'
// AUDI CI
// const nioColor = '#bb0a30'
// const ioColor = '#009900'
// const nrColor = '#ffaa00'//'#EACC3C',
// const defaultColor = '#CACACA'
// const updateColor = '#0099da'//'#ffaa00',//'#00BAFE',
// REVENT
const nioColor = '#d42364' //'#EA306A'
const ioColor = '#19de9f' //'#21E98F' //'#51E8B8'
const nrColor = '#ffaa00'//'#EACC3C',s
const defaultColor = '#B4B7B9'//'#CACACA'
const updateColor = '#0068aa'//'#9c27b0'//'#0099da'//'#ffaa00',//'#00BAFE',
const diagramColors = {
    diagramBase:'#303a42',
    diagram1Color: '#e91e63',//pink
    diagram2Color: '#9c27b0',//purple
    // diagram3Color: '#673ab7',//deeppurple
    diagram3Color: '#3f51b5',//indigo
    diagram4Color: '#2196f3',//blue
    // diagram6Color: '#03a9f4',//lightblue
    diagram5Color: '#00bcd4',//cyan
    diagram6Color: '#009688',//teal
    // diagram9Color: '#4caf50',//green
    // diagram10Color: '#8CC34A',//light green
    diagram7Color: '#cddc39',//lime
    // diagram8Color: '#ffeb3b',//yellow
    diagram8Color: '#ffc107',//amber
    // diagram14Color: '#ff9800',//orange
    diagram9Color: '#ff5722',//deep orange
    
}

export const darkTheme = getMuiTheme( merge(darkBaseTheme, {
  theme: 'dark',
  fontFamily: 'Roboto, sans-serif',
  palette: {
    primary1Color,
    primary3Color: '#EA306A',
    textColor: white,
    secondaryTextColor: '#9e9e9e',

    lightgrey: '#E9EDF0',
    midgrey: '#B4B7B9',
    darkgrey: '#4A4A4A',

    updateColor,
    defaultColor,
    nrColor,
    ioColor,
    nioColor,

    ...diagramColors
  },
  subheader: {
    fontFamily: 'Roboto, sans-serif',
  },
  toolbar: {

  },
  menuItem: {
    frontSize:14
  },
  tooltip: {
    color: white,
    rippleBackgroundColor: 'rgba(255,255,255,.1)'
  },
}));

export const lightTheme = getMuiTheme ({
  theme: 'light',
  fontFamily: 'Roboto, sans-serif',
  palette: {
    primary1Color,
    primary3Color: '#EA306A',
    textColor: '#383838',

    lightgrey: '#E9EDF0',
    midgrey: '#B4B7B9',
    darkgrey: '#4A4A4A',

    updateColor,
    defaultColor,
    nrColor,
    ioColor,
    nioColor,

    ...diagramColors
  },
  subheader: {
    fontFamily: 'Roboto, sans-serif', 
  },
  toolbar: {

  },
  menuItem: {
    frontSize:14
  },
  tooltip: {
    color: white,
    rippleBackgroundColor: 'rgba(255,255,255,.1)'
  },
  slider: {
    // trackSize: 0,
    // handleFillColor: palette.primary1Color,
    // selectionColor: 'rgba(0,0,0,0)',
  }
});
