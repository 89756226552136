"use strict";

// DEPENDENCIES
import React from 'react';
import ReactDOM from 'react-dom';

import update from 'immutability-helper';
import muiThemeable from 'material-ui/styles/muiThemeable';

// COMPONENTS
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import Slider from 'material-ui/Slider';


// ICONS
import DeleteIcon from 'material-ui/svg-icons/action/delete';

// COMPONENT
class HeightDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = Object.assign({
            id:'',
            pid:'',
            values: new Array(10).fill(0)
        }, this.props.data)
    }

    componentWillReceiveProps(nextProps) {
        this.setState( update(this.state, {$merge: nextProps.data}) );
    }
    handleDelete() {
        this.props.onDelete(this.state.id)
    }
    handleSave() {
        this.props.onSave(this.state)
    }
    handleCancel() {
        this.props.onCancel()
    }

    handleChange(key, value) {
        this.setState( update(this.state, {
            [key]: { $set: value }
        }));
    }
    handleValueChange(index, value) {
        this.setState( update(this.state, {
            values:{ $splice: [[index,1,Math.min(250, Math.max(0,value))]] }
        }));
    }
    handleAllValueChange(value) {
        this.setState( update(this.state, {
            values: {$set: new Array(10).fill(value) } 
        }));
    }

    render() {
        const { muiTheme } = this.props
        const { values } = this.state
        const palette = muiTheme.palette
        const styles = {
            content:{ maxWidth:'880px' },
            body:{ },
            header: { padding:'none', background:'none', marginBottom:30 },
            title: { color:'#4A4A4A', fontSize:24, fontWeight:'bold' },
            values:{ position:'relative' },
            seperator:{ display:'inline-block', width:1, height:160, margin:'0px 30px', backgroundColor:palette.midgrey }
        }
        const actions = [
            <FlatButton
                label="Abbrechen"
                primary={true}
                onClick={this.handleCancel.bind(this)}
            />,
            <FlatButton
                label="Speichern"
                primary={true}
                onClick={this.handleSave.bind(this)}
            />
        ];
        const getAVGValue = () => Math.round( values.reduce( ( p, c ) => p + parseFloat(c), 0 ) / values.length )
    
        return (
            <Dialog 
                actions={actions} 
                modal={true} 
                open={this.props.open} 
                onRequestClose={this.handleCancel.bind(this)}
                contentStyle={ styles.content }
                bodyStyle={ styles.body }
            >
                <Toolbar style={ styles.header }>
                    <ToolbarGroup>
                        <TextField
                            value={ this.state.pid }
                            floatingLabelText={'PID/PR Bezug'}
                            onChange={ (e,v) => this.handleChange('pid',v) }
                        />
                    </ToolbarGroup>
                    <ToolbarGroup>
                        <IconButton onClick={this.handleDelete.bind(this)}>
                            <DeleteIcon/>
                        </IconButton>
                    </ToolbarGroup>
                </Toolbar>
                <div style={ styles.values }>
                    <ValueItem
                        name={ 'Alle' }
                        value={ getAVGValue() }
                        onChange={ (v) => this.handleAllValueChange(v) }
                    />
                    <div style={ styles.seperator }/>
                    {   values.map( (y,i) => 
                        <ValueItem 
                            key={ i } 
                            name={ 'x'+(i+1) }
                            value={ y }
                            onChange={ (v) => this.handleValueChange(i,v) }
                        />
                    )}
                </div>
            </Dialog>
        )
    }
};

const ValueItem = (props) => {
    const { name, value, onChange, style } = props
    const styles={
        wrapper:{ display:'inline-block', margin:'0px 10px', width:50, textAlign:'center' },
        slider:{ height:100 },
        input: { width:50 }
    }
    return (
        <div style={ styles.wrapper }>
            <Slider
                min={0}
                max={250}
                step={1}
                axis={'y'}
                style={ styles.slider }
                value={ parseFloat(value) || 0 }
                onChange={ (e,v) => onChange(v) }
            />
            <TextField
                type={'number'}
                value={ value }
                style={ styles.input } 
                floatingLabelText={ name }
                onChange={ (e,v) => onChange(v) }
            />
        </div>
    )
}

// EXPORT
export default muiThemeable()(HeightDialog);