import React from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { connect } from 'react-redux';

import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton';
import ErrorIcon from 'material-ui/svg-icons/alert/error';
import { MissingDataIcon } from '../assets/icons'
import PokayokeIcon from 'material-ui/svg-icons/action/compare-arrows';
import UpdateIcon from 'material-ui/svg-icons/av/loop';

const Warning = props => {
    const palette = props.muiTheme.palette
    const styles = {
        trigger:{ display:'inline-block', verticalAlign:'middle', height:46, width:46, padding:11 },
        info:{ width:'auto', maxWidth:'400px', padding:'5px 10px' },
    }
    const warning = props.warnings[props.type][props.code].warning || ''
    const getIcon = () => {
        switch (props.type) {
            case 'height':{
                switch (props.code) {
                    case 1: return <ErrorIcon color={ palette.primary1Color }/>
                    default: return  <ErrorIcon color={ palette.nioColor }/>
                }
            }
            case 'part': case 'screwcase': case 'resource':{
                switch (props.code) {
                    case 1: return <MissingDataIcon color={ palette.nioColor }/>
                    case 2: return <PokayokeIcon color={ palette.nioColor }/>
                    default: return  <ErrorIcon color={ palette.nioColor }/>
                }
            } 
        }
        
    }
    return (
        <IconMenu
            iconButtonElement={
                <IconButton style={ styles.trigger }>
                { getIcon() }
                </IconButton>
            }
            anchorOrigin={{horizontal: 'left', vertical: 'center'}}
            targetOrigin={{horizontal: 'right', vertical: 'center'}}
            menuStyle={ styles.info }
        >
            <div>{warning}</div>
        </IconMenu> 
    )
}

export default connect(store => ({
    warnings: store.app.configuration.warnings
}))(
    muiThemeable()(Warning)
);