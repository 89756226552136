// DEPENDENCIES
import React from 'react';

import muiThemeable from 'material-ui/styles/muiThemeable';
import update from 'immutability-helper';

// COMPONENTS
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'
import CircularProgress from 'material-ui/CircularProgress';
import IconButton from 'material-ui/IconButton';
import FlatButton from 'material-ui/FlatButton';
import BackIcon from 'material-ui/svg-icons/navigation/chevron-left';
import AutosizeInput from 'react-input-autosize';

// COMPONENT
class View extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            title: this.props.title,
            titleFocus: false
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState( update(this.state, {
            title: {$set: nextProps.title}
        }));
    }
    handleTitleInputKeypress(e) {
        if(e.key === 'Enter') e.target.blur()
    }
    handleTitleInputChange(e) {
        this.setState( update(this.state, {
            title: {$set: e.target.value}
        }));
    }
    handleTitleInputFocus(e) {
        this.setState( update(this.state, {
            titleFocus: {$set: true}
        }));
    }
    handleTitleInputBlur(e) {
        this.setState( update(this.state, {
            titleFocus: {$set: false}
        }));
        if(this.props.title != this.state.title ) this.props.onTitleChange(this.state.title)
    }
    render() {
        const { title, titleFocus } = this.state
        const { pending, fulfilled, subtitle, onTitleChange, leftActions, children, onNavigateBack, NavigateBackTooltip, rightActions, muiTheme }= this.props
        const palette = muiTheme.palette
        const styles = {
            view:{ position:'relative', height:'100%', width:'100%' },
            header:{ position:'relative', height:50 },
            toolbar:{ backgroundColor:'white', height:48, padding:'0px 30px' },
            content:{ 
                position:'relative', height:'calc(100% - 80px)', padding:'15px 30px', overFlow:'hidden', overflowY: "auto",
                display:'flex', flexDirection:'column', justifyContent:'flex-start', 
            },
            backBtn:{ marginLeft:'-24px', visibility:onNavigateBack ? 'visible' : 'hidden' },
            title: { fontSize:24, lineHeight:'normal', fontWeight:'bold', color:palette.textColor },
            subtitle: { fontSize:18, color:'grey' },
            input:{ 
                textAlign:'left', margin:0, paddingRight:20,
                fontFamily:'Roboto', color:palette.textColor, fontSize:24, fontWeight:'bold',
                border:'none', outline:'none',
                backgroundColor: titleFocus ? palette.lightgrey : 'white'
            },
            tooltip:{ backgroundColor:'white', color:'#303a42' },
            progress:{ 
                position:'absolute', top:0, bottom:0, right:0, left:0,
                display:'flex', justifyContent:'center', alignItems:'center',
                backgroundColor:'rgba(0,0,0,0)'
            },
        }
        return (
            <div style={styles.view}>
                <div style={styles.header}>
                    <Toolbar style={styles.toolbar}>
                    {   fulfilled && 
                        <React.Fragment>
                            <ToolbarGroup>
                                <IconButton 
                                    style={styles.backBtn}
                                    tooltip={NavigateBackTooltip||'Zurück'} tooltipPosition='bottom-center' tooltipStyles={ styles.tooltip } 
                                    onClick={onNavigateBack}
                                >
                                    <BackIcon/>
                                </IconButton>
                                    {   onTitleChange && 
                                        <AutosizeInput
                                            style={ styles.inputWrapper }
                                            inputStyle={ styles.input }
                                            value={title}
                                            onKeyPress={ this.handleTitleInputKeypress.bind(this) }
                                            onChange={ this.handleTitleInputChange.bind(this) }
                                            onFocus={ this.handleTitleInputFocus.bind(this) }
                                            onBlur={ this.handleTitleInputBlur.bind(this) }
                                        />
                                    }
                                    {   !onTitleChange &&
                                        <ToolbarTitle style={ styles.title } text={ title }/>
                                    }
                                    <div style={ styles.subtitle }>{subtitle}</div>
                                    {  
                                        leftActions
                                    }
    
                            </ToolbarGroup>
                            <ToolbarGroup>
                            {   
                                rightActions
                            }
                            </ToolbarGroup>
                        </React.Fragment>}
                    </Toolbar>
                </div>
                <div style={styles.content}>
                {   pending && 
                    <div style={ styles.progress }>
                        <CircularProgress size={60} thickness={4}/> 
                    </div>
                }
                {   fulfilled &&
                    children
                }
                </div> 
            </div> 
        )
    }
};

// EXPORT
export default muiThemeable()(View)
