"use strict";

// DEPENDENCIES
import React from 'react';

import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom'

import socket from "../socket";

// ACTIONS
import { act_LoadProject, act_UpdateSyncJobs, act_ResetStatus } from '../actions/projectActions'
import { act_LoadPlan } from '../actions/planActions'
import { act_LoadDashboards } from '../actions/dashboardActions'
import { act_SetNotifications } from "../actions/notificationActions";

// COMPONENTS
import Plan from './plan'
import Section from './section'
import Cycle from './cycle'
import Workstation from './workstation'
import Dashboard from './dashboard'
import Export from './export'
import CircularProgress from 'material-ui/CircularProgress';
import ProjectConfig from './projectConfig'

// ICONS

// COMPONENT
class Project extends React.Component {

    constructor() {
        super();
        this.socket = null
    }

    componentWillMount() {
        const projectId = this.props.match.params.id
        this.init(projectId)
    }
    componentWillReceiveProps(nextProps) {
        const currProjectId = this.props.match.params.id, nextProjectId = nextProps.match.params.id
        const currProjectJob = this.props.sync.jobs.find(j=>j.project_id === parseInt(currProjectId) && j.status === 'active')
        const nextProjectJob = nextProps.sync.jobs.find(j=>j.project_id === parseInt(nextProjectId)  && j.status === 'active')
        if(nextProjectId != currProjectId) return this.init(nextProjectId)
        if(currProjectJob && !nextProjectJob) return this.init(nextProjectId)
        if(!currProjectJob && nextProjectJob) {
            this.props.dispatch( act_ResetStatus() )
        } 
            
    }
    componentWillUnmount() {
        if(this.socket) { this.socket.close(); this.socket = null }
    }
    init(projectId) {
        this.props.dispatch( act_LoadProject(projectId) )
        this.props.dispatch( act_LoadPlan(projectId) )
        this.props.dispatch( act_LoadDashboards({projectId}) )
        
        if(this.socket) this.socket.close()
        this.socket = socket.connect(
            {
                userId: this.props.me.data.id, 
                projectId
            },
            {
                'notification': notifications => {
                    console.log("RECIEVED NOTIFICATIONS: ", notifications);
                    this.props.dispatch(act_SetNotifications(notifications));
                },
                'sync': jobs => {
                    this.props.dispatch(act_UpdateSyncJobs(jobs));
                }
            }
        );
    }

    render() {
        const { project, plan, dashboards, me }= this.props
        const styles = {
            progress:{ 
                position:'absolute', top:0, bottom:0, right:0, left:0,
                display:'flex', justifyContent:'center', alignItems:'center',
                backgroundColor:'rgba(0,0,0,0)'
            },
            sync_overlay: {
                position:'absolute',
                display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center',
                top:0,bottom:0,left:0,right:0,
                backgroundColor:'rgba(0,0,0,.2)'
            },
            sync_label:{
                fontSize:20, fontWeight:500, color:'white'
            }
        }
        return (
            <React.Fragment>
            {   plan.fulfilled && project.fulfilled &&
                <React.Fragment>
                    <Route exact path='/projects/:id/plan' component={Plan}/>
                    <Route exact path='/projects/:id/plan/sections/:id' component={Section}/>
                    <Route exact path='/projects/:id/plan/sections/:id/cycles/:id' component={Cycle}/>
                    <Route exact path='/projects/:id/plan/sections/:id/cycles/:id/workstations/:id' component={Workstation}/>
                </React.Fragment>
            }
            {   plan.fulfilled && project.fulfilled && dashboards.fulfilled &&
                <Route path='/projects/:id/dashboards/:id' component={Dashboard}/>
            }
            {   project.fulfilled &&
                <React.Fragment>
                    {   me.isAdmin &&
                        <Route path='/projects/:id/config' component={ProjectConfig}/>
                    }
                    <Route path='/projects/:id/exports/:id' component={Export}/>
                </React.Fragment>
            }
            {   (plan.pending || project.pending) && 
                <div style={ styles.progress }>
                    <CircularProgress size={80} thickness={5}/> 
                </div>
            }
            {/* {   project.syncProgress < 100 &&
                <div style={styles.sync_overlay}>
                    <div style={styles.sync_label}>{`Der Plan wird aktualisiert: ${project.syncProgress}%`}</div>
                </div>
            } */}
            </React.Fragment>
        )

    }
};
// broker-user-stack-dbsharedcluster-i21ckcz1x8vk.cluster-cxbzrffixdhr.eu-central-1.rds.amazonaws.com
// broker-user-stack-dbsharedcluster-i21ckcz1x8vk.cluster-cxbzrffixdhr.eu-central-1.rds.amazonaws.com

// EXPORT
export default connect(store => ({
    project: store.project.current,
    sync: store.project.sync,
    plan: store.plan.current,
    dashboards: store.dashboard.list,
    user: store.oidc.user,
    me: store.user.me
}))(
    Project
);