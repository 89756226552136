"use strict";

// DEPENDENCIES
import React from 'react';
import ReactDOM from 'react-dom';
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { darkTheme } from "../theme";

// COMPONENTS
import CircularProgress from 'material-ui/CircularProgress';

// STYLES
const styles = {
   container: {
       position:'absolute',
       top:0, right:0, bottom:0, left:0,
       backgroundColor:'rgb(26, 26, 26)',
       zIndex:9999
   },
   progress:{
       position:'absolute',
       top:0, right:0, bottom:0, left:0,
       margin:'auto'
   }
}

// COMPONENT
class splash extends React.Component {

    constructor() {
        super();
    }

    render() {
        return (
            <MuiThemeProvider muiTheme={darkTheme}>
                <div style={styles.container}>
                    <CircularProgress size={80} thickness={5} style={styles.progress}/>
                </div>
            </MuiThemeProvider>
        )
    }
};

// EXPORT
export default splash;