import React from 'react';

import update from 'immutability-helper';
import muiThemeable from 'material-ui/styles/muiThemeable';

// LOGIC

// COMPONENTS
import { UpdateItem } from './status';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import Subheader from 'material-ui/Subheader';
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'

// COMPONENT
class ScrewcaseStatusDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: this.props.screwcase.status
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState( update(this.state, {
            status: {$set: nextProps.screwcase.status},
        }));
    }

    handleSave() {
        this.props.onSave(this.props.screwcase.data.id, this.state.status)
    }
    handleCancel() {
        this.props.onCancel()
    }

    handleChange(value) {
        if(value==this.state.status) value=-1;
        this.setState( update(this.state, {
            status: { $set: value }
        }));
    }

    render() {
        const { screwcase, muiTheme } = this.props
        const palette = muiTheme.palette
        const styleSets = { 
            inline: { display:'inline-block', verticalAlign:'middle' }
        }
        const primTextColor = this.props.muiTheme.palette.textColor;
        const secTextColor = this.props.muiTheme.palette.secondaryTextColor;
        const styles = {
            root: { textAlign:'center' },
            content: { display:'inline-block', width:'auto', maxWidth:'none' },
            body:{},
            header: { padding:'none', background:'none' },
            title: { color:'#4A4A4A', fontSize:24, fontWeight:'bold' },
            status:{ 
                ...styleSets.inline,
                height:45, width:65, minWidth:65,
                marginLeft:3,
                color:'white', fontSize:14, 
                textAlign:'center', 
                backgroundColor:palette.defaultColor,
                borderRadius:0
            },
            statusLabel: { textTransform:'none', fontWeight:'normal' },
            nio:{ backgroundColor:palette.nioColor },
            io:{ backgroundColor:palette.ioColor },
            box:{  },
            updateBox: { marginTop:50, textAlign:'left' },
            subheader: { lineHeight:'20px', paddingLeft:5, margin:'10px 0px', color:secTextColor, borderBottom:`${secTextColor} solid 1px` }
        }
        const getStyle = (status) => {
            const val = this.state.status;
            const baseStyle = styles.status;
            let stateStyle = {};
            switch (status) {
                case 0: {if(val==0){ stateStyle = styles.nio }; break }
                case 1: {if(val==1){ stateStyle = styles.io }; break }
            }
            return { ...baseStyle, ...stateStyle}
        }
        const actions = [
            <FlatButton
                label="Abbrechen"
                primary={true}
                onClick={this.handleCancel.bind(this)}
            />,
            <FlatButton
                label="Speichern"
                primary={true}
                onClick={this.handleSave.bind(this)}
            />
        ];
        return (
            <Dialog 
                actions={actions} 
                modal={false} 
                open={this.props.open} 
                onRequestClose={this.handleCancel.bind(this)}
                style={ styles.root }
                contentStyle={ styles.content }

                bodyStyle={ styles.body }
            >
                <Toolbar style={ styles.header }>
                    <ToolbarGroup>
                        <ToolbarTitle 
                            style={ styles.title } 
                            text={ (screwcase.data) ? 'Schraubfall: ' + screwcase.data.number : '' }
                        />
                    </ToolbarGroup>
                    <ToolbarGroup>
                          <div>
                            <FlatButton label={'n.i.O.'} style={ getStyle(0) } labelStyle={ styles.statusLabel } onClick={ () => this.handleChange(0) }/>
                            <FlatButton label={'i.O.'} style={ getStyle(1) } labelStyle={ styles.statusLabel } onClick={ () => this.handleChange(1) }/>
                        </div>  
                    </ToolbarGroup>
                </Toolbar>
                <div style={ styles.box }>
                {   screwcase.changelog && 
                    <div style={ styles.updateBox }> 
                        <Subheader style={ styles.subheader }>Änderungen seit dem letzten Speichern</Subheader>
                        {   
                            screwcase.changelog.map( (c,i) => 
                                <UpdateItem key={i} name={ c.key } prev={ c.prevValue } curr={ screwcase.data[c.key] } color={palette.updateColor}/>  
                            )
                        }
                    </div>
                }
                </div>
            </Dialog>
        )
    }
};

// EXPORT
export default muiThemeable()(ScrewcaseStatusDialog);