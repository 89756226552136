
// DEPENDENCIES
import React from 'react';

import { connect } from 'react-redux';
import update from 'immutability-helper';
import _ from 'lodash'

// MuiTheme
import muiThemeable from 'material-ui/styles/muiThemeable';

// ACTIONS
import { act_SaveSettings } from '../actions/settingsActions.js'

// COMPONENTS
import { Panel } from './helper.js';
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'
import FlatButton from 'material-ui/FlatButton'
import SelectField from 'material-ui/SelectField';
import MenuItem  from 'material-ui/MenuItem';
import Checkbox from 'material-ui/Checkbox';


// COMPONENT
class Settings extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.props.settings
    }

    componentWillReceiveProps(nextProps) {
        this.setState( update(this.state, {$merge: nextProps.settings} ) );
    }
    handleChange(key,value) {
        this.setState( update(this.state, {
            [key]:{ $set: value }
        }));
    }
    handleSave() {
        const userId = this.props.me.id, settings = this.state
        this.props.dispatch( act_SaveSettings(settings) )
    }

    render() {
        const settings = this.state
        const { muiTheme } = this.props
        const palette = muiTheme.palette
        const styles = {
            panel: { margin:'15px 30px'},
            header: { 
                marginBottom:15, padding:'none', background:'none' 
            },
        }
        const actions = [
            <FlatButton
                key={0}
                label="Speichern"
                primary={true}
                onClick={this.handleSave.bind(this)}
                disabled={ _.isEqual(settings, this.props.settings) }
            />
        ]
        return (
            <Panel width={600} actions={ actions } title={ 'Einstellungen' }>
                <div>
                    <SelectField
                        floatingLabelText="Sprache"
                        style={ styles.input }
                        value={ settings.language }
                        onChange={ (e,k,v) => this.handleChange('language',v) }
                    >
                    {   [
                        {key:'de', name:'Deutsch'},
                        {key:'nl', name:'Niederländisch'},
                        {key:'fr', name:'Französisch'},
                        {key:'es', name:'Spanisch'},
                        {key:'hu', name:'Ungarisch'}
                    ].map( l => 
                        <MenuItem key={l.key} value={l.key} primaryText={l.name}/>
                    )}
                    </SelectField>
                    {/* <Checkbox
                        style={ styles.checkbox }
                        iconStyle={ styles.checkboxIcon }
                        label={'Angepasser Modus für Farbenblinde (Rot-Grün-Sehschwäche) aktivieren'}
                        checked={ settings.red_green_color_blindness }
                        onCheck={ (e,v) => this.handleChange('red_green_color_blindness',v) }
                    /> */}
                </div>
            </Panel>
        )
    }
};


// EXPORT
export default connect(store => ({
    me: store.user.me.data,
    settings: store.user.me.settings
}))(
    muiThemeable()(Settings)
);