"use strict";

// DEPENDENCIES
import update from 'immutability-helper';
import merge from 'lodash.merge';

// DEFAULT
const defaultState = { 
    pending:false,
    fulfilled:false,
    data:{}
}

// REDUCER
const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'LOAD_SECTION_PENDING': {
            state = update(state, {
                pending: {$set: true},
                fulfilled: {$set: false},
            });
            break;
        }
        case 'LOAD_SECTION_FULFILLED': {
            state = update(state, {
                pending: {$set: false},
                fulfilled: {$set: true},
                data: {$set: action.payload}
            });
            break;
        }
        case 'LOAD_SECTION_REJECTED': {
            state = update(state, {
                pending: {$set: false},
                fulfilled: {$set: false},
            });
            break;
        }
    }
    return state
}

// EXPORT
export default reducer;