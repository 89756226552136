"use strict";

// DEPENDENCIES from 
import { Observable } from 'rxjs'
import api from '../api';

// ACTIONS
import { act_HandleError} from '../actions/appActions';


// -----------------------------------------------------
// EPICS
export const epc_Search = (action$, store) => {
    return action$.ofType('SEARCH')
        .mergeMap( action => {
            const { projectId, query } = action.payload
            const params = { projectId } 
            const data = { query: escapeQuery(query) }
            return api.search({params,data})
                .mergeMap( payload => [
                    {type:'SEARCH_FULFILLED', payload}
                ])
                .catch( err => [ {type:'SEARCH_REJECTED'}, act_HandleError(err) ])
                .startWith( {type:'SEARCH_PENDING'} ) 
        })
}
       
const escapeQuery = (query) => {
    query = query.toLowerCase();
    query = query.replace(/ä/g, 'a');
    query = query.replace(/ö/g, 'o');
    query = query.replace(/ü/g, 'u');
    query = query.replace(/ß/g, 'ss');
    return query
}