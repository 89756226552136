"use strict";

// DEPENDENCIES
import React from 'react';
import { connect } from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { 
    FlexibleWidthXYPlot,
    XAxis, YAxis, HorizontalGridLines,
    LineMarkSeries,
    Hint
} from 'react-vis'

// ACTIONS
import { act_LoadCycle, act_CreateHeight, act_UpdateHeight, act_DeleteHeight, act_EditHeight, act_CancelEditHeight } from '../actions/cycleActions'
import { act_ChangePosition } from '../actions/workstationActions'

// COMPONENTS
import View from './view'
import StatusSelector from './statusSelector'
import CycleItem from './cycleItem'
import HeightDialog from './heightDialog'
import ExpansionPanel from './expansionPanel';
import Menu from 'material-ui/Menu';
import MenuItem  from 'material-ui/MenuItem';

// ICONS
import LegendIcon from 'material-ui/svg-icons/av/stop';

// COMPONENT
class Cycle extends React.Component {

    constructor() {
        super();
        this.state = {
        }
    }
    componentWillMount() {
        const cycleId = this.props.match.params.id
        this.init(cycleId)
    }
    componentWillReceiveProps(nextProps) {
        const currCycleId = this.props.match.params.id, nextCycleId = nextProps.match.params.id
        if(nextCycleId != currCycleId) this.init(nextCycleId )
    }
    init(cycleId) {
        const projectId = this.props.project.id
        this.props.dispatch( act_LoadCycle({projectId,cycleId}) )
    }
    handleSelectWorkstation(workstationId) {
        this.props.history.push(`${this.props.match.url}/workstations/${workstationId}`)
    }
    handleChangePosition(wId, pos) {
        const projectId = this.props.project.id, workstationId = wId
        this.props.dispatch( act_ChangePosition({projectId, workstationId, position: pos}) )
    }
    handleEditHeight(heightId) {
        this.props.dispatch( act_EditHeight({heightId}) )
    }
    handleCreateHeight() {
        const projectId = this.props.project.id, cycleId = this.props.match.params.id
        this.props.dispatch( act_CreateHeight({projectId,cycleId}) )
    }
    handleSaveHeight(height) {
        const projectId = this.props.project.id, cycleId = this.props.match.params.id, heightId = height.id
        this.props.dispatch( act_UpdateHeight({projectId,cycleId, heightId, height}) )
    }
    handleDeleteHeight(heightId) {
        const projectId = this.props.project.id, cycleId = this.props.match.params.id
        this.props.dispatch( act_DeleteHeight({projectId,cycleId, heightId}) )
    }
    handleCancelHeight() {
        this.props.dispatch( act_CancelEditHeight() )
    }
    handleGoBack(){
        let url = this.props.match.url
        url = url.replace(/\/cycles.*$/,'')
        this.props.history.push(url)
    }

    render() {
        const { pending, fulfilled, editHeight, data, heights, workstations, project, notifications, mapStatus, muiTheme }= this.props
        const palette = muiTheme.palette
        const styles = {
            expansion: { marginTop:15 },
            expansionBar: { backgroundColor:'rgba(255,255,255,.4', paddingLeft:20, paddingRight:10 },
            expansionPanel: { backgroundColor:'white', padding:20 },
            cycleItem:{ display:'block', marginBottom:45 },
            chart:{ position:'relative', display:'inline-block', verticalAlign:'top', height:300, width:'calc(100% - 300px)', marginRight:30},
            menu:{ display:'inline-block', verticalAlign:'top', textAlign:'left' },
            menuList:{ width:270 },
            menuItem:{ width:'100%', minHeight:30, lineHeight:'30px', fontSize:14 },
            menuItemInnerDiv:{ paddingLeft:40 },
            menuItemIcon:{ margin:'5px 12px', height:20, width:'auto' },
        }
        return (
            <View
                pending={pending}
                fulfilled={fulfilled}
                title={ `${data.number} - ${data.name}` } 
                rightActions={<StatusSelector/>}
                onNavigateBack={this.handleGoBack.bind(this)}
                NavigateBackTooltip={ 'Zum Bandabschnitt'}
            >
                <CycleItem
                    key={data.id}
                    style={ styles.cycleItem }
                    cycle={ data }
                    workstations={ workstations }
                    mapStatus={mapStatus}
                    onSelectWorkstation={ this.handleSelectWorkstation.bind(this) }
                    onPositionChange={ this.handleChangePosition.bind(this) } 
                    notifications={notifications}
                />
                <ExpansionPanel 
                    open={true} 
                    title={'Arbeitshöhe'} 
                    style={ styles.expansion } 
                    barStyle={ styles.expansionBar } 
                    panelStyle={ styles.expansionPanel }
                    fullBarTrigger
                >
                    <div>
                        <div style={ styles.chart }>
                            <FlexibleWidthXYPlot height={300} animation colorType="literal" xDomain={[0,9]} yDomain={[0,250]}>
                                <XAxis tickFormat={v => 'x'+(v+1)} tickValues={[0,1,2,3,4,5,6,7,8,9]}/>
                                <YAxis/>
                                <HorizontalGridLines/>
                                {   heights.map( (h,i) =>
                                    <LineMarkSeries 
                                        key={i} 
                                        data={ h.values.map((v,i)=>( {x:i, y:parseFloat(v)} )) }
                                        color={ palette[`diagram${i+1}Color`]}
                                    />
                                )}
                            </FlexibleWidthXYPlot>
                        </div>
                        <Menu style={ styles.menu } listStyle={ styles.menuList} autoWidth={false}>
                        {   heights.map( (h,i) =>
                            <MenuItem
                                key={ h.id }
                                style={ styles.menuItem }
                                innerDivStyle={ styles.menuItemInnerDiv }
                                leftIcon={ <LegendIcon style={ styles.menuItemIcon } color={ palette[`diagram${i+1}Color`]}/> }
                                onClick={ () => this.handleEditHeight(h.id) }
                                primaryText={ h.pid }
                            />
                        )}
                            <MenuItem
                                key={ 'add' }
                                style={ styles.menuItem }
                                innerDivStyle={ styles.menuItemInnerDiv }
                                onClick={ this.handleCreateHeight.bind(this) }
                                primaryText={ '+ hinzufügen' }
                            />
                        </Menu>
                    </div>
                </ExpansionPanel>

                <HeightDialog
                    open={ editHeight!=null }
                    data={ heights.find( h=> h.id==editHeight ) || {} }
                    onSave={ this.handleSaveHeight.bind(this) }
                    onDelete={ this.handleDeleteHeight.bind(this) }
                    onCancel={ this.handleCancelHeight.bind(this) }
                />
            </View>
        )

    }
};

// EXPORT
export default connect(store => ({
    pending: store.cycle.pending,
    fulfilled: store.cycle.fulfilled,
    data: store.cycle.data, 
    heights: store.cycle.heights,
    editHeight: store.cycle.editHeight,
    project: store.project.current.data,
    workstations: store.plan.current.workstations.filter( w => w.parentId==store.cycle.data.id ),
    notifications: store.notification.list,
    mapStatus: store.app.mapStatus,
}))(
    muiThemeable()(Cycle)
);