// DEPENDENCIES
import React from 'react';
import { connect } from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';

// ACTIONS
import { act_LoadSection } from '../actions/sectionActions'
import { act_Subscribe, act_UnSubscribe } from '../actions/userActions'

// COMPONENTS
import View from './view'
import StatusSelector from './statusSelector'
import CycleItem from './cycleItem'
import Toggle from 'material-ui/Toggle';
import Checkbox from 'material-ui/Checkbox';

import NotificationsIcon from 'material-ui/svg-icons/social/notifications-active'
import NotificationsOffIcon from 'material-ui/svg-icons/social/notifications-off'
import IconButton from 'material-ui/IconButton';

// COMPONENT
class Section extends React.Component {

    constructor() {
        super();
    }
    
    componentWillMount() {
        const sectionId = this.props.match.params.id
        this.init(sectionId)
    }
    componentWillReceiveProps(nextProps) {
        const currSectionId = this.props.match.params.id, nextSectionId = nextProps.match.params.id
        if(nextSectionId != currSectionId) this.init(nextSectionId )
    }
    init(sectionId) {
        const projectId = this.props.project.id
        this.props.dispatch( act_LoadSection({projectId,sectionId}) )
    }

    handleSubscribeSection(e,val) {
        const projectId = this.props.project.id, sectionId = this.props.match.params.id
        if(val) this.props.dispatch( act_Subscribe({projectId, sectionId}) )
        else this.props.dispatch( act_UnSubscribe({projectId, sectionId}) )
    }
    handleSelectCycle(cycleId) {
        this.props.history.push(`${this.props.match.url}/cycles/${cycleId}`)
    }
    handleSelectWorkstation(cycleId, workstationId) {
        this.props.history.push(`${this.props.match.url}/cycles/${cycleId}/workstations/${workstationId}`)
    }
    handleGoBack(){
        let url = this.props.match.url
        url = url.replace(/\/sections.*$/,'')
        this.props.history.push(url)
    }

    render() {
        const { pending, fulfilled, data, cycles, workstations, notifications, project, me, mapStatus, muiTheme }= this.props
        const palette = muiTheme.palette
        const styles = {
            checkbox:{ width:'auto'},
            toggle:{ width:200},
            toggleTrackOff:{ backgroundColor:'lightgrey'},
            toggleLabel: { fontSize:12, whiteSpace:'nowrap' },
            tooltip:{ backgroundColor:'white', color:palette.textColor },
            section:{ fontSize:0 },
        }
        const isSubscribed = (me.subscriptions.findIndex(s=>s.projectId==project.id && s.sectionId==data.id) > -1)
        
        return (
            <View
                pending={pending}
                fulfilled={fulfilled}
                title={ data.name }
                leftActions={
                    // <Checkbox
                    //     style={styles.checkbox}
                    //     checked={ isSubscribed }
                    //     onCheck={this.handleSubscribeSection.bind(this)}
                    //     checkedIcon={<NotificationsIcon color={palette.midgrey}/>}
                    //     uncheckedIcon={<NotificationsOffIcon color={palette.lightgrey}/>}
                    //     tooltip={isSubscribed ? 'Benachrichtigungen aktiv' : 'Benachrichtigungen aktiv'} tooltipPosition='bottom-center' tooltipStyles={ styles.tooltip } 
                    // />
                    <IconButton 
                        tooltip={isSubscribed ? 'Benachrichtigungen aktiv' : 'Benachrichtigungen inaktiv'} tooltipPosition='bottom-center' tooltipStyles={ styles.tooltip } 
                        onClick={ (e) => this.handleSubscribeSection(e,!isSubscribed)}
                    >
                    {   isSubscribed && <NotificationsIcon color={palette.textColor}/>}
                    {   !isSubscribed && <NotificationsOffIcon color={palette.midgrey}/>}
                    </IconButton>
                }
                rightActions={<StatusSelector/>}
                onNavigateBack={this.handleGoBack.bind(this)}
                NavigateBackTooltip={ 'Zum Plan'}
            >
            <div style={ styles.section }>
                {   
                    cycles.filter(c =>c.parentId===data.id).map( c=>
                        <CycleItem
                            key={c.id}
                            style={{opacity:c.visible?1:.3}}
                            min
                            cycle={c}
                            workstations={ workstations.filter( w => w.parentId ==c.id) }
                            mapStatus={mapStatus}
                            onSelectCycle={ this.handleSelectCycle.bind(this) }
                            onSelectWorkstation={ (workstationId) => this.handleSelectWorkstation(c.id,workstationId) }
                            notifications={notifications}
                        />
                    )
                }
            </div>
            </View>
        )
    }
};

// EXPORT
export default connect(store => ({
    pending: store.plan.current.pending,
    fulfilled: store.plan.current.fulfilled,
    data: store.section.data,
    cycles: store.plan.current.cycles,
    workstations: store.plan.current.workstations,
    project: store.project.current.data,
    me: store.user.me,
    notifications: store.notification.list,
    mapStatus: store.app.mapStatus,
}))(
    muiThemeable()(Section)
);