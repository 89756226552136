export const act_LoadChartConfig = (payload) => ({ type:'LOAD_CHART_CONFIG', payload })

export const act_LoadDashboards = (payload) => ({ type:'LOAD_DASHBOARDS', payload })

export const act_LoadDashboard = (payload) => ({ type:'LOAD_DASHBOARD', payload })

export const act_ChangeDashboardPosition = (payload) => ({ type:'CHANGE_DASHBOARD_POSITION', payload })

export const act_CreateDashboard = (payload) => ({ type:'CREATE_DASHBOARD', payload})

export const act_DeleteDashboard = (payload) => ({ type:'DELETE_DASHBOARD', payload})

export const act_UpdateDashboardData = (payload) => ({ type:'UPDATE_DASHBOARD_DATA', payload})

export const act_ChangeDashboardBlacklist = (payload) => ({ type:'CHANGE_DASHBOARD_BLACKLIST', payload})

export const act_DuplicateDashboard = (payload) => ({ type:'DUPLICATE_DASHBOARD', payload})

export const act_AddChart = (payload) => ({ type:'ADD_CHART', payload})

export const act_DeleteChart = (payload) => ({ type:'DELETE_CHART', payload})

export const act_ChangeChartBlacklist = (payload) => ({ type:'CHANGE_CHART_BLACKLIST', payload})

export const act_ChangeChartSize = (payload) => ({ type:'CHANGE_CHART_SIZE', payload})

export const act_ChangeChartPosition = (payload) => ({ type:'CHANGE_CHART_POSITION', payload})

export const act_LoadChartData = (payload) => ({ type:'LOAD_CHART_DATA', payload})



