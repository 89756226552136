
import { Observable } from 'rxjs';

// ACTIONS
import { act_LoadConfiguration, act_HandleError, act_PushError } from '../actions/appActions';
import { act_LoadMe } from '../actions/userActions';
import { act_LoadProjects, act_LoadSyncJobs } from '../actions/projectActions';
import { act_LoadPlans } from '../actions/planActions';
import { act_LoadChartConfig } from '../actions/dashboardActions';
import api from '../api';

// -----------------------------------------------------
// EPICS

export const epc_InitApp = (action$) => {
    return action$.ofType('redux-oidc/USER_FOUND')
        .mergeMap( action => 
            Observable.concat(
                // DISPATCH ACTIONS
                Observable.of( 
                    act_LoadMe(),
                    act_LoadPlans(),
                    act_LoadProjects(),
                    act_LoadSyncJobs(),
                    act_LoadChartConfig(),
                    act_LoadConfiguration()
                ),
                // WAITING FOR FULFILLED
                Observable.combineLatest(
                    Observable.timer(1),
                    // action$.ofType('LOAD_ME_FULFILLED'),
                    action$.ofType('LOAD_PLANS_FULFILLED'),
                    action$.ofType('LOAD_PROJECTS_FULFILLED'),
                    action$.ofType('LOAD_SYNCJOBS_FULFILLED'),
                    action$.ofType('LOAD_CHART_CONFIG_FULFILLED'),
                    action$.ofType('LOAD_CONFIGURATION_FULFILLED'),
                ).mergeMap( () => [
                    {type:'INIT_APP_FULFILLED'}
                ])
            )
        ).catch(error=>[
            act_HandleError(error)
        ])
}

export const epc_LoadConfiguration = (action$, store) => {
    return action$.ofType("LOAD_CONFIGURATION")
        .mergeMap(action => api.get_configuration()
            .mergeMap(payload => [
            { type: "LOAD_CONFIGURATION_FULFILLED", payload }
            ])
            .catch(err => [
            { type: "LOAD_CONFIGURATION_REJECTED" },
            act_HandleError(err)
            ])
            .startWith({ type: "LOAD_CONFIGURATION_PENDING" })
        );
  };
  

export const epc_HandleError = (action$,store) => {
    return action$.ofType('HANDLE_ERROR')
        .mergeMap(action => {
            // let tasks = []
            // if( typeof(action.payload)=='object' ) {
            //     tasks.push( act_PushError(action.payload.message) )
            //     switch (action.payload.code) {
            //         case 0: { tasks.push( push('/settings') ); break; } // no db-path
            //         case 1: { tasks.push( push('/settings') ); break; } // db not connectable
            //         case 2: { tasks.push( push('/user') ); break; } // user not registrated
            //     }
            // } else tasks.push( act_PushError(action.payload) )
            // return tasks
            return []
        })
}

       