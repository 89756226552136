import { Observable } from "rxjs";

import api from "../api";

// ACTIONS
import { act_HandleError } from "../actions/appActions";
import { act_LoadChartData, act_LoadDashboard } from "../actions/dashboardActions";

// -----------------------------------------------------
// EPICS

export const epc_LoadChartConfig = (action$, store) => {
  return action$.ofType("LOAD_CHART_CONFIG").mergeMap(action => {
    return api.get_charts()
      .mergeMap(payload => [
        {
          type: "LOAD_CHART_CONFIG_FULFILLED",
          payload
        }
      ])
      .catch(err => [
        { type: "LOAD_CHART_CONFIG_REJECTED" },
        act_HandleError(err)
      ])
      .startWith({ type: "LOAD_CHART_CONFIG_PENDING" });
  });
};

export const epc_LoadDashboards = (action$, store) => {
  return action$.ofType("LOAD_DASHBOARDS").mergeMap(action => {
    const { projectId } = action.payload;
    if(projectId==='new') return [{type:'LOAD_DASHBOARDS_REJECTED'}]
    else return api.get_dashboards({ params: { projectId } })
      .mergeMap(payload => [
        {
          type: "LOAD_DASHBOARDS_FULFILLED",
          payload
        }
      ])
      .catch(err => [
        { type: "LOAD_DASHBOARDS_REJECTED" },
        act_HandleError(err)
      ])
      .startWith({ type: "LOAD_DASHBOARDS_PENDING" });
  });
};

export const epc_LoadDashboard = (action$, store) => {
  return action$.ofType("LOAD_DASHBOARD").mergeMap(action => {
    const { projectId, dashboardId } = action.payload;
    return api
      .get_dashboard({ params: { projectId, dashboardId } })
      .mergeMap(({dashboard, blacklist, items}) => {
        let batch = [{ type: "LOAD_DASHBOARD_FULFILLED", payload: {dashboard, blacklist, items} }];
        batch = batch.concat(
            items.map(itm =>
                act_LoadChartData({
                    projectId,
                    dashboardItemId: itm.id,
                    chartId: itm.chart_id,
                    blacklist_id: dashboard.blacklistActive ? dashboard.blacklist_id : itm.blacklist_id
                })
            )
        );
        return batch;
      })
      .catch(err => [{ type: "LOAD_DASHBOARD_REJECTED" }, act_HandleError(err)])
      .startWith({ type: "LOAD_DASHBOARD_PENDING" });
  });
};

export const epc_CreateDashboard = (action$, store) => {
  return action$.ofType("CREATE_DASHBOARD").mergeMap(action => {
    const { projectId, name = "Neues Dashboard" } = action.payload;
    return api
      .create_dashboard({ params: { projectId }, data: { name } })
      .mergeMap(payload => [{ type: "CREATE_DASHBOARD_FULFILLED", payload }])
      .catch(err => [
        { type: "CREATE_DASHBOARD_REJECTED" },
        act_HandleError(err)
      ])
      .startWith({ type: "CREATE_DASHBOARD_PENDING" });
  });
};

export const epc_UpdateDashboardData = (action$, store) => {
  return action$.ofType("UPDATE_DASHBOARD_DATA").mergeMap(action => {
    const current = store.getState().dashboard.current
    const { projectId, dashboardId, data } = action.payload;
    return api
        .update_dashboard_data({ params: { projectId, dashboardId }, data })
        .mergeMap(payload => {
            let batch = [ {type: "UPDATE_DASHBOARD_DATA_FULFILLED", payload} ];
            if(current.data.blacklistActive !== payload.blacklistActive || current.data.blacklist_id !== payload.blacklist_id){
                batch = batch.concat(
                    current.items.map(itm =>
                        act_LoadChartData({
                            projectId,
                            dashboardItemId: itm.id,
                            chartId: itm.chart_id,
                            blacklist_id: payload.blacklistActive ? payload.blacklist_id : itm.blacklist_id
                        })
                    )
                );
            }
            return batch;  
        })
        .catch(err => [
            { type: "UPDATE_DASHBOARD_DATA_REJECTED" },
            act_HandleError(err)
        ])
        .startWith({ type: "UPDATE_DASHBOARD_DATA_PENDING" });
  });
};

export const epc_DeleteDashboard = (action$, store) => {
  return action$.ofType("DELETE_DASHBOARD").mergeMap(action => {
    const { projectId, dashboardId } = action.payload;
    return api
      .delete_dashboard({ params: { projectId, dashboardId } })
      .mergeMap(payload => [{ type: "DELETE_DASHBOARD_FULFILLED", payload }])
      .catch(err => [
        { type: "DELETE_DASHBOARD_REJECTED" },
        act_HandleError(err)
      ])
      .startWith({ type: "DELETE_DASHBOARD_PENDING" });
  });
};

export const epc_ChangeDashboardBlacklist = (action$, store) => {
    return action$.ofType("CHANGE_DASHBOARD_BLACKLIST").mergeMap(action => {
      const { projectId, dashboardId, blacklist } = action.payload;
      const current = store.getState().dashboard.current

      const params = { projectId, dashboardId };
      const data = {blacklist}
      return api
        .update_dashboard_blacklist({ params, data })
        .mergeMap(payload => {
            const {dashboard,blacklist} = payload
            let batch = [ { type: "CHANGE_DASHBOARD_BLACKLIST_FULFILLED", payload } ];
            if(current.data.blacklistActive !== dashboard.blacklistActive || current.data.blacklist_id !== dashboard.blacklist_id){
                batch = batch.concat(
                    current.items.map(itm =>
                        act_LoadChartData({
                            projectId,
                            dashboardItemId: itm.id,
                            chartId: itm.chart_id,
                            blacklist_id: dashboard.blacklistActive ? dashboard.blacklist_id : itm.blacklist_id
                        })
                    )
                );
            }
            return batch;  
        })
        .catch(err => [
          { type: "CHANGE_DASHBOARD_BLACKLIST_REJECTED" },
          act_HandleError(err)
        ])
        .startWith({ type: "CHANGE_DASHBOARD_BLACKLIST_PENDING" });
    });
};

export const epc_DuplicateDashboard = (action$, store) => {
    return action$.ofType("DUPLICATE_DASHBOARD").mergeMap(action => {
      const { projectId, dashboardId } = action.payload;
      return api
        .duplicate_dashboard({ params: { projectId, dashboardId } })
        .mergeMap(payload => [{ type: "DUPLICATE_DASHBOARD_FULFILLED", payload }])
        .catch(err => [
          { type: "DUPLICATE_DASHBOARD_REJECTED" },
          act_HandleError(err)
        ])
        .startWith({ type: "DUPLICATE_DASHBOARD_PENDING" });
    });
  };

export const epc_ChangeDashboardPosition = (action$, store) => {
  return action$.ofType("CHANGE_DASHBOARD_POSITION").mergeMap(action => {
    const { projectId, dashboardId, position } = action.payload;
    const params = { projectId, dashboardId };
    const data = { position };
    return api.update_dashboard_position({ params, data })
      .mergeMap(payload => [
        { type: "CHANGE_DASHBOARD_POSITION_FULFILLED", payload }
      ])
      .catch(err => [
        { type: "CHANGE_DASHBOARD_POSITION_REJECTED" },
        act_HandleError(err)
      ])
      .startWith({ type: "CHANGE_DASHBOARD_POSITION_PENDING" });
  });
};

export const epc_AddChart = (action$, store) => {
  return action$.ofType("ADD_CHART").mergeMap(action => {
    const { projectId, dashboardId, chartId, size } = action.payload;
    const current = store.getState().dashboard.current
    
    const params = { projectId, dashboardId };
    const data = { chart_id: chartId, size };
    return api
      .create_dashboard_item({ params, data })
      .mergeMap(payload => [
        { type: "ADD_CHART_FULFILLED", payload },
        act_LoadChartData({
          projectId,
          dashboardItemId: payload.id,
          chartId: payload.chart_id,
          blacklist_id: current.data.blacklistActive ? current.data.blacklist_id : payload.blacklist_id
        })
      ])
      .catch(err => [{ type: "ADD_CHART_REJECTED" }, act_HandleError(err)])
      .startWith({ type: "ADD_CHART_PENDING" });
  });
};

export const epc_ChangeChartBlacklist = (action$, store) => {
  return action$.ofType("CHANGE_CHART_BLACKLIST").mergeMap(action => {
    const { projectId, dashboardId, dashboardItemId, blacklist } = action.payload;
    const params = { projectId, dashboardId, dashboardItemId };
    const data = {blacklist}
    return api
      .update_dashboard_item_context({ params, data })
      .mergeMap(payload => [
        { type: "CHANGE_CHART_BLACKLIST_FULFILLED", payload },
        act_LoadChartData({
          projectId,
          dashboardItemId,
          chartId: payload.chart_id,
          blacklist_id: payload.blacklist_id
        })
      ])
      .catch(err => [
        { type: "CHANGE_CHART_BLACKLIST_REJECTED" },
        act_HandleError(err)
      ])
      .startWith({ type: "CHANGE_CHART_BLACKLIST_PENDING" });
  });
};

export const epc_ChangeChartSize = (action$, store) => {
  return action$.ofType("CHANGE_CHART_SIZE").mergeMap(action => {
    const { projectId, dashboardId, dashboardItemId, size } = action.payload;
    const params = { projectId, dashboardId, dashboardItemId };
    const data = { size };
    return api
      .update_dashboard_item_size({ params, data })
      .mergeMap(payload => [{ type: "CHANGE_CHART_SIZE_FULFILLED", payload }])
      .catch(err => [
        { type: "CHANGE_CHART_SIZE_REJECTED" },
        act_HandleError(err)
      ])
      .startWith({ type: "CHANGE_CHART_SIZE_PENDING" });
  });
};

export const epc_ChangeChartPosition = (action$, store) => {
  return action$.ofType("CHANGE_CHART_POSITION").mergeMap(action => {
    const {
      projectId,
      dashboardId,
      dashboardItemId,
      position
    } = action.payload;
    const params = { projectId, dashboardId, dashboardItemId };
    const data = { position };
    return api
      .update_dashboard_item_position({ params, data })
      .mergeMap(payload => [
        { type: "CHANGE_CHART_POSITION_FULFILLED", payload }
      ])
      .catch(err => [
        { type: "CHANGE_CHART_POSITION_REJECTED" },
        act_HandleError(err)
      ])
      .startWith({ type: "CHANGE_CHART_POSITION_PENDING" });
  });
};

export const epc_DeleteChart = (action$, store) => {
  return action$.ofType("DELETE_CHART").mergeMap(action => {
    const { projectId, dashboardId, dashboardItemId } = action.payload;
    const params = { projectId, dashboardId, dashboardItemId };
    return api
      .delete_dashboard_item({ params })
      .mergeMap(payload => [
        { type: "DELETE_CHART_FULFILLED", payload: { dashboardItemId } }
      ])
      .catch(err => [{ type: "DELETE_CHART_REJECTED" }, act_HandleError(err)])
      .startWith({ type: "DELETE_CHART_PENDING" });
  });
};

export const epc_LoadChartData = (action$, store) => {
  return action$.ofType("LOAD_CHART_DATA").mergeMap(action => {
    const { projectId, dashboardItemId, chartId, blacklist_id } = action.payload;
    const params = { projectId, chartId };
    let data = {blacklist_id};
    return api
      .get_chart_data({ params, data })
      .mergeMap(payload => [
        {
          type: "LOAD_CHART_DATA_FULFILLED",
          payload: { dashboardItemId, ...payload }
        }
      ])
      .catch(err => [
        { type: "LOAD_CHART_DATA_REJECTED", payload: { dashboardItemId } },
        act_HandleError(err)
      ])
      .startWith({
        type: "LOAD_CHART_DATA_PENDING",
        payload: { dashboardItemId }
      });
  });
};
