import { combineReducers } from 'redux'

import { reducer as oidc } from 'redux-oidc';

import app from './appReducer';

// import settings from './settingsReducer';
import user from './userReducer';
import project from './projectReducer';
import plan from './planReducer';
import section from  './sectionReducer'
import cycle from './cycleReducer'
import workstation from './workstationReducer'
import dashboard from './dashboardReducer'
import notification from './notificationReducer';


  
export default combineReducers({
    oidc, app, user, project, plan, section, cycle, workstation, dashboard, notification
})