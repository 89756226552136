import React from 'react'

// DEPENDENCIES
import { connect } from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';

// ACTIONS


// COMPONENTS
import { List, ListItem } from 'material-ui/List';
import IconButton from 'material-ui/IconButton';
import AddIcon from 'material-ui/svg-icons/content/add-circle-outline';
import {ReactComponent as AppIcon} from '../assets/AppIcon.svg';
// import { AppIcon } from '../assets/icons'

// COMPONENT
class Home extends React.Component {

    constructor() {
        super();
    }

    componentDidMount() {
        
    }

    render() {
        const { muiTheme } = this.props
        const palette = muiTheme.palette
        const styles = {
            wrapper: { 
                display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',
                width:'100%', height:'80%', 
            },
            label:{
                fontFamily:'audiowide', fontSize:30, frontWeight:500, color:'#000205', marginBottom:20
            },
            icon:{
                height:70, width:70
            }
        }
        return (
            <div style={styles.wrapper}>
                <div style={styles.label}>TM-Reporter</div>
                <AppIcon style={styles.icon}/>
            </div> 
        )

    }
};

// EXPORT
export default
    connect(store => ({store: store}))(
        muiThemeable()(Home)
    );