"use strict";

// DEPENDENCIES
import React from 'react';

import update from 'immutability-helper';

// MUI-THEME
import muiThemeable from 'material-ui/styles/muiThemeable';

// COMPONENTS
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';
import {Popover, PopoverAnimationVertical} from 'material-ui/Popover'
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from 'material-ui/Checkbox';

import SortIcon from 'material-ui/svg-icons/av/sort-by-alpha';
import CancelIcon from 'material-ui/svg-icons/navigation/cancel';

class ItemList extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            currentFilter: null,
            sort:null,
            filter: this.props.columns.filter( c=> c.filter ).map( c=> Object.assign({
                key:c.key,
                search:'',
                whitelist:null
            },(this.props.filter || []).find(f=>f.key==c.key)))
        }

    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.filter!=this.props.filter){
            this.setState( update(this.state, { 
                filter: {$set: nextProps.columns.filter( c=> c.filter ).map( c=> Object.assign({
                    key:c.key,
                    search:'',
                    whitelist:null
                },(nextProps.filter || []).find(f=>f.key==c.key))) }
            }));
        }
    }
    handleFocusInput(e) {
        e.target.style.backgroundColor = 'rgba(0,0,0,.2)'
    }
    handleBlurInput(e) {
        e.target.style.backgroundColor = 'rgba(0,0,0,0)'
    }
    handleFilterOpen(key) {
        this.setState( update(this.state, { 
            currentFilter: {$set: key }
        }));
    }
    handleSort(prop) {
        this.setState( update(this.state, { 
            sort: {$set: prop }
        }));
    }
    handleFilterChange(data) {
        const index = this.state.filter.findIndex( f => f.key == this.state.currentFilter)
        let filter = update(this.state.filter, { 
            [index]: {$merge: data }
        })
        if(this.props.onFilterChange) this.props.onFilterChange(filter)
        else this.setState( update(this.state, { 
            filter: {$set: filter }
        }));
    }
    handleFilterClose() {
        this.setState( update(this.state, { 
            currentFilter: {$set: null }
        }));
    }
    render() {
        const { identifier, height, width, rowHeight, onChange, updates, updateColor } = this.props
        const { currentFilter, sort, filter } = this.state
        const palette = this.props.muiTheme.palette
        const limit = 300
        const styles = {
            wrapper:{height:'100%'},
            table:{ 
                height: (height) ? `calc(${height} - 40px)` : `calc(100% - 40px)`,
                width: width || '100%',
            },
            header:{ border:'none' },
            headerRow:{ border:'none', height:40, padding:0 },
            headerCell:{ overflow:'hidden', padding:'0px 10px', height:30, cursor:'pointer' },
            headerCellFiltered:{
                color:palette.primary1Color, fontWeight:'bold'
            },
            headerCellTrigger:{ position:'absolute', top:0, left:0, right:0, bottom:0 },
            body:{ backgroundColor:'white' },
            row: { height: rowHeight || 50, padding:0, border:'none', borderTop:this.props.muiTheme.palette.lightgrey + ' solid 1px', cursor:'pointer' },
            rowSelected:{ backgroundColor:'rgb(245,245,245)' },
            cell: { position:'relative', height: rowHeight || 50, padding:'0px 10px', fontSize:13 },
            input: { 
                position:'absolute', top:0, left:0,
                width:'100%',
                lineHeight: (rowHeight || 50) + 'px', textAlign:'left', padding:'0px 10px',
                fontFamily:'Roboto', color:palette.textColor, fontSize:13,
                border:'none', outline:'none', backgroundColor:'rgba(0,0,0,0)',
                transition:'200ms'
            },
            updateBadge:{ position:'absolute', bottom:0, left:1, right:1, height:5, backgroundColor:updateColor || palette.updateColor || palette.primary1Color },
            filterMenu:{ maxWidth:400 }
        }
        const isColumnFiltered = (prop) => {
            const f = this.state.filter.find(f=>f.key==prop); 
            if(f) return (
                sort == f.key ||
                f.search.length>0 ||
                f.whitelist
            )
        } 
        const getHeaderCellStyle = (prop) => isColumnFiltered(prop) ? {...styles.headerCell, ...styles.headerCellFiltered} : styles.headerCell
        const getRowStyle = (id) => (this.props.selected && id==this.props.selected) ? { ...styles.row, ...styles.rowSelected } : styles.row
        const getFilteredRows = (options={}) => {
            const { exeptFilter } = options
            const filtered = this.props.rows.filter( (el,i) => {
                let out = false
                this.state.filter.filter(f=>f.key!==exeptFilter).every( f => {
                    if(el[f.key] && typeof(el[f.key])=='object'){
                        out = f.whitelist ? !!f.whitelist.find(b=>b.key==el[f.key].key) : false
                    } else {
                        out = (
                            (f.whitelist ? f.whitelist.indexOf(el[f.key])===-1 : false) || 
                            !(String(el[f.key]).indexOf(f.search)>-1)
                        )
                    } return !(out)
                })
                return !out
            })
            return (sort) 
                ? 
                filtered.sort( (a,b) => {
                    const A = (a[sort] && typeof(a[sort])=='object') ? a[sort].key : a[sort]
                    const B = (b[sort] && typeof(b[sort])=='object') ? b[sort].key : b[sort]
                    return (A > B) ? 1 : ((B > A) ? -1 : 0) 
                }) 
                : filtered
        }
        const hasUpdates = (id, key) => updates && updates.find(u=>u.id===id && u.key===key)!=undefined
        return (
            <>
                <Table height={ styles.table.height } width={ styles.table.width } wrapperStyle={styles.wrapper} selectable={false} fixedHeader>
                    <TableHeader displaySelectAll={false} adjustForCheckbox={false} style={ styles.header }>
                        <TableRow style={ styles.headerRow }>
                            {   this.props.columns.map( (c,i) =>
                                <TableHeaderColumn key={i} style={ {...getHeaderCellStyle(c.key), ...c.style} }>
                                    <div id={ c.key } >{ c.title }</div>
                                    {   c.filter && 
                                        <div ref={ c.key } style={ styles.headerCellTrigger } onClick={ () => c.filter ? this.handleFilterOpen(c.key) : null }/>
                                    }
                                </TableHeaderColumn>
                            )}
                        </TableRow>
                    </TableHeader>
                    <TableBody style={ styles.body } displayRowCheckbox={false} showRowHover={true}>
                    {   getFilteredRows().slice(0,limit).map( r => 
                        <TableRow key={r[identifier]} style={ getRowStyle(r[identifier]) } onClick={ () => (this.props.onSelect) ? this.props.onSelect(r[identifier]) : null }>
                        {   this.props.columns.map( (c,i) =>
                            <TableRowColumn key={i} style={ {...styles.cell, ...c.style} }>
                            {   (c.editable) ?
                                <input
                                    type={ c.type||'text' }
                                    style={ styles.input }
                                    value={ r[c.key] }
                                    onChange={ (e) => onChange(r[identifier],c.key, e.target.value)}
                                    onFocus={ this.handleFocusInput.bind(this) }
                                    onBlur={ this.handleBlurInput.bind(this) }
                                />
                                :
                                c.type==='number'?parseFloat(r[c.key]).toLocaleString('de-De',{maximumFractionDigits:4}):r[c.key]
                            }
                            {   hasUpdates(r[identifier],c.key) &&
                                <div style={ styles.updateBadge }/>
                            }
                            </TableRowColumn>
                        )}
                        </TableRow>
                    )} 
                    </TableBody>
                </Table>

                <Popover
                    open={ (currentFilter) ? true : false }
                    anchorEl={ this.refs[currentFilter] }
                    anchorOrigin={{horizontal: 'left', vertical: 'top'}}
                    targetOrigin={{horizontal: 'left', vertical: 'top'}}
                    onRequestClose={ this.handleFilterClose.bind(this) }
                    animation={ PopoverAnimationVertical }
                >
                {   currentFilter &&
                    <Menu style={ styles.filterMenu }>
                        <FilterMenuItems
                            muiTheme={ this.props.muiTheme }
                            items={ getFilteredRows({exeptFilter:currentFilter}) }
        
                            sort={ sort == currentFilter }
                            filter={ filter.find(f=>f.key==currentFilter) } 
        
                            onSortChange={ this.handleSort.bind(this) }
                            onFilterChange={ this.handleFilterChange.bind(this) }
                        />
                    </Menu>
                }   
                </Popover>
            </>
        )
    }
};


// COMPONENT
class FilterMenuItems extends React.Component {

    constructor() {
        super();
        this.state = {
            searchValue:''
        }
    }
    handleSort() {
        this.props.onSortChange( (!this.props.sort) ? this.props.filter.key : null )
    }
    handleSearchChange(v) {
        this.props.onFilterChange( {search:v} )
    }
    handleCheckItem(el,v) {
        const options = this.getOptions()
        let whitelist = this.props.filter.whitelist 
            ? this.props.filter.whitelist.slice()
            : options.map(o=>o.key||o)
        const index = whitelist.indexOf(el)
        if(!(index>-1) && v==true) whitelist.push(el)
        if(index>-1 && v==false) whitelist.splice(index,1)
        if(whitelist.length===this.getOptions().length) whitelist = null
        this.props.onFilterChange({whitelist})
    }
    handleCheckAll(v) {
        let whitelist;
        if(v==true) whitelist = null
        else whitelist = []
        this.props.onFilterChange({whitelist})
    }
    handleRequestClose() {
        this.props.onClose()
    }

    getOptions() {
        let options = [];
        this.props.items.map(el=>el[this.props.filter.key]).forEach( el => {
            if(typeof(el)=='object' && el !=null){
                if(!options.find(o=>o.key==el.key)) options.push(el)
            } else {
                if(options.indexOf(el)==-1) options.push(el)
            }
        })
        options.sort( (a,b) => {
            let p = (a && typeof(a)=='object') ? a.key : a, 
                c = (b && typeof(b)=='object') ? b.key : b  
            if (p > c) return 1
            if (p < c) return -1
            return 0;
        })
        return options//.filter( el=> String(el).indexOf(this.props.filter.search)>-1 )
    }

    render() {
        const palette = this.props.muiTheme.palette
        const limit = 50
        const styles = {
            menuItem:{ fontSize:14, minHeight:38, lineHeight:'38px', maxWidth:'400px' },
            menuItemLabel:{ overflow:'hidden', textOverflow:'ellipsis' },
            divider:{ width:'100%', height:'1px', backgroundColor:palette.lightgrey },
            sortItem:{ 
                color:(this.props.sort) ? 'white' : palette.textColor,
                backgroundColor:(this.props.sort) ? palette.primary1Color : 'rgba(255,255,255,0)'
            },
            sortIcon:{ 
                margin:'7px 12px',
                fill:(this.props.sort) ? 'white' : palette.textColor
            },
            searchWrapper:{ position:'relative'},
            search:{
                display:'inline-block',
                padding:'12px 18px', width:'calc(100% - 36px)',
                fontFamily:'Roboto', fontSize:14, color:palette.textColor,
                outline:'none', border:'none', background:'none'
            },
            cancel:{ position:'absolute', top:0, bottom:0, right:12, cursor:'pointer', margin:'auto' },
            list:{ margin:'8px 0px', maxHeight:'400px', overflowY:'auto' },
            checkbox:{ margin:'7px 12px'},
            checkIcon:{  }
        }
        const getItemLabel = (el) => {
            if(typeof(el)=='object' && el!=null) {
                return (
                    <div style={{display:'flex', alignItems:'center', pointerEvents:'none'}}>
                        { el }
                        <div style={{paddingLeft:15}}>{el.props.name}</div>
                    </div>
                )
            } else return el 
        }

        return (
            <div>
                <MenuItem 
                    primaryText={ (this.props.sort) ? 'Sortierung aufheben' : 'Sortieren' }
                    leftIcon={ <SortIcon style={ styles.sortIcon }/> }
                    style={ styles.menuItem } innerDivStyle={ styles.sortItem }
                    onClick={ this.handleSort.bind(this) }
                />

                <div style={ styles.divider }/>

                <div style={ styles.searchWrapper }>
                    <input
                        type={'text'} 
                        style={ styles.search } 
                        placeholder={ 'Suchen' } 
                        value={ this.props.filter.search }
                        onChange={ (e) => this.handleSearchChange(e.target.value) }
                    />
                    {   this.props.filter.search.length>0 &&
                        <CancelIcon style={ styles.cancel} onClick={ () => this.handleSearchChange('') }/>
                    }
                </div>

                <div style={ styles.divider }/>
                
                <MenuItem 
                    primaryText={ 'Alle' }
                    style={ styles.menuItem }
                    leftIcon={ 
                        <Checkbox
                            style={ styles.checkbox }
                            iconStyle={ styles.checkIcon }
                            checked={ !this.props.filter.whitelist }
                            onCheck={ (e,v) => this.handleCheckAll(v) }
                        />
                    }
                />

                <div style={ styles.divider }/>

                <div style={ styles.list }>
                {
                    this.getOptions().slice(0,limit).map( (el,i) => 
                        <MenuItem 
                            key={i}
                            style={ styles.menuItem }
                            primaryText={ <div style={ styles.menuItemLabel }>{ getItemLabel(el) }</div> }
                            leftIcon={ 
                                <Checkbox
                                    style={ styles.checkbox }
                                    iconStyle={ styles.checkIcon }
                                    checked={ !this.props.filter.whitelist || this.props.filter.whitelist.indexOf(el)>-1 }
                                    onCheck={ (e,v) => this.handleCheckItem(el,v) }
                                />
                            }
                        />
                    )
                }
                </div>
            </div>
        )
    }
};

// EXPORT
export default muiThemeable()( ItemList )