"use strict";

// Dependencies
import { Observable } from 'rxjs';

import api from '../api'

// ACTIONS
import { act_PushError, act_HandleError } from '../actions/appActions';

// -----------------------------------------------------
// EPICS
export const epc_LoadMe = (action$, store) => {
    return action$.ofType('LOAD_ME')
        .mergeMap( action => api.get_me()
            .mergeMap( payload => [
                {type:'LOAD_ME_FULFILLED', payload}
            ])
            .catch( err => [ {type:'LOAD_ME_REJECTED'}, act_HandleError(err) ])
        )
}

export const epc_SaveUser = (action$, store) => {
    return action$.ofType('SAVE_USER')
        .mergeMap( action => {
            const { userId, data } = action.payload
            return api.update_user_data({params:{userId}, data})
                .mergeMap( payload => [
                    {type:'SAVE_USER_FULFILLED', payload}
                ])
                .catch( err => [ {type:'SAVE_USER_REJECTED'}, act_HandleError(err) ])
        })
}

// export const epc_SaveSettings = (action$, store) => {
//     return action$.ofType('SAVE_SETTINGS')
//         .mergeMap( action => {
//             const { userId, settings } = action.payload
//             return api.update_user_settings({params:{userId}, data:settings})
//                 .mergeMap( payload => [
//                     {type:'SAVE_SETTINGS_FULFILLED', payload}
//                 ])
//                 .catch( err => [ {type:'SAVE_SETTINGS_REJECTED'}, act_HandleError(err) ])
//         })
// }

export const epc_Subscribe = (action$, store) => {
    return action$.ofType('SUBSCRIBE')
        .mergeMap((action) => {
            const { projectId, sectionId } = action.payload
            return api.subscribe_to_section({params:{projectId,sectionId}})
                .mergeMap( payload => [{type:'SUBSCRIBE_FULFILLED', payload}])
                .catch( error => [act_HandleError(error), { type:'SUBSCRIBE_REJECTED' }])
                .startWith( {type:'SUBSCRIBE_PENDING'} )
        })
}
export const epc_UnSubscribe = (action$, store) => {
    return action$.ofType('UNSUBSCRIBE')
        .mergeMap((action) => {
            const { projectId, sectionId } = action.payload
            return api.unsubscribe_from_section({params:{projectId,sectionId}})
                .mergeMap( payload => [{type:'UNSUBSCRIBE_FULFILLED', payload}])
                .catch( error => [act_HandleError(error), { type:'UNSUBSCRIBE_REJECTED' }])
                .startWith( {type:'UNSUBSCRIBE_PENDING'} )
        })
}
