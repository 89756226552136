"use strict";

// DEPENDENCIES
import { Observable } from 'rxjs';

import api from '../api'

// ACTIONS
import { act_HandleError } from '../actions/appActions';
import { act_ToggleTreeItem } from '../actions/planActions'


// -----------------------------------------------------
// EPICS
export const epc_LoadWorkstation = (action$, store) => {
    return action$.ofType('LOAD_WORKSTATION')
        .mergeMap( action => {
            const { projectId, workstationId } = action.payload
            return api.get_workstation({params:{projectId, workstationId}})
                .mergeMap( payload => [
                    { type:'LOAD_WORKSTATION_FULFILLED', payload },
                    act_ToggleTreeItem({id:workstationId,type:'workstation',open:true}) 
                ])
                .catch( error => [act_HandleError(error)])
                .startWith( {type:'LOAD_WORKSTATION_PENDING'} )
        })
}

export const epc_SetWorkloadStatus = (action$, store) => {
    return action$.ofType('SET_WORKLOAD_STATUS')
        .mergeMap( action => {
            const { projectId, workstationId, status } = action.payload
            return api.update_workload_status({params:{projectId, workstationId},data:{status}})
                .mergeMap( payload => [
                    { type:'SET_WORKLOAD_STATUS_FULFILLED' },
                    { type:'LOAD_WORKSTATION_FULFILLED', payload },
                ])
                .catch( error => [act_HandleError(error), {type:'SET_WORKLOAD_STATUS_REJECTED'}])
                .startWith( {type:'SET_WORKLOAD_STATUS_PENDING'} )
        })
}

export const epc_SetPartStatus = (action$, store) => {
    return action$.ofType('SET_PART_STATUS')
        .mergeMap( action => {
            const { projectId, workstationId, partId, status } = action.payload
            return api.update_part_status({params:{projectId, workstationId, partId},data:status})
                .mergeMap( payload => [
                    { type:'SET_PART_STATUS_FULFILLED' },
                    { type:'LOAD_WORKSTATION_FULFILLED', payload },
                ])
                .catch( error => [act_HandleError(error), {type:'SET_PART_STATUS_REJECTED'}])
                .startWith( {type:'SET_PART_STATUS_PENDING'} )
        })
}

export const epc_SetScrewcaseStatus = (action$, store) => {
    return action$.ofType('SET_SCREWCASE_STATUS')
        .mergeMap( action => {
            const { projectId, workstationId, screwcaseId, status } = action.payload
            return api.update_screwcase_status({params:{projectId, workstationId, screwcaseId},data:{status}})
                .mergeMap( payload => [
                    { type:'SET_SCREWCASE_STATUS_FULFILLED' },
                    { type:'LOAD_WORKSTATION_FULFILLED', payload }
                ])
                .catch( error => [act_HandleError(error), {type:'SET_SCREWCASE_STATUS_REJECTED'}])
                .startWith( {type:'SET_SCREWCASE_STATUS_PENDING'} )
        })
}

export const epc_SetResourceStatus = (action$, store) => {
    return action$.ofType('SET_RESOURCE_STATUS')
        .mergeMap( action => {
            const { projectId, workstationId, resourceId, status } = action.payload
            return api.update_resource_status({params:{projectId, workstationId,resourceId},data:{status}})
                .mergeMap( payload => [
                    { type:'SET_RESOURCE_STATUS_FULFILLED' },
                    { type:'LOAD_WORKSTATION_FULFILLED', payload }
                ])
                .catch( error => [act_HandleError(error), {type:'SET_RESOURCE_STATUS_REJECTED'}])
                .startWith( {type:'SET_RESOURCE_STATUS_PENDING'} )
        })
}

export const epc_UploadImage = (action$, store) => {
    return action$.ofType('UPLOAD_IMAGE')
        .mergeMap( action => {
            const { projectId, workstationId, imageData } = action.payload
            return api.upload_image({params:{projectId, workstationId},data:imageData})
                .mergeMap( payload => [
                    { type:'UPLOAD_IMAGE_FULFILLED', payload },
                    // { type:'LOAD_WORKSTATION_FULFILLED', payload },
                ])
                .catch( error => [act_HandleError(error), {type:'UPLOAD_IMAGE_REJECTED'}])
                .startWith( {type:'UPLOAD_IMAGE_PENDING'} )
        })
}

export const epc_DeleteImage = (action$, store) => {
    return action$.ofType('DELETE_IMAGE')
    .mergeMap( action => {
        const { projectId, workstationId, imageId } = action.payload
        return api.delete_image({params:{projectId, workstationId, imageId}})
            .mergeMap( payload => [
                { type:'DELETE_IMAGE_FULFILLED', payload: { imageId } },
                // { type:'LOAD_WORKSTATION_FULFILLED', payload },
            ])
            .catch( error => [act_HandleError(error), {type:'DELETE_IMAGE_REJECTED'}])
            .startWith( {type:'DELETE_IMAGE_PENDING'} )
    })
}

export const epc_CreateComment = (action$, store) => {
    return action$.ofType('CREATE_COMMENT')
        .mergeMap( action => {
            const { projectId, workstationId, scope } = action.payload
            return api.create_comment({params:{projectId, workstationId},data:{scope, text:''}})
                .mergeMap( payload => [
                    { type:'CREATE_COMMENT_FULFILLED', payload },
                    // { type:'LOAD_WORKSTATION_FULFILLED', payload },
                ])
                .catch( error => [act_HandleError(error), {type:'CREATE_COMMENT_REJECTED'}])
                .startWith( {type:'CREATE_COMMENT_PENDING'} )
        })
}

export const epc_ChangeComment = (action$, store) => {
    return action$.ofType('CHANGE_COMMENT')
        .mergeMap( action => {
            const { projectId, workstationId, commentId, text, scope } = action.payload
            return api.update_comment({params:{projectId, workstationId, commentId},data:{text, scope}})
                .mergeMap( payload => [
                    { type:'CHANGE_COMMENT_FULFILLED', payload },
                    // { type:'LOAD_WORKSTATION_FULFILLED', payload },
                ])
                .catch( error => [act_HandleError(error), {type:'CHANGE_COMMENT_REJECTED'}])
                .startWith( {type:'CHANGE_COMMENT_PENDING'} )
        })
}

export const epc_DeleteComment = (action$, store) => {
    return action$.ofType('DELETE_COMMENT')
        .mergeMap( action => {
            const { projectId, workstationId, commentId } = action.payload
            return api.delete_comment({params:{projectId, workstationId, commentId}})
                .mergeMap( payload => [
                    { type:'DELETE_COMMENT_FULFILLED', payload:{commentId} },
                    // { type:'LOAD_WORKSTATION_FULFILLED', payload },
                ])
                .catch( error => [act_HandleError(error), {type:'DELETE_COMMENT_REJECTED'}])
                .startWith( {type:'DELETE_COMMENT_PENDING'} )
        })
}

export const epc_ChangePosition = (action$, store) => {
    return action$.ofType('CHANGE_POSITION')
        .mergeMap( action => {
            const { projectId, workstationId, position } = action.payload
            return api.update_position({params:{projectId, workstationId},data:{position}})
                .mergeMap( payload => [
                    { type:'CHANGE_POSITION_FULFILLED', payload }
                ])
                .catch( error => [act_HandleError(error), { type:'CHANGE_POSITION_REJECTED' }])
                .startWith( {type:'CHANGE_POSITION_PENDING'} )
        })
}