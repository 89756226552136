import { createStore, applyMiddleware } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { createLogger } from 'redux-logger'

import reducer from './reducer'
import rootEpic from './epics';

const epicMiddleware = createEpicMiddleware(rootEpic);

const middlewares = [epicMiddleware]

if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({ collapsed: true })    
    middlewares.push(logger);
}

const store = createStore(
    reducer,
    applyMiddleware(...middlewares)
)

export default store