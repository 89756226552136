"use strict";

// DEPENDENCIES
const React = require('react');
const ReactDOM = require('react-dom');

const SvgIcon = require('material-ui/SvgIcon').default;

export const MoveIcon = (props) => (
	<SvgIcon color={props.color}>
		<path d="M15,8 C16.1,8 17,7.1 17,6 C17,4.9 16.1,4 15,4 C13.9,4 13,4.9 13,6 C13,7.1 13.9,8 15,8 L15,8 Z M9,8 C10.1,8 11,7.1 11,6 C11,4.9 10.1,4 9,4 C7.9,4 7,4.9 7,6 C7,7.1 7.9,8 9,8 L9,8 Z M15,10 C13.9,10 13,10.9 13,12 C13,13.1 13.9,14 15,14 C16.1,14 17,13.1 17,12 C17,10.9 16.1,10 15,10 L15,10 Z M9,10 C7.9,10 7,10.9 7,12 C7,13.1 7.9,14 9,14 C10.1,14 11,13.1 11,12 C11,10.9 10.1,10 9,10 L9,10 Z M15,16 C13.9,16 13,16.9 13,18 C13,19.1 13.9,20 15,20 C16.1,20 17,19.1 17,18 C17,16.9 16.1,16 15,16 L15,16 Z M9,16 C7.9,16 7,16.9 7,18 C7,19.1 7.9,20 9,20 C10.1,20 11,19.1 11,18 C11,16.9 10.1,16 9,16 L9,16 Z"></path>
	</SvgIcon>
)
export const BlacklistIcon = (props) => (
	<SvgIcon color={props.color}>
		<path fillRule="evenodd" clipRule="evenodd" d="M13 9.5h5v-2h-5v2zm0 7h5v-2h-5v2zm6 4.5H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2zM6 11h5V6H6v5zm1-4h3v3H7V7zM6 18h5v-5H6v5zm1-4h3v3H7v-3z"/>
	</SvgIcon>
)
export const Chart1x1Icon = (props) => (
	<SvgIcon style={ props.style } color={props.color}>
		<rect x="3" y="3" width="18" height="18"/>
	</SvgIcon>
)
export const Chart2x1Icon = (props) => (
	<SvgIcon style={ props.style } color={props.color}>
		<rect x="1" y="6.8" width="10.5" height="10.5"/>
		<rect x="12.5" y="6.8" width="10.5" height="10.5"/>
	</SvgIcon>
)
export const Chart3x1Icon = (props) => (
	<SvgIcon style={ props.style } color={props.color}>
		<rect x="1" y="8.5" width="7" height="7"/>
		<rect x="8.5" y="8.5" width="7" height="7"/>
		<rect x="16" y="8.5" width="7" height="7"/>
	</SvgIcon>
)
export const MissingDataIcon = (props) => (
	<SvgIcon style={ props.style } color={props.color}>
		<path fill="none" d="M0 0h24v24H0V0z"/>
		<path d="M17 7h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1 0 1.43-.98 2.63-2.31 2.98l1.46 1.46C20.88 15.61 22 13.95 22 12c0-2.76-2.24-5-5-5zm-1 4h-2.19l2 2H16zM2 4.27l3.11 3.11C3.29 8.12 2 9.91 2 12c0 2.76 2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1 0-1.59 1.21-2.9 2.76-3.07L8.73 11H8v2h2.73L13 15.27V17h1.73l4.01 4L20 19.74 3.27 3 2 4.27z"/>
		<path fill="none" d="M0 24V0"/>
	</SvgIcon>
)
export const SyncIcon = (props) => (
	<SvgIcon {...props}>
		<path d="M12 4V1L16 5l-4 4V6c-3.31 0-6 2.69-6 6 0 1.01.25 1.97.7 2.8l-1.46 1.46C4.46 15.03 4 13.57 4 12c0-4.42 3.58-8 8-8zm0 14c3.31 0 6-2.69 6-6 0-1.01-.25-1.97-.7-2.8l1.46-1.46C19.54 8.97 20 10.43 20 12c0 4.42-3.58 8-8 8v3l-4-4 4-4v3z"/>
	</SvgIcon>
)

